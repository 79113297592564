import { Add } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useRef, useState } from "react";

const GridFour = ({commitImage, sponsors}) => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  // Image Top Left 1
  const [uploadedImageTopLeft, setUploadedImageTopLeft] = useState(sponsors?.split(',')[0]);
  // Handle Image Upload
  const handleImageTopLeft = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageTopLeft(URL.createObjectURL(event.target.files[0]));
      console.log(event.target.files[0]);
      commitImage(event.target.files[0], 0);
    }
  };
  const ref = useRef();
  // handle Image Delete
  const handleImageDeleteTopLeft = () => {
    ref.current.value = ``;
  };

  ////////////////////////////////////////////////////////
  // Image Top Right 2
  const [uploadedImageTopRight, setUploadedImageTopRight] = useState(sponsors?.split(',')[1]);
  // Handle Image Upload
  const handleImageTopRight = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageTopRight(URL.createObjectURL(event.target.files[0]));
      commitImage(event.target.files[0], 1);
    }
  };
  const ref2 = useRef();
  // handle Image Delete
  const handleImageDeleteTopRight = () => {
    ref2.current.value = ``;
  };

  // Image Bottom Left 1
  const [uploadedImageBottomLeft, setUploadedImageBottomLeft] = useState(sponsors?.split(',')[2]);
  // Handle Image Upload
  const handleImageBottomLeft = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageBottomLeft(URL.createObjectURL(event.target.files[0]));
      commitImage(event.target.files[0], 2);
      console.log(event.target.files[0]);
    }
  };
  const ref4 = useRef();
  // handle Image Delete
  const handleImageDeleteBottomLeft = () => {
    ref.current.value = ``;
  };

  // Image Bottom Left 1
  const [uploadedImageBottomRight, setUploadedImageBottomRight] =
    useState(sponsors?.split(',')[3]);
  // Handle Image Upload
  const handleImageBottomRight = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageBottomRight(URL.createObjectURL(event.target.files[0]));
      commitImage(event.target.files[0], 3);
      console.log(event.target.files[0]);
    }
  };
  const ref3 = useRef();
  // handle Image Delete
  const handleImageDeleteBottomRight = () => {
    ref.current.value = ``;
  };
  return (
    <Box
      sx={{
        height: "650px",
        width: "650px",
        // display: "flex",
        // justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffb658",
        overflow: "hidden",
      }}
    >
      <Stack display="flex" direction="column" height="100%" width="100%">
        {/* Top Row */}
        <Stack display="flex" direction="row" height="50%" width="100%">
          {/* Left Side */}
          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {!uploadedImageTopLeft ? (
                <></>
              ) : (
                <img
                  src={!uploadedImageTopLeft.includes('blob') ? (CDN_URL+uploadedImageTopLeft) : uploadedImageTopLeft}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

            {/* Upload Div */}
            <Box
              position="absolute"
              sx={{
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <input
                multiple
                ref={ref}
                type="file"
                id="upload"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleImageTopLeft(event)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  translate: "-50% -50%",
                }}
              >
                <IconButton size="large">
                  <label htmlFor="upload">
                    <Add sx={{ color: "#df0c62" }} fontSize="large" />
                  </label>
                </IconButton>
              </Box>
            </Box>
          </Box>

          {/* right side */}
          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {!uploadedImageTopRight ? (
                <></>
              ) : (
                <img
                  src={!uploadedImageTopRight.includes('blob') ? (CDN_URL+uploadedImageTopRight) : uploadedImageTopRight}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

            {/* Upload Div */}
            <Box
              position="absolute"
              sx={{
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <input
                multiple
                ref={ref}
                type="file"
                id="upload2"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleImageTopRight(event)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  translate: "-50% -50%",
                }}
              >
                <IconButton size="large">
                  <label htmlFor="upload2">
                    <Add sx={{ color: "#df0c62" }} fontSize="large" />
                  </label>
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Stack>
        {/* Bottom Row */}
        <Stack display="flex" direction="row" height="50%" width="100%">
          {/* Left Side */}
          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {!uploadedImageBottomLeft ? (
                <></>
              ) : (
                <img
                  src={!uploadedImageBottomLeft.includes('blob') ? (CDN_URL+uploadedImageBottomLeft) : uploadedImageBottomLeft}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

            {/* Upload Div */}
            <Box
              position="absolute"
              sx={{
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <input
                multiple
                ref={ref}
                type="file"
                id="upload3"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleImageBottomLeft(event)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  translate: "-50% -50%",
                }}
              >
                <IconButton size="large">
                  <label htmlFor="upload3">
                    <Add sx={{ color: "#df0c62" }} fontSize="large" />
                  </label>
                </IconButton>
              </Box>
            </Box>
          </Box>

          {/* right side */}
          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {!uploadedImageBottomRight ? (
                <></>
              ) : (
                <img
                  src={!uploadedImageBottomRight.includes('blob') ? (CDN_URL+uploadedImageBottomRight) : uploadedImageBottomRight}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

            {/* Upload Div */}
            <Box
              position="absolute"
              sx={{
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <input
                multiple
                ref={ref}
                type="file"
                id="upload4"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleImageBottomRight(event)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  translate: "-50% -50%",
                }}
              >
                <IconButton size="large">
                  <label htmlFor="upload4">
                    <Add sx={{ color: "#df0c62" }} fontSize="large" />
                  </label>
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GridFour;
