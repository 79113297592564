import {dataGet, dataPost} from "../Services/data.js";

export const loginPromoter = async (params) => {
    let promoter = {};
    await dataPost('promoter/login', {...params}).then((response) => {
        promoter = response.content;
    });
    return promoter;
}

export const registerPromoter = async (params) => {
    let promoter = null;
    await dataPost('promoter/register', {...params}).then((response) => {
      if(response){
        if(response.isSuccess) promoter = response.content;
        else promoter = response;
      }
    });
    return promoter;
}

export const getDashboard = async () => {
    let dashboard = null;
    await dataGet('promoter/getDashboard').then((response) => {
        if (response && response.isSuccess) {
            dashboard = response.content;
        }
    });
    return dashboard;
}

export const saveOne = async (_promoter) => {
    let promoter = null;
    let response = null;
    if(_promoter._id) response = await dataPost('promoter/editProfile', {..._promoter});
    else response = await dataPost('promoter/create', {..._promoter});
    if(response.isSuccess) promoter = response.content;
    return promoter;
}

export const forgotPromoter = async (email) => {
    let status = false;
    await dataPost('promoter/forgot', {email: email}).then((response) => {
        status = !!(response && response.isSuccess);
    });
    return status;
}

export const resetPromoter = async (load) => {
    let status = false;
    await dataPost('promoter/reset', {...load}).then((response) => {
        status = !!(response && response.isSuccess);
    });
    return status;
}

export const listAll = async () => {
    let promoters = [];
    await dataGet('promoter/listAll').then((response) => {
        if (response && response.isSuccess) {
            promoters = response.content;
        }
    });
    return promoters;
}

export const listConfirmed = async () => {
    let promoters = [];
    await dataGet('promoter/listConfirmed').then((response) => {
        if (response && response.isSuccess) {
            promoters = response.content;
        }
    });
    return promoters;
}

export const listUnConfirmed = async () => {
    let promoters = [];
    await dataGet('promoter/listUnConfirmed').then((response) => {
        if (response && response.isSuccess) {
            promoters = response.content;
        }
    });
    return promoters;
}

export const confirm = async (promoter) => {
    let resp = false;
    await dataPost('promoter/confirm', {...promoter}).then((response) => {
        resp = !!(response && response.isSuccess);
    });
    return resp;
}


export const getPromoterByEmail = async (email) => {
    let promoter = {};
    await dataGet('promoter/getByEmail', {email: email}).then((response) => {
        promoter = response.content;
    });
    return promoter;
}

export const getProfile = async () => {
    let promoter = {};
    await dataGet('promoter/getProfile').then((_response) => {
        promoter = _response?.content;
    });
    return promoter;
}

export const updateProfile = async (profile) => {
    let promoter = {};
    await dataPost('promoter/updateProfile', {...profile}).then((_response) => {
        promoter = _response?.content;
    });
    return promoter;
}

export const changePassword = async (password) => {
    let promoter = {};
    await dataPost('promoter/changePassword', {password: password}).then((_response) => {
        promoter = _response?.content;
    });
    return promoter;
}

export const getPromoterById = async (id) => {
    let promoter = {};
    await dataGet('promoter/getById', {id: id}).then((response) => {
        promoter = response.content;
    });
    return promoter;
}

export const deletePromoter = async (id) => {
    let promoter = {};
    await dataPost('promoter/delete', {id: id}).then((response) => {
        promoter = response.content;
    });
    return promoter;
}

export const listNotifiations = async () => {
    let notifications = [];
    await dataGet('promoter/listNotifiations').then((response) => {
        if (response && response.isSuccess) notifications = response.content;
    });
    return notifications;
}

export const checkNotifiations = async () => {
    let notifications = [];
    await dataGet('promoter/checkNotifications').then((response) => {
        if (response && response.isSuccess) notifications = response.content;
    });
    return notifications;
}

export const listFavorites = async () => {
    let favorites = [];
    await dataGet('promoter/listFavorites').then((response) => {
        if (response && response.isSuccess) {
            favorites = response.content;
        }
    });
    return favorites;
}

export const fastFavorites = async () => {
    let favorites = "";
    await dataGet('promoter/fastFavorites').then((response) => {
        if (response && response.isSuccess) favorites = response.content;
    });
    return favorites;
}

export const countFavorites = async () => {
    let favorites = [];
    let lis = [];
    let st = "";
    await dataGet('promoter/countFavorites').then((response) => {

        if (response && response.isSuccess) {
            if (response.content) {
                if (response.content.length) {
                    if (response.content.length > 1) {
                        response.content.forEach((item, i) => {
                            lis.push(item);
                        });
                        st = lis.join(",");
                    } else st = response.content[0];
                } else st = null;
            } else st = null;

            if (st) localStorage.setItem("favs", st);
            else localStorage.removeItem("favs");

            favorites = response.content.length;
        }
    });
    return favorites;
}

export const checkFavorite = async (id) => {
    let success = false;
    let favorites = [];
    let lis = [];
    let st = "";
    await dataPost('promoter/checkFavorite', {id: id}).then(async (response) => {
        if (response && response.isSuccess) {
            await dataGet('promoter/countFavorites').then(async (_response) => {
                if (_response && _response.isSuccess) {
                    if (_response.content) {
                        if (_response.content.length) {
                            if (_response.content.length > 1) {
                                _response.content.forEach((item, i) => {
                                    lis.push(item);
                                });
                                st = lis.join(",");
                            } else st = _response.content[0];
                        } else st = null;
                    } else st = null;

                    if (st) await localStorage.setItem("favs", st);
                    else await localStorage.removeItem("favs");
                    favorites = _response.content.length;
                }
            });
            success = true;
        } else success = false;
    });
    return success;
}
