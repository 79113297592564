import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import AdminDesktop from "./Admin/Desktop/AdminDesktop";
import CustomerDesktop from "./Customer/Desktop/CustomerDesktop";
import SaleDesktop from "./Sale/Desktop/SaleDesktop";
import OrganizerDesktop from "./Organizer/Desktop/OrganizerDesktop";
import Footer from "../Footer/Footer";
import classes from "./styles.module.css";
import FooterMobile from "../Footer/Mobile/FooterMobile";
import { useMediaQuery } from "react-responsive";
import cookies from "react-cookies";
import FooterTablet from "../Footer/Tablet/FooterTablet";
import MobileNavBar from "../../AppBar/Mobile/NavBar/MobileNavBar";
import { testPayment } from "../../../Data/payment";
import Loading from "../../Common/Loading";
import Spinner from "../../Common/Spinner";

const Desktop = () => {
  const navigate = useNavigate();
  const [display, setDisplay] = useState("Admin");
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 576px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const cookiesConfig = {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE,
    path: process.env.REACT_APP_BASE_URL,
  };

  const clearAdminData = async () => {
    await cookies.remove("adminFirstName", cookiesConfig);
    await cookies.remove("adminLastName", cookiesConfig);
    await cookies.remove("adminEmail", cookiesConfig);
    await cookies.remove("adminPhoneNumber", cookiesConfig);
    await localStorage.removeItem("admin");
  };

  const logout = () => {
    clearAdminData().then(() => {
      navigate("/");
    });
  };

  const onClickTest = async () => {
    await testPayment();
  };

  return (
    <Box>
      <NavBar color="#ffd283" />
      {/* <button onClick={() => onClickTest()}>Test fnc</button> */}
      <Container
        maxWidth="lg"
        sx={{ marginBottom: { xs: "30px", md: "30px" } }}
      >
        {/* Parent Stack */}
        <Stack
          display="flex"
          direction="column"
          sx={{ paddingTop: "5%" }}
          spacing={2}
          paddingBottom="5%"
        >
          <Stack display="flex" direction="row" justifyContent="space-between">
            <Typography
              variant="h4"
              color="#df0c63"
              className={classes.titleStroke}
            >
              ADMIN PANELİ
            </Typography>

            <Button
              onClick={() => logout()}
              variant="contained"
              sx={{
                display: "block",
                color: "#df0c62",
                backgroundColor: "transparent",
                "&:hover": { backgroundColor: "#df0c62", color: "#fff" },
              }}
            >
              Çıkış
            </Button>
          </Stack>
          {/* Display Buttons */}
          <Stack
            display="flex"
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#feb659",
                "&:hover": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 1px #fe0c62",
                },
                "&:focus": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 4px #fe0c62",
                  transition: "750ms",
                },
              }}
              onClick={() => setDisplay("Admin")}
              disableElevation
              className={display === "Admin" && classes.Admin}
            >
              Admin
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#feb659",
                "&:hover": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 1px #fe0c62",
                },
                "&:focus": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 4px #fe0c62",
                  transition: "750ms",
                },
              }}
              onClick={() => setDisplay("Customer")}
              disableElevation
              className={display === "Customer" && classes.Customer}
            >
              MÜŞTERİ
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#feb659",
                "&:hover": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 1px #fe0c62",
                },
                "&:focus": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 4px #fe0c62",
                  transition: "750ms",
                },
              }}
              onClick={() => setDisplay("Sale")}
              disableElevation
              className={display === "Sale" && classes.Customer}
            >
              Satış
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#feb659",
                "&:hover": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 1px #fe0c62",
                },
                "&:focus": {
                  backgroundColor: "#feb659",
                  boxShadow: "0px 0px 5px 4px #fe0c62",
                  transition: "750ms",
                },
              }}
              onClick={() => setDisplay("Organizer")}
              disableElevation
              className={display === "Organizer" && classes.Organizer}
            >
              ORGANİZATÖR
            </Button>
          </Stack>
          {/* End Of Parent Stack */}
        </Stack>
        {display === "Admin" && <AdminDesktop />}
        {display === "Customer" && <CustomerDesktop />}
        {display === "Sale" && <SaleDesktop />}
        {display === "Organizer" && <OrganizerDesktop />}
        {/* <RequestsDesktop /> */}
      </Container>

      {/* Last Part */}
      <div>
        {isMobile ? (
          <FooterMobile />
        ) : isBigScreen ? (
          <Footer />
        ) : (
          <FooterTablet />
        )}
      </div>
      {isMobile && <MobileNavBar />}
    </Box>
  );
};

export default Desktop;
