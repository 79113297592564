import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import TopBar from "../../AppBar/TopBar";
import endTicket from "../../../Assets/svg/endTicket.svg";
import logo from "../../../Assets/Images/logo.png";
import classes from "./styles.module.css";
import Footer from "../../Panel/Footer/Footer";
import { useTranslation } from "react-i18next";

const DesktopCongratulations = ({ orderId, logged }) => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  return (
    <Box
      sx={{ display: { xs: "none", sm: "block" } }}
      className={classes.container}
    >
      <TopBar color="#ffd283" />

      <Container maxWidth="sm" sx={{ paddingTop: "8%", paddingBottom: "3.6%" }}>
        {/* Body */}
        <Stack display="flex" direction="column" spacing={1}>
          {/* Congrats Ticket */}
          <Box className={classes.CelebTicket}>
            <Stack
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ position: "absolute", padding: "16px 0" }}
            >
              <Box>
                <Typography
                  variant="h6"
                  color="#df0c62"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{ textDecoration: "underline" }}
                >
                   {t('congrats')}
                </Typography>
              </Box>
              <Typography
                color="#df0c62"
                sx={{ padding: "4px 80px" }}
                fontWeight="bold"
                textAlign="center"
              >
                {orderId?.substring(orderId.length - 8, orderId.length)?.toUpperCase()} {t('order_complete_not_verified')}
              </Typography>
              <img src={logo} alt="logo" width="25%" />
            </Stack>
            <img src={endTicket} alt="logo" />
          </Box>
          <Stack display="flex" direction="row" spacing={1}>
            {logged ? (
              <Box flex={1}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => { navigate('/profile?tickets=show') }}
                  sx={{
                    backgroundColor: "#df0c62",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                >
                  {t('go_to_tickets')}
                </Button>
              </Box>
            ) : null}
            <Box flex={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => { navigate('/events') }}
                sx={{
                  backgroundColor: "#df0c62",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
                {t('see_other_events')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
};

export default DesktopCongratulations;
