import { dataGet, dataPost } from "../Services/data.js";

export const stripeConfig = async () => {
  let response = {};
  await dataGet("payments/stripe/config").then((res) => {
    response = res.content.publishableKey;
  });
  return response;
};

export const createPayment = async (total,currency) => {
  let response = {};
  await dataPost("payments/stripe/create-payment-intent",{total:total,currency:currency}).then((res) => {
    response = res.content.clientSecret;
  });
  return response;
};

export const garantiMake = async (payment) => {
  let response = {};
  await dataPost("payments/garanti/make",{...payment}).then((res) => {
    response = res.content;
  });
  return response;
};
