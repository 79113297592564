import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "../../../Assets/Images/logo.png";
import aidKit from "../../../Assets/Images/aidKit.png";
import stage from "../../../Assets/svg/stage.svg";
import cloud from "../../../Assets/svg/cloudGroupBlack.svg";
import classes from "./styles.module.css";
import { getAllCountries } from "../../../Data/general";
import { SocialIcon } from "react-social-icons";
import MobileNavBar from "../../AppBar/Mobile/NavBar/MobileNavBar";
import MobileAppBar from "../../AppBar/Mobile/TopBar/MobileAppBar";
import { ExpandMore } from "@mui/icons-material";
import AutoInput from "../../AutoComplete/AutoInput";
import { Link, useNavigate } from "react-router-dom";
import FooterMobile from "../../Panel/Footer/Mobile/FooterMobile";
import CheckoutForm from "../../Payment/CheckoutForm";
import GarantiForm from "../../Payment/GarantiForm";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

const MobileBuyTicket = ({ clientSecret, thisEvent, cart, removeItem, clearCart, total, serviceTotal, order, setOrder, stripePromise, submitOrder, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [isLogged, setIsLogged] = useState(localStorage.getItem('local'));
  const ticketsAmnt = [0, 1];
  const [address, setAddress] = useState('');
  const [disabled, setDisabled] = useState(true)
  const [continueAsGuest, setContinueAsGuest] = useState(false)

  const [checked, setChecked] = useState({ first: false, second: false });
  const [allowed, setAllowed] = useState(false);

  const [countries, setCountries] = useState([]);
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [countdown, setCount] = useState("05:00");


  const appearance = {
    theme: 'none',
    variables:{colorPrimary: '#df0b62',colorPrimaryText: '#df0b62'},
    rules: {
      '.Tab': {border: '2px solid #ffd689'},
      '.Tab:hover': {color: '#df0b62',boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',},
      '.Tab--selected': {borderColor: '#df0b62',color: '#df0b62'},
      '.TabIcon--selected': {fill: '#df0b62'},
      '.Input': {color: '#df0b62',border:'2px solid #df0b62',borderRadius:0,},
      '.Input::placeholder': {color: '#df0b62'},
      '.Input--invalid': {boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',},
      '.Label': {color: '#df0b62'},
      '.p-LinkAutofillPromptButton': {color: '#df0b62'},
      '.RedirectText': {color: '#df0b62'}
    }
  };

  const fetchCountries = async () => {
    setCountries(await getAllCountries());
  };

  const getClocked = (num) => {
    if(num > 0){
      if(num > 9) return num;
      else return "0"+num;
    }else return "00";
  }

  const updateCount = async (extime,ttime) => {
    let timeleft = extime - ttime;

    let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    if(!minutes && !seconds) return;
    else setCount(getClocked(minutes)+":"+getClocked(seconds));

  }

  const initTimer = async () => {
    let exTime = await localStorage.getItem('resExTime');
    if(!exTime) navigate('/');
    else{
      setInterval(() => { updateCount(new Number(exTime),new Number(new Date().getTime()));},1000);
    }
  }

  const isValidEmail = (val) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(val);
  }

  const isValidPhoneNumber = (val) => {
    const phoneNumberRegex = /^(\+\d{1,3}[- ]?)?(\d{10,15})$/;
    return phoneNumberRegex.test(val);
  }

  useEffect(() => {
    if (order.firstName.length < 2 || order.lastName.length < 2 || !isValidEmail(order.email) || !isValidPhoneNumber(order.phoneNumber)) setDisabled(true)
    else setDisabled(false)
  }, [order])

  useEffect(() => {
    window.scrollTo(0,0);
  },[window.location]);

  useEffect(() => {
    if (countrySelectorOpen) fetchCountries();
    else setCountries([]);
  }, [countrySelectorOpen]);

  useEffect(() => {
    initTimer();
  },[]);

  const repeatedRow = (text1, text2) => {
    return (
      <Stack display="flex" direction="row" sx={{ width: "100%" }}>
        <Box flex={1} className={classes.yellowBorder}>
          <Typography variant="h6" color="#df0c62">
            {text1}
          </Typography>
        </Box>
        <Box flex={1} className={classes.yellowBorder}>
          <Typography variant="h6" textAlign="end" color="#df0c62">
            {text2}
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <Box
      className={classes.container + " eventPageContainer"}
      sx={{ display: { xs: 'block', sm: 'none' } }}
    >
      <MobileAppBar color="#ffd283" shadow={[253, 201, 129, 0.702]} />
      {/* Event Header */}
      <Box className={classes.eventHeader}>
        <Container maxWidth="md" sx={{ height: "100%" }}>
          <Stack display="flex" className={classes.eventStack} sx={{ height: "100%" }}>
            {/* Event Poster */}
            <Box flex={1}>
              <img
                src={CDN_URL + thisEvent?.image}
                alt="Event Poster"
                className={classes.eventPoster}
              />
            </Box>
            {/* Event Details */}
            <Box flex={2} className={classes.eventDetails}>
              <Stack display="flex" direction="column">
                <Typography
                  variant="h4"
                  color="#df0c62"
                  className={classes.titleStroke}
                >
                  {thisEvent?.title}
                </Typography>
                <Stack display="flex" direction="row">
                  <Box flex={1}>
                    <Typography variant="h6" fontWeight="bold" color="#df0c62">
                      {thisEvent?.location?.name}
                    </Typography>
                    <Typography paragraph color="#df0c62">
                      {thisEvent?.startDate}
                    </Typography>
                  </Box>
                </Stack>
                <Box className={classes.redDash}></Box>
                {/* Event Type */}
                <Typography paragraph color="#df0c62">
                  {thisEvent?.type}
                </Typography>
                <Typography paragraph color="#df0c62">
                    {t('Reservation Expires In:')} <strong>{countdown}</strong>
                  </Typography>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Cart Information */}
      <Box sx={{ padding: "0 16px" }}>
        {/* BILETLER */}
        <Stack display="flex" direction="row">
          <Box flex={1}>
            <Typography variant="h6" color="#df0c62">
              {t('Tickets')}
            </Typography>
          </Box>
          <Box flex={4.7} sx={{ display: "flex", paddingBottom: "8px" }}>
            <Box className={classes.yellowLine}></Box>
          </Box>
        </Stack>
        {/* Cart written details */}
        <Box sx={{ width: "100%" }}>
          <Typography paragraph textAlign="end" color="#df0c62">
            {t('There are tickets in your cart', { amount: cart?.length })}
          </Typography>
        </Box>

        {/* Cart */}
        <Box className={classes.cart}>
          <Stack display="flex" direction="column" spacing={4}>
            {cart?.map((it, i) => (
              <Stack key={i} display="flex" direction="column">
                {repeatedRow(t('Type'), it.type.toString().substring(0, 1).toUpperCase() + it.type.toString().substring(1, it.type.length))}
                {thisEvent && thisEvent.plan && thisEvent.plan.isSimple == false ? (
                  <>
                    {repeatedRow(t('Block'), 'Main')}
                    {repeatedRow(t('Row'), it.label.substring(0,1))}
                    {repeatedRow(t('Seat'), (thisEvent.plan.startsFromOne ? it.trueSeat : it.seat))}
                  </>
                ):null}
                {repeatedRow(t('Ticket Cost'), (it.price?.toFixed(2) + thisEvent.currency?.symbol))}
                <Box onClick={() => removeItem(i)}>
                  <Typography variant="h6" textAlign="right" color="#df0c62">
                    {t('Remove')}
                  </Typography>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>

        {/* EMPTY BOX */}
        <Box sx={{ width: "100%", height: "1rem" }}></Box>
        {/* AdditionalButton */}
        <Stack display="flex" direction="column" spacing={2}>

          {/* Second Row */}
          <Stack display="flex" direction="row" >
            <Box className="w-50 px-2">
              <Button
                className="w-100"
                onClick={() => navigate("/event/" + thisEvent.slug)}
                variant="contained"
                sx={{
                  backgroundColor: "#df0c62",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
              {t('Add Seat')}
              </Button>
            </Box>
            <Box className="w-50 px-2">
            <Button
              className="w-100"
              variant="contained"
              onClick={clearCart}
              sx={{
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
            {t('Remove All')}
            </Button>
            </Box>

          </Stack>

          {false ? (

            <Stack display="flex" direction="row">
            <Box className="w-50 px-2" >
              <Button
              className="w-100"
                variant="contained"
                sx={{
                  backgroundColor: "#df0c62",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
                onClick={() => navigate("/login")}
              >
                {t('Login')}
              </Button>
            </Box>

              <Box className="w-50 px-2">
                <Button
                className="w-100"
                  variant="contained"
                  sx={{
                    backgroundColor: "#df0c62",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                  onClick={() => setContinueAsGuest(true)}
                >
                  {t('Continue as Guest')}
                </Button>
              </Box>
            </Stack>
          ) : null}



        </Stack>
        {/* Start OF Accordions */}
        {!isLogged ? (
              <Stack display="flex" direction="column" sx={{marginTop:'20px!important'}} spacing={2}>
                <Stack display="flex" direction="row" spacing={1}>
                  <Box flex={1}>
                    <Typography color="#df0c62" fontWeight="bold">
                      {t('First Name')} *
                    </Typography>
                    <input
                      value={order.firstName}
                      onChange={(e) => setOrder({ ...order, firstName: e.target.value })}
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="name"
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography color="#df0c62" fontWeight="bold">
                      {t('Last Name')} *
                    </Typography>
                    <input
                      value={order.lastName}
                      onChange={(e) => setOrder({ ...order, lastName: e.target.value })}
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="lastName"
                    />
                  </Box>
                </Stack>
                <Box flex={1}>
                  <Typography color="#df0c62" fontWeight="bold">
                    {t('E-mail')} *
                  </Typography>
                  <input
                    value={order.email}
                    onChange={(e) => setOrder({ ...order, email: e.target.value })}
                    style={{
                      width: "100%",
                      border: "2px solid #df0c63",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box flex={1}>
                  <Typography color="#df0c62" fontWeight="bold">
                    {t('Phone Number')} *
                  </Typography>
                  <input
                    value={order.phoneNumber}
                    onChange={(e) => setOrder({ ...order, phoneNumber: e.target.value })}
                    style={{
                      width: "100%",
                      border: "2px solid #df0c63",
                      outline: "none",
                    }}
                    name="id"
                  />
                </Box>

                <Box display="flex" justifyContent="end" pb={1}></Box>
                {/* End of Accordion Stack */}
              </Stack>

        ) : null}

        <Accordion>
          <AccordionSummary
          disabled={disabled && !isLogged}
            sx={{ paddingLeft: "0" }}
            expandIcon={
              <ExpandMore sx={{ color: "#df0c62" }} fontSize="large" />
            }
            aria-controls="panel1a-content"
            id="panel1a-header2"
          >
            <Stack display="flex" direction="row">
              <Typography variant="h6" fontWeight="bold" color="#df0c62">
                {t('Payment')}
              </Typography>
              <Box
                flex={1}
                sx={{ borderBottom: "2px solid #ffd289" }}
              ></Box>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {/* Child Accordion Stack */}
            <Stack display="flex" direction="column" spacing={2}>

              <Stack display="flex" direction="column" spacing={2}>
                {/* Left Side Payment Detail */}
                <Box
                  flex={1}
                  sx={{
                    border: "2px solid #df0c62",
                    height: "100%",
                    padding: "32px",
                  }}
                >
                  <Stack
                    display="flex"
                    direction="column"
                    spacing={2}
                    sx={{ flexGrow: 1 }}
                  >
                    <Box
                      sx={{
                        borderBottom: "2px solid #ffd589",
                      }}
                    >
                      <Stack
                        display="flex"
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography color="#df0c62" fontWeight="bold">
                          {t('Total')}:
                        </Typography>
                        <Typography fontWeight="bold" color="#df0c62">
                          {total} {thisEvent?.currency?.symbol}
                        </Typography>
                      </Stack>
                    </Box>
                    <Typography color="#df0c62">*{t('vat_included')}</Typography>

                    {thisEvent && thisEvent.location ? (
                      <>
                      {thisEvent.location.country === "Turkey" ? (
                        <>
                        <GarantiForm submit={submitOrder} loading={loading} />
                        </>
                      ):(
                        <>
                        <Box flex={1}>
                          {clientSecret && stripePromise && (
                            <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                              <CheckoutForm secret={clientSecret} submitOrder={submitOrder} loading={loading} />
                            </Elements>
                          )}
                        </Box>
                        </>
                      )}
                      </>
                    ):null}
                  </Stack>
                </Box>
                {clientSecret && stripePromise && false && (
                  <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
                    <CheckoutForm secret={clientSecret} submitOrder={submitOrder} loading={loading} />
                  </Elements>
                )}
                <Accordion>
                  <AccordionSummary onChange={(e) => setOrder({ ...order, invoice: !order.invoice })}
                  disabled={disabled && !isLogged}
                    expandIcon={<></>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ paddingLeft: 0, marginTop:'15px' }}
                  >
                    <Stack display="flex" direction="row" alignItems="center">
                      <Typography color="#df0c62" variant="h9" fontWeight="bold">
                          {t('I want bill')}
                      </Typography>
                      <Checkbox checked={order.invoice}  color="error" />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box>
                          <Typography color="#df0c62" fontWeight="bold">
                            {t('Country')} *
                          </Typography>
                          <input
                            value={order.country}
                            onChange={(e) => setOrder({ ...order, country: e.target.value })}
                            style={{
                              width: "100%",
                              border: "2px solid #df0c63",
                              outline: "none",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box>
                          <Typography color="#df0c62" fontWeight="bold">
                            {t('City')} *
                          </Typography>
                          <input
                            value={order.city}
                            onChange={(e) => setOrder({ ...order, city: e.target.value })}
                            style={{
                              width: "100%",
                              border: "2px solid #df0c63",
                              outline: "none",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item>
                          <Box>
                            <Typography color="#df0c62" fontWeight="bold">
                              {t('Zip Code')} *
                            </Typography>
                            <input
                              value={order.zip}
                              onChange={(e) => setOrder({ ...order, zip: e.target.value })}
                              style={{
                                width: "100%",
                                border: "2px solid #df0c63",
                                outline: "none",
                              }}
                              name="country"
                            />
                          </Box>
                        </Grid>
                        <div className='position-relative mt-3'>
                          <Box flex={1}>
                            <Typography color="#df0c62" fontWeight="bold">
                              {t('Address')}
                            </Typography>
                            <textarea
                              rows={5}
                              maxLength={255}
                              style={{
                                width: "100%",
                                border: "2px solid #df0c63",
                                outline: "none",
                                resize: 'none'
                              }}
                              value={order.address1}
                              onChange={(e) => setOrder({ ...order, address1: e.target.value })}
                            />
                          </Box>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
      <FooterMobile />
      <MobileNavBar />
    </Box>
  );
};

export default MobileBuyTicket;
