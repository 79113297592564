import React from "react";
import { Route, Routes } from "react-router-dom";
import CurrentEvent from "./CurrentEvent/Desktop/Desktop";
import EventSubmit from "./EventSubmit";
import Organizer from "./Organizer/Desktop/Desktop";
import Participants from "./ParticipantInformation/Desktop/Desktop";
import Seats from "./SeatArrangmenet/Desktop/Desktop";
import HotSale from "./HotSale/HotSale";
const index = () => {
  return (
    <div>
      {/* <NavBar color="#ffd283" /> */}
      <Routes>
        <Route path="/" element={<Organizer />} />
        <Route path="/currentEvent" element={<CurrentEvent />} />
        <Route path="/hotSale" element={<HotSale />} />
        <Route path="/submit" element={<EventSubmit />} />
        <Route path="/participants" element={<Participants />} />
        <Route path="/seats" element={<Seats />} />
      </Routes>
    </div>
  );
};

export default index;
