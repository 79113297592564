import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const AModal = ({ title, submit }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [form, setForm] = useState({ firstName: "", lastName: "", email: "", phoneNumber: "", password: "", birth_day: "" });
  const [disabled, setDisabled] = useState(true)
  const [pendingApiCall, setPendingApiCall] = useState(false)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff0d7",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    setDisabled(true)
    if (form.firstName === '') return
    if (form.lastName === '') return
    if (form.email === '') return
    if (form.phoneNumber === '') return
    if (form.password === '') return
    if (form.birth_day === '') return
    setDisabled(false)
  }, [form])

  const formSubmit = async () => {
    setPendingApiCall(true)
    await submit(form).then(() => {
      setOpen(false);
      setForm({ firstName: "", lastName: "", email: "", phoneNumber: "", password: "", birth_day: "" });
    });
    setPendingApiCall(false)
  }
  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#df0c62",
          "&:hover": { backgroundColor: "#df0c62" },
        }}
        onClick={handleOpen}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* Detail parent Stack */}
          <Stack display="flex" direction="column" spacing={4}>
            {/* First Part Stack */}
            <Stack
              display="flex"
              direction="row"
              spacing={2}
              sx={{ backgroundColor: "#fff0d7" }}
            >
              {/* Left Side */}
              <Box flex={1}>
                <Stack display="flex" direction="column" spacing={2}>
                  <Box padding="8px 0">
                    <TextField
                      color="error"
                      variant="standard"
                      placeholder="İsim"
                      onChange={(e) => { setForm({ ...form, firstName: e.target.value }) }}
                      value={form.firstName}
                      name="firstName"
                      type="text"
                    />
                  </Box>
                  <Box padding="8px 0">
                    <TextField
                      color="error"
                      variant="standard"
                      placeholder="Telefon"
                      value={form.phoneNumber}
                      onChange={(e) => { setForm({ ...form, phoneNumber: e.target.value }) }}
                      name="phone"
                      type="text"
                    />
                  </Box>
                  <Box padding="8px 0">
                    <TextField
                      color="error"
                      variant="standard"
                      placeholder="Doğum Tarihi"
                      onChange={(e) => { setForm({ ...form, birth_day: e.target.value }) }}
                      value={form.birth_day}
                      name="date"
                      type="text"
                    />
                  </Box>
                </Stack>
              </Box>
              {/* Right Side */}
              <Box flex={1}>
                <Stack display="flex" direction="column" spacing={2}>
                  <Box padding="8px 0">
                    <TextField
                      color="error"
                      variant="standard"
                      placeholder="Soyisim"
                      onChange={(e) => { setForm({ ...form, lastName: e.target.value }) }}
                      value={form.lastName}
                      name="lastName"
                      type="text"
                    />
                  </Box>
                  <Box padding="8px 0">
                    <TextField
                      color="error"
                      variant="standard"
                      placeholder="Email"
                      onChange={(e) => { setForm({ ...form, email: e.target.value }) }}
                      value={form.email}
                      name="email"
                      type="email"
                    />
                  </Box>
                  <Box padding="8px 0">
                    <TextField
                      color="error"
                      variant="standard"
                      placeholder="Şifre"
                      onChange={(e) => { setForm({ ...form, password: e.target.value }) }}
                      value={form.password}
                      name="sex"
                      type="text"
                    />
                  </Box>
                </Stack>
              </Box>
              {/* End Of First Part Stack */}
            </Stack>

            <Button
              variant="contained"
              disableElevation
              onClick={() => { formSubmit() }}
              sx={{
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
                borderRadius: "0",
              }}
              disabled={disabled || pendingApiCall}
            >
              {
                pendingApiCall &&
                <div class="spinner-border spinner-border-sm" role="status">
                </div>
              }
              onayla
            </Button>
            {/* End Of Accordion Detail Stack */}
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AModal;
