import {
  Box, Container, Stack, Typography, Modal, Button, Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Footer/Footer";
import SearchBar from "../../../SearchBar/SearchBar";
import AddSponsor from "../../../AdminPanel/Sponsor/Add Sponsor/AddSponsor";
import guy from "../../../../../Assets/Images/guy.jpg";
import classes from "./styles.module.css";
import { toast } from "react-toastify";
import { listAll, ticketCancel, onResend } from "../../../../../Data/order";
import { BiCopy, BiSave, BiTrash } from "react-icons/bi";
import { ExpandMore, FileDownloadOutlined, Close, Email, Save } from "@mui/icons-material";
import { BsTrash } from "react-icons/bs";
import { saveOne, deleteCounter } from "../../../../../Data/counter";
import { useTranslation } from "react-i18next";
import { showDiscountPrice } from "../../../../../Services/helpers";

const Desktop = ({ e_event, refetch, small, edit }) => {
  const { t } = useTranslation();
  const [emailToSend, setEmailToSend] = useState('');


  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('admin'));
  const [sponsorModal, setSponsorModal] = useState(false);
  const [newSeller, setNewSeller] = useState({ event: { _id: e_event._id }, role: "seller", email: "", firstName: "" });
  const [newScanner, setNewScanner] = useState({ event: { _id: e_event._id }, role: "scanner", email: "", firstName: "" });

  const copy = async (text) => {
    await window.navigator.clipboard.writeText(text);
    toast("Panoya kopyalandı.");
  }

  const sponsorCommit = async () => {
    toast("Sponsorlar başarıyla ayarlandılar.");
    setSponsorModal(false);
    refetch();
  }

  const save = async (counter) => {
    await saveOne(counter).then((r) => {
      if (r) {
        refetch();
        setNewSeller({ event: { _id: e_event._id }, role: "seller", email: "", firstName: "" });
        setNewScanner({ event: { _id: e_event._id }, role: "scanner", email: "", firstName: "" });
        toast("Gişe hesabı oluşturuldu.");
      } else toast(t('error_try_again'));
    })
  }

  const remove = async (id) => {
    if (window.confirm(t('sure_deleting_counter'))) {
      await deleteCounter(id).then((r) => {
        if (r) {
          refetch();
          toast(t('account_deleted'));
        } else toast(t('error_try_again'));
      });
    }
  }

  const onCancel = async (id) => {
    await ticketCancel(id).then((r) => {
      if (!r) toast.error(t('error_try_again'));
      else {
        toast.success(t('ticket_canceled'));
        refetch();
      }
    });
  };

  const onResendTickets = async (id, email) => {
    await onResend(id, email).then((r) => {
      if (!r) toast.error(t('error_try_again'));
      else toast.success(t('tickets_sent_again'));
    })
  }

  const onSponsorClose = () => {
    setSponsorModal(false);
  }

  const RepeatedRows = ({ title, detail }) => {
    return (
      <>
        <Stack
          direction="column"
          sx={{
            display: { xs: "none", sm: "flex" },
            borderBottom: "2px solid #feb659",
          }}
        >
          <Typography variant="h6" fontWeight="bold" color="#df0c62">
            {title}
          </Typography>
          <Box sx={{ padding: "8px 16px" }}>
            <Typography color="#df0c62" fontWeight="bold">
              {detail}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="column"
          sx={{
            display: { xs: "flex", sm: "none" },
            borderBottom: "2px solid #feb659",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#df0c62"
            fontSize="0.7rem"
          >
            {title}
          </Typography>
          <Box sx={{ padding: "8px 16px" }}>
            <Typography color="#df0c62" fontWeight="bold" fontSize="0.5rem">
              {detail}
            </Typography>
          </Box>
        </Stack>
      </>
    );
  };
  return (
    <Box>
      <Modal sx={{ overflow: 'auto', backgroundColor: 'white' }} open={sponsorModal} onClose={onSponsorClose}>
        <AddSponsor
          onClose={onSponsorClose}
          thisEvent={e_event}
          commit={sponsorCommit}
        />
      </Modal>
      <Container>
        {/* Parent Stack */}
        <Stack
          display="flex"
          direction="column"
          sx={{ paddingTop: "5%" }}
          spacing={4}
        >
          {e_event.canceled ? (
            <Stack dispay="flex" direction="row" >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  border: "none",
                  backgroundColor: "#df0c62",
                  borderRadius: "0",
                  cursor: 'default!important',
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
                onClick={() => { }}
              >
                Etkinlik Iptal Edildi
              </Button>
              {/* End OF Row Stack */}
            </Stack>
          ) : null}
          {/* Events Title & Poster */}
          <Stack
            display="flex"
            direction="row"
            sx={{ backgroundColor: "#fffed7" }}
          >
            {/* Desktop */}
            <Box flex={1} sx={{ display: { xs: "none", sm: "flex" } }}>
              <img src={CDN_URL + e_event?.smimage} alt="guy" width="100%" />
            </Box>
            <Box
              flex={1}
              sx={{ display: { xs: "none", sm: "flex" } }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                color="#df0c62"
                textAlign="center"
              >
                {e_event?.title}
              </Typography>
            </Box>
            {/* Mobile */}
            <Box flex={1} sx={{ display: { xs: "flex", sm: "none" } }}>
              <img src={CDN_URL + e_event?.image} alt={e_event?.title} width="100%" />
            </Box>
            <Box
              flex={1}
              sx={{ display: { xs: "flex", sm: "none" } }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                color="#df0c62"
                textAlign="center"
              >
                {e_event?.title}
              </Typography>
            </Box>
          </Stack>
          {/* Parent Event detail Stack */}
          <Stack display="flex" direction="column">
            <Stack display="flex" direction="row" spacing={2}>
              {/* Left Side */}
              <Box flex={1}>
                <Stack display="flex" direction="column" spacing={1}>
                  <RepeatedRows title=" Etkinlik Adı" detail={e_event?.title} />
                  <RepeatedRows
                    title="Etkinlik Şehri"
                    detail={e_event?.location?.country + " / " + e_event?.location?.city}
                  />
                  <RepeatedRows title="Etkinlik Türü" detail={e_event.type} />
                  <RepeatedRows title="Organizatör" detail={e_event.promoter?.businessName || "eTicket Al"} />
                </Stack>
              </Box>
              {/* Right Side */}
              <Box flex={1}>
                <Stack display="flex" direction="column" spacing={1}>
                  <RepeatedRows
                    title="Etkinlik Tarihi"
                    detail={e_event?.startDate}
                  />
                  <RepeatedRows
                    title="Etkinlik Sahnesi"
                    detail={e_event?.location?.name}
                  />

                  <RepeatedRows title="Katılımcı Sayısı" detail={(e_event.soldToUsed && e_event.soldToUsed.sold) ? ('Toplam: ' + showDiscountPrice(e_event.soldToUsed.sold) + " Katıldı: " + showDiscountPrice(e_event.soldToUsed.sold) + "/" + showDiscountPrice(e_event.soldToUsed.used)) : "0"} />
                </Stack>
              </Box>
            </Stack>
            {/*<RepeatedRows title="Oturma Düzeni" detail="Yok" /> */}
            {/* End of Event Detail Stack */}
          </Stack>

          {isAdmin || (e_event.soldToUsed?.sold < 1) ? (
            <Stack dispay="flex" direction="row" >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  border: "none",
                  backgroundColor: "#df0c62",
                  borderRadius: "0",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
                onClick={() => edit(e_event)}
              >
                Etkinlik Düzenle
              </Button>
              {/* End OF Row Stack */}
            </Stack>
          ) : null}

          {!small ? (

            <div className="row counter-account-box pb-4" style={{ borderBottom: '2px solid #feb659' }}>

              <div className="col-12 d-flex align-items-end justify-content-between mb-4">
                <Typography variant="h5" fontWeight="bold" color="#df0c62">
                  Gişe
                </Typography>
                <small className="ms-2" >Gişe hesaplara giriş <a target="_blank" href="/login">login</a> sayfasından yapılabilir</small>
              </div>

              <div className="col-12 col-lg-6">
                <Typography variant="h6" sx={{ marginBottom: "20px" }} fontWeight="bold" color="#df0c62">
                  Satış Hesapları
                </Typography>
                {e_event?.counters?.map((account, index) => (
                  account.role == 'seller' ? (
                    <div className="row my-2" key={index} style={{ color: '#df0c62' }}>
                      <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                        <div className="">{account.email}</div>
                        <div className="p-2 cursor-pointer" onClick={() => { copy(account.email) }}><BiCopy /></div>
                      </div>
                      <div className="col-4 d-flex flex-row align-items-center justify-content-center">{account.firstName}</div>
                      <div className="col-3 d-flex flex-row align-items-center justify-content-center">
                        <div className="">{account.oPass}</div>
                        <div className="p-2 cursor-pointer" onClick={() => { copy(account.oPass) }}><BiCopy /></div>
                      </div>
                      <div className="col-1 d-flex flex-row align-items-center justify-content-center">
                        <div className="p-2 cursor-pointer" onClick={() => { remove(account._id) }}><BiTrash /></div>
                      </div>
                    </div>
                  ) : null
                ))}
                <div className="row my-2" style={{ color: '#df0c62' }}>
                  <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <input type="text" placeholder="email" value={newSeller.email} onChange={(e) => { setNewSeller({ ...newSeller, email: e.target.value }) }} />
                  </div>
                  <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <input type="text" placeholder="Ad Soyad" value={newSeller.firstName} onChange={(e) => { setNewSeller({ ...newSeller, firstName: e.target.value }) }} />
                  </div>
                  <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <div className="p-2 cursor-pointer d-flex align-items-center justify-content-between" onClick={() => { save(newSeller) }}><BiSave />&nbsp;Kaydet</div>
                  </div>
                </div>
              </div>


              <div className="col-12 col-lg-6">
                <Typography variant="h6" sx={{ marginBottom: "20px" }} fontWeight="bold" color="#df0c62">
                  Bilet Kontrol Hesapları
                </Typography>
                {e_event?.counters?.map((account, index) => (
                  account.role == 'scanner' ? (
                    <div className="row my-2" key={index} style={{ color: '#df0c62' }}>
                      <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                        <div className="">{account.email}</div>
                        <div className="p-2 cursor-pointer" onClick={() => { copy(account.email) }}><BiCopy /></div>
                      </div>
                      <div className="col-4 d-flex flex-row align-items-center justify-content-center">{account.firstName}</div>
                      <div className="col-3 d-flex flex-row align-items-center justify-content-center">
                        <div className="">{account.oPass}</div>
                        <div className="p-2 cursor-pointer" onClick={() => { copy(account.oPass) }}><BiCopy /></div>
                      </div>
                      <div className="col-1 d-flex flex-row align-items-center justify-content-center">
                        <div className="p-2 cursor-pointer" onClick={() => { remove(account._id) }}><BiTrash /></div>
                      </div>
                    </div>
                  ) : null
                ))}
                <div className="row my-2" style={{ color: '#df0c62' }}>
                  <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <input type="text" placeholder="email" value={newScanner.email} onChange={(e) => { setNewScanner({ ...newScanner, email: e.target.value }) }} />
                  </div>
                  <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <input type="text" placeholder="Ad Soyad" value={newScanner.firstName} onChange={(e) => { setNewScanner({ ...newScanner, firstName: e.target.value }) }} />
                  </div>
                  <div className="col-4 d-flex flex-row align-items-center justify-content-center">
                    <div className="p-2 cursor-pointer d-flex align-items-center justify-content-between" onClick={() => { save(newScanner) }}><BiSave />&nbsp;Kaydet</div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                {e_event?.counters?.filter((it) => it.role === 'seller')?.map((seller, sindex) => (
                  <div style={{ padding: '10px', margin: '10px', border: '1px solid #df0b62' }}>
                    <Typography variant="h7" sx={{ marginBottom: "20px" }} fontWeight="bold" color="#df0c62">
                      {seller.firstName + " satışları"}
                    </Typography>
                    {seller.orders.length ? (
                      <>
                        {seller.orders.map((c, inx) => (
                          <Box key={inx}>
                            <Accordion
                              sx={{
                                backgroundColor: "#fff0d7",
                                boxShadow: "none",
                              }}
                            >
                              <AccordionSummary
                                sx={{ paddingRight: "0" }}
                                expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Stack display="flex" direction="row" width="100%">
                                  <Typography color="#df0c62" fontWeight="bold">
                                    <>
                                      {c.customer ? (
                                        <>{c.customer.firstName + " " + c.customer.lastName}</>
                                      ) : c.guest ? (
                                        <>{c.guest.firstName + " " + c.guest.lastName}</>
                                      ) : null}
                                      {" ("}{showDiscountPrice(c.tickets.length)}{" Adet)"}
                                    </>
                                  </Typography>
                                  <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                                    <Typography
                                      color="#df0c62"
                                      fontWeight="bold"
                                      textAlign="end"
                                    >
                                      Bilgileri Gör
                                    </Typography>
                                  </Box>
                                </Stack>
                              </AccordionSummary>
                              <AccordionDetails>
                                {/* Detail parent Stack */}
                                <Stack display="flex" direction="column" spacing={4}>
                                  {/* First Part Stack */}
                                  <Stack
                                    display="flex"
                                    direction="row"
                                    spacing={2}
                                    sx={{ backgroundColor: "#fff0d7" }}
                                  >
                                    {/* Left Side */}
                                    <Box flex={1}>
                                      <Stack display="flex" direction="column" spacing={2}>
                                        <Box
                                          padding="8px 0"
                                          sx={{ borderBottom: "2px solid #feb659" }}
                                        >
                                          <Typography color="#df0c62" fontWeight="bold">
                                            {c.customer?.firstName || c.guest?.firstName}
                                          </Typography>
                                        </Box>
                                        <Box
                                          padding="8px 0"
                                          sx={{ borderBottom: "2px solid #feb659" }}
                                        >
                                          <Typography color="#df0c62" fontWeight="bold">
                                            {c.customer?.phoneNumber || c.guest?.phoneNumber}
                                          </Typography>
                                        </Box>
                                        <Box
                                          padding="8px 0"
                                          sx={{ borderBottom: "2px solid #feb659" }}
                                        >
                                          <Typography color="#df0c62" fontWeight="bold">
                                            Toplam {c.currency.symbol}{showDiscountPrice(c.total)} {c.payment === 'cash' && " (Nakit)"}
                                            {c.payment == 'card' && " (Kredi Karti)"}
                                            {c.payment == 'guest' && " (Misafir)"}
                                          </Typography>
                                        </Box>

                                      </Stack>
                                    </Box>
                                    {/* Right Side */}
                                    <Box flex={1}>
                                      <Stack display="flex" direction="column" spacing={2}>
                                        <Box
                                          padding="8px 0"
                                          sx={{ borderBottom: "2px solid #feb659" }}
                                        >
                                          <Typography color="#df0c62" fontWeight="bold">
                                            {c.customer?.lastName || c.guest?.lastName}
                                          </Typography>
                                        </Box>
                                        <Box
                                          padding="8px 0"
                                          sx={{ borderBottom: "2px solid #feb659" }}
                                        >
                                          <Typography color="#df0c62" fontWeight="bold">
                                            {c.customer?.email || c.guest?.email}
                                          </Typography>
                                        </Box>
                                        <Box
                                          padding="8px 0"
                                          sx={{ borderBottom: "2px solid #feb659" }}
                                        >
                                          <Typography color="#df0c62" fontWeight="bold">
                                            {c.created.split('T')[0] + " " + c.created.split('T')[1].split('.')[0]}
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Box>
                                    {/* End Of First Part Stack */}
                                  </Stack>

                                  <Stack display="flex" direction="column" spacing={2}>
                                    <Typography color="#df0c62" variant="h5" fontWeight="bold" >
                                      Etkinlik
                                    </Typography>
                                    <Stack display="flex" direction="column" spacing={2}>
                                      <Box
                                        padding="8px 0"
                                        sx={{ borderBottom: "2px solid #feb659", marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                      >
                                        <Typography color="#df0c62" fontWeight="bold">
                                          {c.tickets[0].event.title} at {c.tickets[0].event.location?.name}
                                        </Typography>
                                        {c.tickets[0].event.promoter ? (
                                          <Typography color="#df0c62" fontWeight="bold">
                                            {c.tickets[0].event.promoter.firstName + " " + c.tickets[0].event.promoter.lastName}
                                          </Typography>
                                        ) : null}

                                      </Box>
                                    </Stack>
                                  </Stack>

                                  <Stack display="flex" direction="column" spacing={2}>
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                      <Typography color="#df0c62" variant="h5" fontWeight="bold" >
                                        Biletler
                                      </Typography>
                                      <div className="centeredFlex d-inline-flex" style={c.tickets.find((it) => it.canceled) ? { opacity: 0.7, display: 'inline-flex' } : { display: 'inline-flex' }} >
                                        <input className="simpleInput me-3" type='text' value={c.emailToSend} placeholder={c.guest?.email || c.customer?.email} onChange={(e) => { setEmailToSend(e.target.value); }} />
                                        <div className="centeredFlex d-inline-flex" onClick={() => {
                                          if (window.confirm("Biletleri göndermek istediğinizde emin misiniz?")) onResendTickets(c._id, emailToSend);
                                        }}>
                                          <Typography color="#df0c62" fontWeight="bold">
                                            Mail Gönder
                                          </Typography>
                                          <IconButton size="medium">
                                            <Email sx={{ color: "#df0c62" }} />
                                          </IconButton>
                                        </div>
                                      </div>
                                    </div>
                                    {c.tickets.map((ticket, tindex) => (
                                      <div style={ticket.canceled ? { opacity: 0.4 } : null}>
                                        <Box
                                          padding="8px 0"
                                          sx={{ borderBottom: "2px solid #feb659", marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                        >
                                          <Typography color="#df0c62" fontWeight="bold">
                                            <span style={ticket.canceled ? { textDecoration: 'line-through' } : null}>
                                              {ticket.event.plan.startsFromOne ? ticket.trueLabel : ticket.label} ({c.currency.symbol + ticket.price}) {ticket.row ? ticket.row + "/" + (ticket.event.plan.startsFromOne ? ticket.trueSeat : ticket.seat) : null}
                                            </span>
                                          </Typography>

                                          {ticket.canceled ? (<Typography color="#df0c62" fontWeight="bold">
                                            Iptal Edildi
                                          </Typography>) : null}
                                          <div>
                                            <a href={process.env.REACT_APP_CDN_URL + 'pdfs/' + ticket._id + '.pdf'} target="_blank" className="centeredFlex d-inline-flex me-4" style={c.tickets.find((it) => it.canceled) ? { pointerEvents: 'none', display: 'inline-flex' } : { display: 'inline-flex' }} >
                                              <Typography color="#df0c62" fontWeight="bold">
                                                İndir
                                              </Typography>
                                              <IconButton size="medium">
                                                <Save sx={{ color: "#df0c62" }} />
                                              </IconButton>

                                            </a>
                                            <div onClick={() => {
                                              if (window.confirm("Bilet sılıyorsunuz. Emin misiniz?")) onCancel(ticket._id)
                                            }} className="centeredFlex" style={ticket.canceled ? { pointerEvents: 'none', display: 'inline-flex' } : { display: 'inline-flex' }} >
                                              <Typography color="#df0c62" fontWeight="bold">
                                                Iptal Et
                                              </Typography>
                                              <IconButton size="medium">
                                                <Close sx={{ color: "#df0c62" }} />
                                              </IconButton>

                                            </div>
                                          </div>
                                        </Box>
                                      </div>
                                    ))}

                                  </Stack>
                                  {/* End Of Accordion Detail Stack */}
                                </Stack>
                              </AccordionDetails>
                            </Accordion>
                            {/* Accordion End */}
                          </Box>
                        ))}
                      </>
                    ) : (
                      <div className="row">
                        <Typography variant="h7" sx={{ margin: "20px" }} fontWeight="bold" color="#df0c62">
                          {"Satış daha yapılmadı"}
                        </Typography>
                      </div>
                    )}
                  </div>
                ))}

              </div>



            </div>
          ) : null}
          {/* End Of Parent Stack */}
        </Stack>

        {isAdmin && !small ? (

          <Stack sx={{ marginTop: '30px' }} display="flex" direction="column" spacing={1}>
            <div className="col-12 d-flex align-items-end justify-content-between mb-4">
              <Typography variant="h5" fontWeight="bold" color="#df0c62">
                Sponsor
              </Typography>
            </div>
            <Stack dispay="flex" direction="row" >
              <Button
                variant="contained"
                fullWidth
                sx={{
                  border: "none",
                  backgroundColor: "#df0c62",
                  borderRadius: "0",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
                onClick={() => setSponsorModal(true)}
              >
                Sponsor Düzenle
              </Button>
            </Stack>
            <Box className={classes.colorBox}></Box>
          </Stack>
        ) : null}
      </Container>
    </Box>
  );
};

export default Desktop;
