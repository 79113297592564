import React from "react";
import Desktop from "../../../components/Panel/AdminPanel/Desktop";

const AdminPanel = () => {
  return (
    <React.Fragment>
      <Desktop />
    </React.Fragment>
  );
};

export default AdminPanel;
