import { Add } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import classes from "./styles.module.css";
const GridTwo = () => {
  // Image Top
  const [uploadedImage, setUploadedImage] = useState(null);
  // Handle Image Upload
  const handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImage(URL.createObjectURL(event.target.files[0]));
      console.log(event.target.files[0]);
    }
  };
  const ref = useRef();
  // handle Image Delete
  const handleImageDelete = () => {
    ref.current.value = ``;
  };
  ////////////////////////////////////////////////////////
  // Image Bottom
  const [uploadedImageSecond, setUploadedImageSecond] = useState(null);
  // Handle Image Upload
  const handleImageSecond = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageSecond(URL.createObjectURL(event.target.files[0]));
    }
  };
  const ref2 = useRef();
  // handle Image Delete
  const handleImageDeleteSecond = () => {
    ref2.current.value = ``;
  };

  return (
    <Box
      sx={{
        height: "650px",
        width: "650px",
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffb658",
        overflow: "hidden",
      }}
    >
      <Stack
        display="flex"
        direction="column"
        justifyContent="center"
        width="100%"
      >
        {/* Top Image */}
        <Box
          height="50%"
          width="100%"
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
          overflow="hidden"
          className={classes.templateOne}
        >
          {/* Image Div */}
          <Box sx={{ height: "100%", width: "100%" }}>
            {uploadedImage === null ? (
              <></>
            ) : (
              <img
                src={uploadedImage}
                alt="something"
                width="100%"
                height="100%"
              />
            )}
          </Box>

          {/* Upload Div */}
          {/* <Box
            position="absolute"
            sx={{
              top: "50%",
              left: "50%",
              translate: "-50% -50%",
            }}
          >
            <input
              multiple
              ref={ref}
              type="file"
              id="upload"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(event) => handleImage(event)}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <IconButton size="large">
                <label htmlFor="upload">
                  <Add sx={{ color: "#df0c62" }} fontSize="large" />
                </label>
              </IconButton>
            </Box>
          </Box> */}
        </Box>
        {/* Bottom Image */}
        <Box
          height="50%"
          width="100%"
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
          overflow="hidden"
          className={classes.templateTwo}
        >
          {/* Image Div */}
          <Box sx={{ height: "100%", width: "100%" }}>
            {uploadedImageSecond === null ? (
              <></>
            ) : (
              <img
                src={uploadedImageSecond}
                alt="something"
                width="100%"
                height="100%"
              />
            )}
          </Box>
          {/* Upload Div */}
          {/* <Box
            position="absolute"
            sx={{
              top: "50%",
              left: "50%",
              translate: "-50% -50%",
            }}
          >
            <input
              multiple
              ref={ref2}
              type="file"
              id="upload2"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(event) => handleImageSecond(event)}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <IconButton size="large">
                <label htmlFor="upload2">
                  <Add sx={{ color: "#df0c62" }} fontSize="large" />
                </label>
              </IconButton>
            </Box>
          </Box> */}
        </Box>
      </Stack>
    </Box>
  );
};

export default GridTwo;
