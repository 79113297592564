import { dataGet, dataPost } from "../Services/data.js";

export const addLocation = async (params) => {
  let location = {};
  await dataPost("location/addLocation", { ...params }).then((response) => {
    location = response.content;
  });
  return location;
};

export const listLocations = async () => {
  let locations = [];
  await dataGet("location/listAll").then((response) => {
    if (response && response.isSuccess) locations = response.content;
  });
  return locations;
};

export const updateLocation = async (params) => {
  let location = {};
  await dataPost("location/updateLocation", { ...params }).then((response) => {
    location = response.content;
  });
  return location;
};


export const deleteLocation = async (id) => {
  let location = false;
  await dataPost("location/deleteLocation", { _id: id }).then((response) => {
    location = response.isSuccess;
  });
  return location;
};
