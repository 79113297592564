import { Typography } from '@mui/material'
import {useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import MobileAppBar from '../AppBar/Mobile/TopBar/MobileAppBar'
import TopBar from '../AppBar/TopBar'
import './barcode.css'
import { useSearchParams } from "react-router-dom";
import {tryTicket,use} from "../../Data/ticket";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";

const Barcode = () => {
const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' })
  const isTablet = useMediaQuery({ query: '(min-width: 576px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 992px)' })
  const [searchParams, setSearchParams] = useSearchParams();
  const [counter,setCounter] = useState(localStorage.getItem('counter'));
  const [ticket,setTicket] = useState(null);
  const [used,setUsed] = useState(false);
  const [date,setDate] = useState(null);
  const [notFound,setNotFound] = useState(false);
  const [clientTime, setClientTime] = useState(-(new Date().getTimezoneOffset() / 60));
  const nav = useNavigate();

  const weekdays = [t("Sunday"), t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday")];

  const seriDigit = (num) => {
    if(num > 9) return num;
    else return '0'+num;
  }

  const getClientLocalTime = (date) => {

    let thisD = new Date(date);
    // thisD.setHours(thisD.getHours() + clientTime);
    let thour = seriDigit(thisD.getHours());
    let tminute = seriDigit(thisD.getMinutes());
    let tsecond = seriDigit(thisD.getSeconds());
    return thour+":"+tminute+":"+tsecond;
  }

  const getLocalDate = (dt) => {
    let date = new Date(dt);
    let weekday = weekdays[date.getDay()];
    getClientLocalTime(dt)
    return { date: dt.split('T')[0], week: weekday, time: getClientLocalTime(dt)}
  }

  const fetchTicket = async(id) => {
    try{
      let ticket = await tryTicket(id);
      if(ticket){
        setTicket(ticket);
        if(ticket.used) setDate(getLocalDate(ticket.usedTime))
        setNotFound(false)
      }

    }catch(e){ setNotFound(true); };

  }

  const submit = async (id) => {
    await use(id).then((r) => {
      if(r){
        // setUsed(true);
        fetchTicket(id);
        toast('Bilet başarıyla kullanıldı.')
      }else toast('Hata. Tekrar Deneyiniz.')
    })
  }

  useEffect(() => {
    let tid = searchParams.get('tid');
    if(tid) fetchTicket(tid);
    else setNotFound(true);
  }, []);

  useEffect(() => {
    if(!counter) nav('/login');
  }, [counter]);

  return (
    <div className='barcode-screen'>
      <MobileAppBar color="#ffd283" />
      {ticket && ticket._id ? (
        <>
        <h3 className='barcode-screen-heading-primary'>{ticket.event.title}</h3>
        <h4 className='barcode-screen-heading-secondary'>{ticket.event.location?.name}</h4>

        <h4 className='barcode-screen-heading-tertiary'>Tür</h4>
        <h4 className='barcode-screen-info'>{ticket.event.plan.startsFromOne ? (ticket.trueLabel || ticket.label) : ticket.label}</h4>
        {ticket.customer ? (
          <>
            <h4 className='barcode-screen-heading-tertiary'>Alıcı</h4>
            <h4 className='barcode-screen-info'>{ticket.customer.firstName+" "+ticket.customer.lastName}</h4>
          </>
        ):null}

        <div className='fixedAlt'>
        {ticket?.used ? (
            <h4>Used at {date?.time} </h4>
        ):(
          <button onClick={() => {submit(ticket._id)}} className='barcode-screen-button'>Girişi Onayla</button>
        )}
        </div>

        </>
      ): notFound ? (
        <h4 className='barcode-screen-heading-primary'>Bilet bulunmadı.</h4>
      ):null}

    </div>
  )
}

export default Barcode
