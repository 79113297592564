import {dataGet, dataPost} from "../Services/data.js";


export const loginAdmin = async (params) => {
    let admin = null;
    await dataPost('admin/login', {...params}).then((response) => {
        if (response && response.isSuccess) admin = response.content;
    });
    return admin;
}

export const listAll = async (params) => {
    let list = null;
    await dataGet('admin/listAll', {...params}).then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}

export const checkAdmin = async () => {
    let admin = null;
    await dataPost('admin/check').then((response) => {
        if (response && response.isSuccess) admin = response.content;
    });
    return admin;
}

export const getDashboard = async () => {
    let list = null;
    await dataGet('admin/getDashboard').then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}

export const listDesigners = async (params) => {
    let list = null;
    await dataGet('admin/listDesigners', {...params}).then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}

export const listAdmins = async (params) => {
    let list = null;
    await dataGet('admin/listAdmins', {...params}).then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}

export const updateProfile = async (profile) => {
    let admin = {};
    await dataPost('admin/updateProfile', {...profile}).then((_response) => {
        if (_response && _response.isSuccess)
            admin = _response.content;
    });
    return admin;
}

export const saveOne = async (_admin) => {
    let admin = null;
    let response = null;
    if(_admin._id) response = await dataPost('admin/updateProfile', {..._admin});
    else response = await dataPost('admin/create', {..._admin});
    if(response.isSuccess) admin = response.content;
    return admin;
}

export const changePassword = async (password) => {
    let admin = {};
    await dataPost('admin/changePassword', {password: password}).then((_response) => {
        if (_response && _response.isSuccess)
            admin = _response?.content;
    });
    return admin;
}



export const deleteAdmin = async (id) => {
    let admin = {};
    await dataPost('admin/deleteAdmin', {id: id}).then((response) => {
        if (response && response.isSuccess)
            admin = response.content;
    });
    return admin;
}
