import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopBar from "../../AppBar/TopBar";
import aidKit from "../../../Assets/Images/aidKit.png";
import smallTicket from "../../../Assets/Images/smallTicket.png";
import AutoInput from "../../AutoComplete/AutoInput";
import City from "../../../Assets/svg/city.svg";

import { listEvents } from "../../../Data/event";

import Footer from "../../Panel/Footer/Footer";
import classes from "./styles.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import queryString from 'query-string';
import { useTranslation } from "react-i18next";

const EventsDesktop = ({ events, detail, focusedImage, setEvents, setDetail, setFocusedImage, searched }) => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const navigate = useNavigate();
  const { t } = useTranslation()

  const evntsArray = [0, 1, 2, 3];
  return (
    <Box
      className={classes.container}
      sx={{ display: { xs: "none", md: "block" } }}
    >
      <TopBar color="white" />
      <Container maxWidth="md" sx={{paddingTop:'100px', marginBottom:{xs:'unset',md: '3rem'}}} >
        {/* Parent Stack */}
        <Stack display="flex" direction="column" spacing={4}>
          {/* Search Country */}
          <Stack display="flex" direction="column" spacing={1}>
            <Typography variant="h5" className={classes.searchTitle}>
              {t('find_in_your_country')}
            </Typography>
            <Box className={classes.searchCountry}>
                <AutoInput />
              <img className={classes.cityImage} src={City} />
            </Box>
          </Stack>
          {/* Event List */}
          {events.length > 0 ? (
          <Box>
            <Stack
              className={classes.eventsList}
              display="flex"
              direction="row"
              sx={{height:'500px'}}
              spacing={8}
            >
              <Box flex={0.5}>
                <Stack display="flex" direction="column" spacing={6}>
                  {events.map((item, index) => (
                    <Box key={index} sx={{ "&:hover": { cursor: "pointer" } }}>
                      <img
                        className={`${classes.eventListPoster} ${index === focusedImage
                          ? classes.focused
                          : classes.unfocused
                          }`}
                        src={CDN_URL + item.image}
                        alt={item.title}
                        onClick={() => {
                          setDetail(item);
                          setFocusedImage((prevValue) => index);
                        }}
                      />
                    </Box>
                  ))}
                </Stack>
              </Box>
              {detail ? (
                <Box flex={2} position="sticky" top={0}>
                  <Stack display="flex" direction="column" spacing={1}>
                    {/* Event Name Date and details */}
                    <Stack display="flex" direction="column" padding="32px 16px">
                      <Link to={'event/' + (detail?.slug || events[0]?.slug)}>
                        <Typography
                          variant="h4"
                          color="#df0c62"
                          className={classes.focusedTitle}
                        >
                          {detail ? detail.title : events[0].title}
                        </Typography>
                      </Link>
                      <Typography variant="h6" color="#df0c62">

                      </Typography>
                      <Typography paragraph sx={{ whiteSpace: 'pre-wrap',marginTop:'30px',height: '145px',overflow: 'hidden'}} color="#df0c62">
                        {detail ? detail.description.substring(0,500)+".." : events[0].description.substring(0,500)+".."}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "#df0c62",
                            fontWeight: "bold",
                          }}
                          href="*"
                        >
                          {detail ? (detail.location?.name) : (events[0].location?.name)}
                        </Link>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "2px dashed #df0c62",
                      }}
                    ></Box>
                    {/* Event's Available Dates */}
                    <Stack
                      display="flex"
                      direction="column"
                      className={classes.datesList}
                    >
                      <React.Fragment>
                        <Stack
                          display="flex"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            borderBottom: "1px solid #ffd283",
                            padding: "8px 0",
                          }}
                        >
                          <Box flex={1}>
                            <Typography variant="h6" color="#df0c62">
                              {detail ? detail.startDate.split('T')[0] + " " + detail.startDate.split('T')[1] : events[0].startDate.split('T')[0] + " " + events[0].startDate.split('T')[1]}
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            sx={{ display: "flex", justifyContent: "end" }}
                          >
                            <Box
                              flex={1}
                              className={classes.buyContainer}
                              onClick={() => navigate('/event/' + (detail?.slug || events[0]?.slug))}
                            >

                              <Typography className={classes.buyText}>
                                {t('Buy')}
                              </Typography>

                              <img
                                src={smallTicket}
                                alt="smallTicket"
                                className={classes.smallTicket}
                              />

                            </Box>
                          </Box>
                        </Stack>
                      </React.Fragment>
                    </Stack>
                  </Stack>
                </Box>
              ) : null}

            </Stack>
          </Box>
        ): searched ? (
          <Typography
            variant="h4"
            color="#df0c62"
            className={classes.focusedTitle}
          >
           {t('not_found')}
          </Typography>
        ):null}
          {/* End Of Parent Stack */}
        </Stack>
      </Container>

      <Footer />
    </Box>
  );
};

export default EventsDesktop;
