import {
    Box,
    Button,
    Container,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React, { Component, useState } from 'react'
import ReactToPrint from 'react-to-print'
import classes from "../Panel/AdminPanel/Sponsor/Add Sponsor/styles.module.css";
import './pdf.css'
import Barcode from "react-barcode";
import logo from "../../Assets/Images/logo.png";
import Image from "./Image";

class Ticket extends Component {

    render() {
        const { data, t } = this.props
        const { event, ticket, sponsors } = data
        const { title, location, date, weekday, hour, currency } = event
        const { code, type, row, chair, price } = ticket


        const RepeatedRow = ({ left, right }) => (
            <Stack display="flex" direction="row">
                <Box flex={1}>
                    <Typography fontWeight="bold" fontSize={10}>
                        {left}
                    </Typography>
                </Box>
                <Box flex={2}>
                    <Typography fontWeight="bold" fontSize={10}>
                        {right}
                    </Typography>
                </Box>
            </Stack>
        );

        return (
            <div>
                <Box paddingBottom={5}>
                    <Container maxWidth="lg" sx={{ paddingTop: "5%" }}>
                        <Stack display="flex" direction="column" spacing={4}>
                            <Container maxWidth="md">
                                <Stack
                                    display="flex"
                                    direction="column"
                                    spacing={2}
                                    className='innerContainer'
                                >
                                    <Stack
                                        display="flex"
                                        direction="column"
                                        className='ticket'
                                        spacing={6}
                                    >
                                        <Stack display="flex" direction="row" spacing={1}>
                                            <Box
                                                flex={0.5}
                                                className='barcode'
                                                display="flex"
                                                justifyContent="center"
                                            >
                                                <Barcode
                                                    value={code}
                                                    marginBottom={0}
                                                    background="transparent"
                                                />
                                            </Box>
                                            <Box flex={3} className='ticketDetails'>
                                                <Stack display="flex" direction="column" spacing={1}>
                                                    <Typography
                                                        variant="h5"
                                                        fontSize={20}
                                                        fontWeight="bold"
                                                    >
                                                        {title}
                                                    </Typography>
                                                    <Typography fontSize={15} fontWeight="bold">
                                                        {location}
                                                    </Typography>
                                                    <Stack display="flex" direction="column">
                                                        <RepeatedRow left="Tarih:" right={date} />
                                                        <RepeatedRow left="Gun:" right={weekday} />
                                                        <RepeatedRow left="Saat" right={hour} />
                                                    </Stack>
                                                    <Stack display="flex" direction="column">
                                                        <RepeatedRow left="Blok:" right={type} />
                                                        <RepeatedRow left="Sira" right={row} />
                                                        <RepeatedRow left="Koltuk" right={chair} />
                                                    </Stack>
                                                    <Stack
                                                        display="flex"
                                                        direction="row"
                                                        justifyContent="space-evenly"
                                                        sx={{
                                                            borderRadius: "50px",
                                                            border: "2px solid #df0c62",
                                                            padding: "2.5%",
                                                        }}
                                                    >
                                                        <Typography fontWeight="bold" fontSize={10}>
                                                            {`Total Price : ${price} ${currency}`}
                                                        </Typography>
                                                        
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            <Box
                                                flex={1}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <img src={logo} alt="logo" className='logo' />
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <div className="row">
                                        {
                                            sponsors.map(sponsor => (
                                                <div className="col-6">
                                                    <Image src={sponsor} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Stack>

                            </Container>

                        </Stack>
                    </Container>
                </Box>
            </div>

        )
    }
}

class TicketPdf extends Component {
    render() {
        const { data, t } = this.props;
        return (
            <div>
                <ReactToPrint
                    trigger={() => <p className='text-decoration-underline'>Print</p>}
                    content={() => this.componentRef}
                    // pageStyle={{ padding: '10px' }}
                    documentTitle={'asd'}
                />
                <div className='d-none'>
                    <Ticket data={data} t={t} ref={el => this.componentRef = el} />
                </div>
            </div>
        )
    }
}

export default TicketPdf
