import React from 'react'
import './image2.css'

const Image = props => {

    return (
        <div className='image2_wrapper'>
            <img style={props.style}
                src={props.src}
                alt='sponsor'
                {...props}
            />
        </div>
    )
}

export default Image