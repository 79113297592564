import React,{useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import HomeMobile from "../../components/Home/Mobile/MobileHome";
import HomeTablet from "../../components/Home/Tablet/HomeTablet";
import HomeDesktop from "../../components/Home/Desktop/HomeDesktop";

const Home = () => {

  const [searchParams,setSearchParams] = useSearchParams();

  const loc = useLocation();
  const nav = useNavigate();

  const orderControl = async () => {
    if(window.location.search.includes('?orderSucc')){
      let lev = window.location.search.split('orderSucc=')[1].split('&')[0];
      console.log(lev);
      if(lev == '2') nav('/seller'+window.location.search);
      else if(lev == '1') nav('/congratulations'+window.location.search);
    }
  }

  useEffect(() => {
    orderControl()
  },[window.location]);

  return (
    <React.Fragment>
      <HomeMobile />
      <HomeDesktop />
    </React.Fragment>
  );
};

export default Home;
