import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ticket from "../../Assets/Images/ticketBig.svg";
import logo from "../../Assets/Images/logo.png";

const Mobile = () => {
  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      <Box
        sx={{
          display: { xs: "inline-block" },
          position: { xs: "absolute" },
          top: { xs: "-4%" },
          right: { xs: "-33%" },
          transform: { xs: "rotate(145deg)" },
        }}
      >
        <img src={ticket} alt="ticket" style={{ width: "100%" }} />
      </Box>
      <Box
        sx={{
          display: { xs: "inline-block" },
          position: { xs: "absolute" },
          bottom: { xs: "2.5%" },
          left: { xs: "5%" },
        }}
      >
        <img src={logo} alt="Logo" style={{ width: "45%" }} />
      </Box>
      <Stack
        display="flex"
        direction="column"
        spacing={3}
        sx={{
          width: "100%",
          padding: "16px",
          marginTop: "22.5%",
        }}
      >
        <Typography
          className="label"
          variant="h5"
          fontWeight="bold"
          sx={{ color: "#df0c62", zIndex: "1" }}
        >
          Giriş Yap
        </Typography>
        <input className="ePosta" placeholder="E-Posta" />
        <input className="şifre" placeholder="Şifre" type="password" />

        <Stack display="flex" direction="column" spacing={1}>
          <Button
            variant="contained"
            style={{
              borderRadius: "0",
              backgroundColor: "#feb659",
              border: "none",
              padding: "16px",
              color: "white",
              fontSize: "16px",
            }}
          >
            Giriş Yap
          </Button>
          <Typography
            variant="h6"
            fontSize="14px"
            textAlign="center"
            color="#feb659"
          >
            Şifreni mi unuttun?
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Mobile;
