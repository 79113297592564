import {dataGet, dataPost} from "../Services/data.js";


export const loginCounter = async (params) => {
    let counter = null;
    await dataPost('counter/login', {...params}).then((response) => {
        if (response && response.isSuccess) counter = response.content;
    });
    return counter;
}

export const listAll = async (params) => {
    let list = null;
    await dataGet('counter/listAll', {...params}).then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}


export const getDashboard = async () => {
    let list = null;
    await dataGet('counter/getDashboard').then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}

export const listDesigners = async (params) => {
    let list = null;
    await dataGet('counter/listDesigners', {...params}).then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}

export const listCounters = async (params) => {
    let list = null;
    await dataGet('counter/listCounters', {...params}).then((response) => {
        if (response && response.isSuccess) list = response.content;
    });
    return list;
}

export const updateProfile = async (profile) => {
    let counter = {};
    await dataPost('counter/updateProfile', {...profile}).then((_response) => {
        if (_response && _response.isSuccess)
            counter = _response.content;
    });
    return counter;
}

export const saveOne = async (_counter) => {
    let counter = null;
    let response = null;
    if(_counter._id) response = await dataPost('counter/updateProfile', {..._counter});
    else response = await dataPost('counter/create', {..._counter});
    if(response.isSuccess) counter = response.content;
    return counter;
}

export const changePassword = async (password) => {
    let counter = {};
    await dataPost('counter/changePassword', {password: password}).then((_response) => {
        if (_response && _response.isSuccess)
            counter = _response?.content;
    });
    return counter;
}



export const deleteCounter = async (id) => {
    let counter = {};
    await dataPost('counter/deleteCounter', {id: id}).then((response) => {
        if (response && response.isSuccess)
            counter = response.content;
    });
    return counter;
}
