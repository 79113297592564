import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../Assets/Images/logo.png";
import classes from "./styles.module.css";
const MobileAppBar = ({ color, shadow = [] }) => {
  const navigate = useNavigate();

  return (
    <div style={{ position: "absolute", top: "0%" }}>
      <Box
        className={classes.navBar}
        sx={{
          display: { xs: "inline-block", md: "none" },
        }}
      >
        {/* Mobile View */}
        <Box
          className={classes.mobileBox}
          sx={{
            backgroundColor: color,
            boxShadow: `0px 3px 0px 3px rgba(${shadow})`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => navigate("/")}
          >
            <img src={logo} alt="logo" style={{ width: "auto", height:'40px' }} />
          </Box>
        </Box>
        <Box className={classes.mobileCurve}></Box>
      </Box>
    </div>
  );
};

export default MobileAppBar;
