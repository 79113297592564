import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import NavBar from "../../../NavBar/NavBar";
import classes from "../Add Sponsor/styles.module.css";
import Barcode from "react-barcode";
import logo from "../../../../../Assets/Images/logo.png";
import TicketPdf from "../../../../pdf/Ticket";
import { useTranslation } from "react-i18next";
const AddSponsor = ({ Children }) => {
  const [isClicked, setIsClicked] = useState("box1");
  const { t } = useTranslation();

  const RepeatedRow = ({ left, right }) => (
    <Stack display="flex" direction="row">
      <Box flex={1}>
        <Typography fontWeight="bold" fontSize={10}>
          {left}
        </Typography>
      </Box>
      <Box flex={2}>
        <Typography fontWeight="bold" fontSize={10}>
          {right}
        </Typography>
      </Box>
    </Stack>
  );

  const data = {
    event: {
      title: "KADINLAR Metinesi",
      location: "Hansonzetrumm",
      date: "29/9/2022",
      weekday: "wednesday",
      hour: "22:30",
      currency: "$",
    },
    ticket: {
      code: "Barcode",
      type: "Salon",
      row: 3,
      chair: 24,
      price: 15,
    },
    sponsors: [
      "https://cloudajans.xyz/e-ticket/public/Montreal_science_centre.jpg",
      "https://cloudajans.xyz/e-ticket/public/Montreal_science_centre.jpg",
    ],
  };

  return (
    <Box height="50vh">
      <Container maxWidth="lg">
        <Stack display="flex" direction="column" spacing={4}>
          {<TicketPdf data={data} t={t} />}
          <Stack
            display="flex"
            direction="column"
            className={classes.parentContainer}
          >
            <Box width="100%">
              <Typography
                variant="h4"
                color="#df0c62"
                padding={4}
                textAlign="start"
                fontWeight="bold"
              >
                Sponsor Düzenle
              </Typography>
            </Box>
            <Container maxWidth="md">
              <Stack
                display="flex"
                direction="column"
                spacing={2}
                className={classes.innerContainer}
              >
                <Stack
                  display="flex"
                  direction="column"
                  className={classes.ticket}
                  spacing={6}
                >
                  <Stack display="flex" direction="row" spacing={1}>
                    <Box
                      flex={0.5}
                      className={classes.barcode}
                      display="flex"
                      justifyContent="center"
                    >
                      <Barcode
                        value="Barcode"
                        marginBottom={0}
                        background="transparent"
                      />
                    </Box>

                    <Box flex={3} className={classes.ticketDetails}>
                      <Stack display="flex" direction="column" spacing={1}>
                        <Typography
                          variant="h5"
                          fontSize={20}
                          fontWeight="bold"
                        >
                          KADINLAR METINESI
                        </Typography>
                        <Typography fontSize={15} fontWeight="bold">
                          Hansonzentrumm
                        </Typography>
                        <Stack display="flex" direction="column">
                          <RepeatedRow left="Tarih:" right="29/9/2022" />
                          <RepeatedRow left="Gun:" right="Carsamba" />
                          <RepeatedRow left="Saat" right="22:30" />
                        </Stack>
                        <Stack display="flex" direction="column">
                          <RepeatedRow left="Blok:" right="Salon" />
                          <RepeatedRow left="Sira" right="3" />
                          <RepeatedRow left="Koltuk" right="24" />
                        </Stack>
                        <Stack
                          display="flex"
                          direction="row"
                          justifyContent="space-evenly"
                          sx={{
                            borderRadius: "50px",
                            border: "2px solid #df0c62",
                            padding: "2.5%",
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={10}>
                            Bilet bedeli:15,000$
                          </Typography>
                          <Typography fontWeight="bold" fontSize={10}>
                            Servis bedeli:15,000$
                          </Typography>
                          <Typography fontWeight="bold" fontSize={10}>
                            Toplam Tutar:15,000$
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img src={logo} alt="logo" className={classes.logo} />
                    </Box>
                  </Stack>

                  {/* column Stack */}
                </Stack>

                {Children}

                {/* end of InnerContainer */}
              </Stack>
            </Container>
          </Stack>

          {/* end of Parent Stack */}
        </Stack>
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#df0c62",
            "&:hover": { backgroundColor: "#df0c62" },
            borderRadius: "0",
            marginTop: "2%",
          }}
        >
          sponsor seç
        </Button>
      </Container>
    </Box>
  );
};

export default AddSponsor;
