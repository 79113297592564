import { Add } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useRef, useState } from "react";

const GridThree = ({commitImage, sponsors}) => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  // Image Top Left 1
  const [uploadedImageLeft, setUploadedImageLeft] = useState(sponsors?.split(',')[0]);
  // Handle Image Upload
  const handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageLeft(URL.createObjectURL(event.target.files[0]));
      console.log(event.target.files[0]);
      commitImage(event.target.files[0],0);
    }
  };
  const ref = useRef();
  // handle Image Delete
  const handleImageDelete = () => {
    ref.current.value = ``;
  };
  ////////////////////////////////////////////////////////
  // Image Top Right 2
  const [uploadedImageRight, setUploadedImageRight] = useState(sponsors?.split(',')[1]);
  // Handle Image Upload
  const handleImageRight = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageRight(URL.createObjectURL(event.target.files[0]));
      commitImage(event.target.files[0], 1);
    }
  };
  const ref2 = useRef();
  // handle Image Delete
  const handleImageDeleteSecond = () => {
    ref2.current.value = ``;
  };

  ////////////////////////////////////////////////////////
  // Image Bottom 3
  const [uploadedImageBottom, setUploadedImageBottom] = useState(sponsors?.split(',')[2]);
  // Handle Image Upload
  const handleImageThird = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageBottom(URL.createObjectURL(event.target.files[0]));
      commitImage(event.target.files[0], 2);
    }
  };
  const ref3 = useRef();
  // handle Image Delete
  const handleImageDeleteThird = () => {
    ref3.current.value = ``;
  };

  return (
    <Box
      sx={{
        height: "650px",
        width: "650px",
        // display: "flex",
        // justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffb658",
        overflow: "hidden",
      }}
    >
      <Stack display="flex" direction="column" width="100%" height="100%">
        <Stack display="flex" direction="row" width="100%" height="50%">
          {/* Image Top Left */}
          <Box height="100%" width="100%" position="relative" overflow="hidden">
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {!uploadedImageLeft? (
                <></>
              ) : (
                <img
                  src={uploadedImageLeft && !uploadedImageLeft.includes('blob') ? (CDN_URL+uploadedImageLeft) : uploadedImageLeft}
                  alt=""
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

            {/* Upload Div */}
            <Box
              position="absolute"
              sx={{
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <input
                multiple
                ref={ref}
                type="file"
                id="upload"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleImage(event)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  translate: "-50% -50%",
                }}
              >
                <IconButton size="large">
                  <label htmlFor="upload">
                    <Add sx={{ color: "#df0c62" }} fontSize="large" />
                  </label>
                </IconButton>
              </Box>
            </Box>
          </Box>

          {/* Image Top Right */}
          <Box height="100%" width="100%" position="relative" overflow="hidden">
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {!uploadedImageRight? (
                <></>
              ) : (
                <img
                  src={uploadedImageRight && !uploadedImageRight.includes('blob') ? (CDN_URL+uploadedImageRight) : uploadedImageRight}
                  alt=""
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

            {/* Upload Div */}
            <Box
              position="absolute"
              sx={{
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <input
                multiple
                ref={ref}
                type="file"
                id="upload2"
                style={{ display: "none" }}
                accept="image/*"
                onChange={(event) => handleImageRight(event)}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  translate: "-50% -50%",
                }}
              >
                <IconButton size="large">
                  <label htmlFor="upload2">
                    <Add sx={{ color: "#df0c62" }} fontSize="large" />
                  </label>
                </IconButton>
              </Box>
            </Box>
          </Box>
          {/* End of Top Row Stack */}
        </Stack>

        <Box height="50%" width="100%" position="relative">
          {/* Image Top Left */}

          {/* Image Div */}
          <Box sx={{ objectFit: "cover" }}>
            {!uploadedImageBottom ? (
              <></>
            ) : (
              <img
                src={!uploadedImageBottom.includes('blob') ? (CDN_URL+uploadedImageBottom) : uploadedImageBottom}
                alt=""
                width="100%"
                height="100%"
              />
            )}
          </Box>

          {/* Upload Div */}
          <Box
            position="absolute"
            sx={{
              top: "50%",
              left: "50%",
              translate: "-50% -50%",
            }}
          >
            <input
              multiple
              ref={ref}
              type="file"
              id="upload3"
              style={{ display: "none" }}
              accept="image/*"
              onChange={(event) => handleImageThird(event)}
            />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            >
              <IconButton size="large">
                <label htmlFor="upload3">
                  <Add sx={{ color: "#df0c62" }} fontSize="large" />
                </label>
              </IconButton>
            </Box>
          </Box>
        </Box>

        {/* End of Parent Column Stack */}
      </Stack>
    </Box>
  );
};

export default GridThree;
