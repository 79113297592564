import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import {useNavigate} from 'react-router-dom';
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";

import logo from "../../../Assets/Images/logo.png";
export default function ButtonAppBar({ color }) {
  const StyledToolBar = styled("div")({
    display: "flex",
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 20px",
  });
  const [_accessTimer,setAccessTimer] = React.useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const accessControl = async () => {
    let access = await localStorage.getItem('access');
    if(access && access == 'denied'){
      if(_accessTimer) clearTimeout(_accessTimer)
      let accessTimer = setTimeout(async() => {
        await localStorage.removeItem('access');
        toast.error(t('error_try_again'));
      },3000);
      setAccessTimer(accessTimer);
    }
  }

  React.useEffect(() => {
    window.addEventListener("storage", async () => {
      accessControl();
    });
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: `${color}` }}>
        <StyledToolBar>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
            }}
          >
            <img src={logo} alt="logo" width="50%" />
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "center",
            }}
          >
            <img src={logo} alt="logo" width="35%" />
          </Box>
        </StyledToolBar>
      </AppBar>
    </Box>
  );
}
