import React, {useEffect,useState} from "react";
//
import EventsTablet from "../components/Events/Tablet/EventsTablet";
import EventsMobile from "../components/Events/Mobile/EventsMobile";
import EventsDesktop from "../components/Events/Desktop/EventsDesktop";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {searchEvents,listEvents} from "../Data/event";


const Events = () => {


  const [detail, setDetail] = useState(null);
  const [focusedImage, setFocusedImage] = useState(0);
  const [events, setEvents] = useState([]);


  const location = useLocation();
  // const [query, setQuery] = useState(location.search.substring(location.search.indexOf('=') + 1))
  let query = location.search.substring(location.search.indexOf('=') + 1)

  useEffect(() => {
    query = (location.search.substring(location.search.indexOf('=') + 1))
    console.log(query)
  })

  const fetchEvents = async (query) => {
    let list = query ? await searchEvents(query) : await listEvents();
    setDetail(list[0]);
    setEvents(list);
  }

  useEffect(() => {
    fetchEvents(query);
  }, [query]);

  // const fetch =
  return (
    <React.Fragment>
      <EventsMobile events={events}
      detail={detail}
      focusedImage={focusedImage}
      setEvents={setEvents}
      setDetail={setDetail}
      setFocusedImage={setFocusedImage}
      searched={query} />
      <EventsDesktop events={events}
      detail={detail}
      focusedImage={focusedImage}
      setEvents={setEvents}
      setDetail={setDetail}
      setFocusedImage={setFocusedImage}
      searched={query} />
    </React.Fragment>
  );
};

export default Events;
