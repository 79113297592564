import axios from "axios";
import data from "./fetch.js";
import multipart from "./multifetch.js";
import reserve from "./reserve.js";
import cookies from "react-cookies";
import keys from "./config";

// const apiUrl = "https://eticketal.com"+'/'+keys.app.apiURL+'/';
const apiUrl = keys.app.backUrl+'/';

const removeCaptcha = () => {
  localStorage.removeItem("captcha");
  window.dispatchEvent(new Event("storage"));
};

export const control = (resp) => {
  if(resp.isSuccess) return resp.content; else return null;
}

export const dataGet = async (url, params) => {
  let urlParams = "?o=1";
  let resp = null;
  let headers = {"Content-Type": "application/json"};

  let token = await localStorage.getItem("local");
  if(token) headers["Authorization"] = "Bearer "+token;

  let proToken = await localStorage.getItem("promoter");
  if(proToken) headers["Authorization"] = (typeof headers["Authorization"] == 'undefined') ? "Bearer "+proToken : headers["Authorization"]+";"+proToken;

  let adminToken = await localStorage.getItem("admin");
  if(adminToken) headers["Authorization"] = (typeof headers["Authorization"] == 'undefined') ? "Bearer "+adminToken : headers["Authorization"]+";"+adminToken;

  let counterToken = await localStorage.getItem("counter");
  if(counterToken) headers["Authorization"] = (typeof headers["Authorization"] == 'undefined') ? "Bearer "+counterToken : headers["Authorization"]+";"+counterToken;

  let selectedCountry = await localStorage.getItem('e-ticket-al country');
  if(selectedCountry) urlParams += ('&country='+selectedCountry);

  let lang = await localStorage.getItem("e-ticket-al language");
  let curr = await localStorage.getItem("e-ticket-al currency");

  headers = {...headers, Cookies: "lang=" + lang + ";curr=" + curr };


  if (params) Object.keys(params).map((key, kindex) => {
      urlParams += `&${key}=${params[key]}`;
    });

    let req = await fetch(apiUrl+url+urlParams,{
      headers
    })
    .then(async(response) => {
      console.log(response);
      return await response.json()
    }).then((body) => {
      if (body?.isSuccess) resp = body;
    }).catch((e) => {
      localStorage.setItem('access','denied');
      window.dispatchEvent(new Event("storage"));
    });


    return resp;
}

export const dataPost = async (url, params) => {

  let resp = null;
  let headers = {"Content-Type": "application/json"};
  let pars = {};
  if(params && Object.keys(params)?.length) pars = {...params};

  let token = await localStorage.getItem("local");
  if(token) headers["Authorization"] = "Bearer "+token;

  let proToken = await localStorage.getItem("promoter");
  if(proToken) headers["Authorization"] = (typeof headers["Authorization"] == 'undefined') ? "Bearer "+proToken : headers["Authorization"]+";"+proToken;

  let adminToken = await localStorage.getItem("admin");
  if(adminToken) headers["Authorization"] = (typeof headers["Authorization"] == 'undefined') ? "Bearer "+adminToken : headers["Authorization"]+";"+adminToken;

  let counterToken = await localStorage.getItem("counter");
  if(counterToken) headers["Authorization"] = (typeof headers["Authorization"] == 'undefined') ? "Bearer "+counterToken : headers["Authorization"]+";"+counterToken;

  let selectedCountry = await localStorage.getItem('e-ticket-al country');
  if(selectedCountry) pars['country'] = selectedCountry;

  let lang = await localStorage.getItem("e-ticket-al language");
  let curr = await localStorage.getItem("e-ticket-al currency");

  headers = {...headers, Cookies: "lang=" + lang + ";curr=" + curr };

  await fetch(apiUrl+url,{
    method: 'POST',
    body: JSON.stringify({...pars}),
    headers
  })
  .then(async(response) => {
    return await response.json()
  }).then((body) => {
    if (body?.isSuccess) resp = body;
    else if(body?.message != '') resp = body.message;
  }).catch((e) => {
    localStorage.setItem('access','denied');
    window.dispatchEvent(new Event("storage"));
  });

  return resp;
}

export const dataPut = async (url, params) => {
  let response = {};
  let list = [];
  let token = null;

  let local = await localStorage.getItem("local");
  let admin = await localStorage.getItem("admin");
  if (admin) list.push(admin);
  if (local) list.push(local);
  if (admin || local) token = list.join(";");

  let lang = await localStorage.getItem("language");
  let curr = await localStorage.getItem("currency");

  let headers = { Cookies: "lang=" + lang + ";curr=" + curr };
  if (token) headers["Authorization"] = "Bearer " + token;

  let captcha = await localStorage.getItem("captcha");
  if (captcha) headers = { ...headers, captcha: captcha };

  let sessreq = await data
    .put(url, { ...params }, { headers: headers })
    .then((_response) => {
      if (_response?.data?.isSuccess) response = _response.data;
      removeCaptcha();
    })
    .catch((e) => {
      removeCaptcha();
    });
  return response;
};

export const dataDelete = async (url, id) => {
  let response = {};
  let nurl = url + "/" + id;

  let token = null;
  let list = [];

  let local = await localStorage.getItem("local");
  let admin = await localStorage.getItem("admin");
  if (admin) list.push(admin);
  if (local) list.push(local);
  if (admin || local) token = list.join(";");

  let lang = await localStorage.getItem("language");
  let curr = await localStorage.getItem("currency");

  let headers = { Cookies: "lang=" + lang + ";curr=" + curr };
  if (token) headers["Authorization"] = "Bearer " + token;

  let captcha = await localStorage.getItem("captcha");
  if (captcha) headers = { ...headers, captcha: captcha };

  let sessreq = await data
    .delete(nurl, { headers: headers })
    .then((_response) => {
      if (_response?.data?.isSuccess) response = _response.data;
      removeCaptcha();
    })
    .catch((e) => {
      removeCaptcha();
    });

  return response;
};

export const dataFile = async (url,file,type) => {
  let response = {};
  let formData = new FormData();
  formData.append('image',file);
  formData.append('type',type);
  await multipart.post(url,formData).then((_response) => {
    if(_response?.data?.isSuccess) response = _response.data;
  });
  return response;
}

export const dataFiles = async (url, files, params, conf) => {
  let response = {};
  let headers = {};
  var formData = new FormData();
  // let token = await localStorage.getItem("local");
  // if (await localStorage.getItem("admin"))
  //   token = await localStorage.getItem("admin");
  // let captcha = await localStorage.getItem("captcha");
  // headers = { ...headers, "Content-Type": "multipart/form-data" };
  // if (token) headers = { ...headers, Authorization: "Bearer " + token };
  // if (captcha) headers = { ...headers, captcha: captcha };
  let urlParams = "?o=1";
  let tval;
  if (params) Object.keys(params).map((key, kindex) => {
      tval = params[key];
      urlParams += `&${key}=${tval}`;
    });
  if (files) Array.from(files).map((key, kindex) => {
      formData.append("images", key);
    });
  let sessreq = await multipart.post(url + urlParams, formData, { ...conf, headers: headers }).then((_response) => {
      if (_response?.data?.isSuccess) response = _response.data;
    }).catch((e) => {});
  return response;
};
