import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TopBar from "../components/AppBar/TopBar";
import cookies from "react-cookies";
import "../Assets/Styles/Profile.css";
import logo from "../Assets/Images/logo.png";
import { toast } from "react-toastify";
import say from "../Services/language";
import {
  getProfile,
  updateProfile,
  changePassword,
  listTickets,
} from "../Data/customer";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useSearchParams, useNavigate } from "react-router-dom";
import FooterTablet from "../components/Panel/Footer/Tablet/FooterTablet";
import Footer from "../components/Panel/Footer/Footer";
import FooterMobile from "../components/Panel/Footer/Mobile/FooterMobile";
import MobileNavBar from "../components/AppBar/Mobile/NavBar/MobileNavBar";
import MobileAppBar from "../components/AppBar/Mobile/TopBar/MobileAppBar";
import axios from "axios";
import { Dialog, DialogContent, CircularProgress } from "@mui/material";
import { saveAs } from "file-saver";

const Profile = () => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    birth_day: "",
  });
  const [pass, setPass] = useState({ pass: "", passrep: "" });
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState({
    profile: true,
    safety: false,
    tickets: false,
  });

  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 576px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const [loadingTic, setLoadingTic] = useState(false);

  const fetch = async () => {
    let access = await localStorage.getItem("local");
    if (!access) {
      navigate("/login");
      return;
    }
    setProfile(await getProfile());
    setTickets(await listTickets());
    if (searchParams.get("tickets"))
      setExpanded({ profile: false, safety: false, tickets: true });
  };

  const onUpdateProfile = async () => {
    setLoading(true);
    await updateProfile(profile).then((r) => {
      setLoading(false);
      if (!r) toast.warning(t("error_try_again"));
      else toast.success(t("profile_updated"));
    });
  };

  const onUpdatePassword = async () => {
    setLoading(true);
    await changePassword(pass.pass).then((r) => {
      setLoading(false);
      if (!r) toast.warning(t("error_try_again"));
      else toast.success(t("password_reset_success"));
    });
  };

  const cookiesConfig = {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE,
    path: process.env.REACT_APP_BASE_URL,
  };
  const clearCustomerData = async () => {
    await cookies.remove("customerFirstName", cookiesConfig);
    await cookies.remove("customerLastName", cookiesConfig);
    await cookies.remove("customerEmail", cookiesConfig);
    await cookies.remove("customerPhoneNumber", cookiesConfig);
    await cookies.remove("customerAddress", cookiesConfig);
    await localStorage.removeItem("local");
  };

  const logout = async () => {
    await clearCustomerData().then(() => {
      navigate("/");
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Box
      sx={{
        marginTop: { xs: "70px", md: 0 },
        height: "fit-content",
        width: "100vw",
      }}
    >
      {isMobile ? <MobileAppBar color="#f2c981" /> : <TopBar color="#f2c981" />}

      <Container maxWidth="md" sx={{ paddingTop: "8%", height: "90%" }}>
        <Typography variant="h5" fontWeight="bold" color="#df0c62">
          {t("My Account")}
        </Typography>
        <Accordion
          expanded={expanded.profile}
          elevation={0}
          sx={{ borderBottom: "2px solid #f2c981" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
            onClick={() => {
              setExpanded({ ...expanded, profile: !expanded.profile });
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ paddingLeft: "0" }}
          >
            <Typography color="#df0c62" variant="h6">
              {t("Profile")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack display="flex" direction="column" spacing={2}>
              {/* Row 1 */}
              <Stack display="flex" direction="row" spacing={2}>
                <Box flex={1}>
                  <Typography color="#df0c62">{t("First Name")}</Typography>
                  <input
                    value={profile.firstName}
                    onChange={(e) => {
                      setProfile({ ...profile, firstName: e.target.value });
                    }}
                    style={{
                      width: "100%",
                      border: "2px solid #df0c62",
                      outline: "none",
                      padding: "8px",
                    }}
                  />
                </Box>
                <Box flex={1}>
                  <Typography color="#df0c62">{t("Last Name")}</Typography>
                  <input
                    value={profile.lastName}
                    onChange={(e) => {
                      setProfile({ ...profile, lastName: e.target.value });
                    }}
                    style={{
                      width: "100%",
                      border: "2px solid #df0c62",
                      outline: "none",
                      padding: "8px",
                    }}
                  />
                </Box>
              </Stack>
              {/* Row 2 */}
              <Stack display="flex" direction="column">
                <Typography color="#df0c62">{t("Phone Number")}</Typography>
                <Stack display="flex" direction="row" spacing={2}>
                  <Box flex={2}>
                    <input
                      value={profile.phoneNumber}
                      onChange={(e) => {
                        setProfile({ ...profile, phoneNumber: e.target.value });
                      }}
                      style={{
                        width: "100%",
                        border: "2px solid #df0c62",
                        outline: "none",
                        padding: "8px",
                      }}
                    />
                  </Box>
                </Stack>
              </Stack>
              {/* Row 3 */}
              <Stack display="flex" direction="row" spacing={2}>
                <Box flex={1}>
                  <Typography color="#df0c62">{"E-Mail"}</Typography>
                  <input
                    value={profile.email}
                    onChange={(e) => {
                      setProfile({ ...profile, email: e.target.value });
                    }}
                    style={{
                      width: "100%",
                      border: "2px solid #df0c62",
                      outline: "none",
                      padding: "8px",
                    }}
                  />
                </Box>
                <Box flex={1}>
                  <Typography color="#df0c62">{t("Birth Date")} </Typography>
                  <input
                    value={profile.birth_day}
                    onChange={(e) => {
                      setProfile({ ...profile, birth_day: e.target.value });
                    }}
                    style={{
                      width: "100%",
                      border: "2px solid #df0c62",
                      outline: "none",
                      padding: "8px",
                    }}
                  />
                </Box>
              </Stack>
              <Box display="flex" justifyContent="end">
                <Button
                  onClick={!loading ? onUpdateProfile : null}
                  className={loading ? "loadingBut" : null}
                  variant="contained"
                  sx={{
                    backgroundColor: "#df0c62",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                >
                  {t("Edit")}
                </Button>
              </Box>
              {/* End Of Parent Stack */}
            </Stack>
          </AccordionDetails>
        </Accordion>

        {/* Second Accordion */}
        <Box>
          <Accordion
            expanded={expanded.safety}
            elevation={0}
            sx={{ borderBottom: "2px solid #f2c981" }}
          >
            <AccordionSummary
              onClick={() => {
                setExpanded({ ...expanded, safety: !expanded.safety });
              }}
              expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ paddingLeft: "0" }}
            >
              <Typography color="#df0c62" variant="h6">
                {t("Safety")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Stack
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="start"
                    height="100%"
                  >
                    <Typography color="#df0c62">{t("Password")}</Typography>
                    <input
                      value={pass.pass}
                      onChange={(e) => {
                        setPass({ ...pass, pass: e.target.value });
                      }}
                      style={{
                        width: "100%",
                        border: "2px solid #df0c62",
                        outline: "none",
                        padding: "8px",
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography color="#df0c62">
                    {t("Confirm Password")}
                  </Typography>
                  <input
                    value={pass.passrep}
                    onChange={(e) => {
                      setPass({ ...pass, passrep: e.target.value });
                    }}
                    style={{
                      width: "100%",
                      border: "2px solid #df0c62",
                      outline: "none",
                      padding: "8px",
                    }}
                  />
                </Grid>
              </Grid>

              <Box display="flex" sx={{ width: "100%" }} justifyContent="end">
                <Button
                  variant="contained"
                  className={loading ? "loadingBut" : null}
                  onClick={!loading ? onUpdatePassword : null}
                  sx={{
                    marginTop: "30px",
                    backgroundColor: "#df0c62",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                >
                  {t("Edit")}
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Dialog
          open={loadingTic}
          PaperProps={{
            style: {
              backgroundColor: "rgba(0, 0, 0, 1)", // Semi-transparent black
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          <DialogContent
            style={{
              color: "white",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <p>İndiriliyor, lütfen bekleyin...</p>
          </DialogContent>
        </Dialog>

        {/* Third Accordion */}
        <Box>
          <Accordion
            expanded={expanded.tickets}
            elevation={0}
            sx={{ borderBottom: "2px solid #f2c981" }}
          >
            <AccordionSummary
              onClick={() => {
                setExpanded({ ...expanded, tickets: !expanded.tickets });
              }}
              expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ paddingLeft: "0" }}
            >
              <Typography color="#df0c62" variant="h6">
                {t("My Tickets")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {tickets.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sx={
                      item.canceled
                        ? {
                            marginTop: "30px",
                            pointerEvents: "none",
                            opacity: 0.5,
                          }
                        : { marginTop: "30px" }
                    }
                  >
                    {/* <a style={{ color: '#df0c62',display:'block' }} target="_blank" href={CDN_URL + 'pdfs/' + item._id + ".pdf"}>Indir</a> */}
                    <button
                      style={{
                        color: "#df0c62",
                        display: "block",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                      onClick={async () => {
                        setLoadingTic(true); // Start loading
                        try {
                          const response = await axios.get(
                            `${process.env.REACT_APP_BACK_URL}/api/ticket/download/${item._id}`,
                            {
                              responseType: "blob", // Important to receive the data as a Blob
                            }
                          );

                          // Use file-saver to save the Blob as a file
                          const blob = new Blob([response.data], {
                            type: "application/pdf",
                          });
                          saveAs(blob, `${item._id}.pdf`);
                        } catch (error) {
                          console.error("Error downloading the PDF:", error);
                          // Optionally, display an error message to the user
                        } finally {
                          setLoadingTic(false); // Stop loading
                        }
                      }}
                    >
                      Indir
                    </button>
                    <Box className={"celebTicket"}>
                      <Stack
                        display="flex"
                        direction="column"
                        justifyContent="center"
                        alignItems="start"
                        height="100%"
                        padding={{ xs: "0 7%", md: "0 25%" }}
                      >
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          textAlign="center"
                          color="#df0c62"
                          sx={{ textDecoration: "underline" }}
                        >
                          {item.event.title}
                        </Typography>

                        <Typography color="#df0c62">
                          {item.event.location?.name}
                        </Typography>
                        <br />
                        <Typography
                          variant="h6"
                          color="#df0c62"
                          fontWeight="bold"
                        >
                          {item.event.plan.startsFromOne
                            ? item.trueLabel
                            : item.label}{" "}
                          {item.row
                            ? item.row +
                              "/" +
                              (item.event.plan.startsFromOne
                                ? item.trueSeat
                                : item.seat)
                            : null}
                        </Typography>
                        <br />
                        <div className="row w-100">
                          <div className="col-6">
                            <Typography color="#df0c62">
                              {t("ticket_date")}
                            </Typography>
                            <Typography color="#df0c62">
                              {item.event.doorOpenDate.split("T")[0]}
                            </Typography>
                          </div>
                          <div className="col-6">
                            <Typography color="#df0c62">
                              {t("ticket_dooropen")}
                            </Typography>
                            <Typography color="#df0c62">
                              {item.event.doorOpenDate.split("T")[1]}
                            </Typography>
                          </div>
                        </div>

                        <br />
                        <img src={logo} alt="logo" width="50%" />
                      </Stack>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Box
            sx={{
              borderBottom: "2px solid #f2c981",
              padding: "10px 0",
              marginBottom: "100px",
            }}
          >
            <Typography
              color="#df0c62"
              variant="h6"
              style={{ cursor: "pointer" }}
              onClick={() => logout()}
            >
              {t("Logout")}
            </Typography>
          </Box>
        </Box>
      </Container>
      <div>
        {isMobile ? (
          <FooterMobile />
        ) : isBigScreen ? (
          <Footer />
        ) : (
          <FooterTablet />
        )}
      </div>
      <div>{isMobile && <MobileNavBar />}</div>
    </Box>
  );
};

export default Profile;
