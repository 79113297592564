import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import NavBar from "../../../NavBar/NavBar";
import classes from "./styles.module.css";
import Barcode from "react-barcode";
import logo from "../../../../../Assets/Images/logo.png";
import { Add } from "@mui/icons-material";
import GridOne from "../SponsorGrid/GridOne";
import GridTwo from "../SponsorGrid/GridTwo";
import GridThree from "../SponsorGrid/GridThree";
import GridFour from "../SponsorGrid/GridFour";
import TicketPdf from "../../../../pdf/Ticket";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import pdfBack from "../../../../../Assets/Images/endTicket.jpg";
import scissors from "../../../../../Assets/Images/scissors.svg";
import { saveImage } from "../../../../../Data/general";
import { setSponsors } from "../../../../../Data/event";

const AddSponsor = ({thisEvent, onClose, commit}) => {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isClicked, setIsClicked] = useState("box1");
  const { t } = useTranslation();
  const [date,setDate] = useState(null);
  const [images,setImages] = useState([]);

  const commitImage = (img,pos) => {
    let imgs = [...images];
    imgs[pos] = img;
    setImages(imgs);
  }

  const defineDate = () => {
    const weekday = ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"];
    let dstr = thisEvent.startDate.split(' ')[0].split('.');
    let ndstr = dstr[2] + '-' + dstr[1] + '-' + dstr[0];
    let odate = new Date(ndstr);
    let dateString = thisEvent.startDate.split(' ')[0].replaceAll('.', '/');
    let dateWeekDay = weekday[odate.getDay()];
    let dateTime = thisEvent.startDate.split(' ')[1];
    setDate({ date: dateString, week: dateWeekDay, time: dateTime });
  }

  const saveSponsor = async () => {

    let uploadList = [];
    let count = parseInt(isClicked.replace('box',''));
    for(let i = 0; i <= count - 1; i++){
      uploadList.push(Array.from(images)[i]);
    }

    let positions = [];
    if(uploadList.length == 1) positions = ['bigSquare'];
    else if(uploadList.length == 2) positions = ['landscape','landscape'];
    else if(uploadList.length == 3) positions = ['square','square','landscape'];
    else if(uploadList.length == 4) positions = ['square','square','square','square'];

    Promise.all(
      uploadList.map((img,ind) => {

        return new Promise(async(resolve,reject) => {
          await saveImage(img,positions[ind]).then((r) => {
            if(r) resolve(r);
            else reject();
          });
        });
      })
    ).then(async(list) => {
      let sponsors = list.join(',');
      await setSponsors(thisEvent._id, sponsors).then((r) => {
        commit();
      });
    });
  }

  const initSponsors = () => {
    let length = 0;
    if(thisEvent.sponsors && thisEvent.sponsors != ""){
      length = thisEvent.sponsors.split(',').length;
    }else length = 0;

    switch(length){
      case 1: setIsClicked('box1'); break;
      case 2: setIsClicked('box2'); break;
      case 3: setIsClicked('box3'); break;
      case 4: setIsClicked('box4'); break;
      default: setIsClicked('box1');
    }
  }

  useEffect(() => {
    initSponsors();
    defineDate();
  },[]);


  const RepeatedRow = ({ left, right }) => (
    <Stack display="flex" direction="row">
      <Box flex={1}>
        <Typography fontWeight="bold" fontSize={10}>
          {left}
        </Typography>
      </Box>
      <Box flex={2}>
        <Typography fontWeight="bold" fontSize={10}>
          {right}
        </Typography>
      </Box>
    </Stack>
  );

  const data = {
    event: {
      title: "KADINLAR Metinesi",
      location: "Hansonzetrumm",
      date: "29/9/2022",
      weekday: "wednesday",
      hour: "22:30",
      currency: "$",
    },
    ticket: {
      code: "Barcode",
      type: "Salon",
      row: 3,
      chair: 24,
      price: 15,
    },
    sponsors: [
      "https://cloudajans.xyz/e-ticket/public/Montreal_science_centre.jpg",
      "https://cloudajans.xyz/e-ticket/public/Montreal_science_centre.jpg",
    ],
  };

  return (
    <Box paddingBottom={5}>
      {/*<NavBar color="#ffd283" />*/}
      <Container maxWidth="lg" sx={{ paddingTop: "5%" }}>
        <Stack display="flex" direction="column" spacing={4}>

          {/* Page title */}
          {/*  <Typography variant="h4" className={classes.titleStroke}>
            ADMİN PANELİ
          </Typography>*/}
          <Stack
            display="flex"
            direction="column"
            className={classes.parentContainer}
          >
            <Box width="100%" sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <Typography
                variant="h4"
                color="#df0c62"
                padding={4}
                textAlign="start"
                fontWeight="bold"
              >
                SPONSOR OLUŞTUR
              </Typography>
              <div
                onClick={onClose}
                style={{ cursor: "pointer" }}
                className="text-end me-4"
              >
                Kapat
              </div>
            </Box>
            <Container maxWidth="md">
              <Stack
                display="flex"
                direction="column"
                spacing={2}
                className={classes.innerContainer}
              >
                <div
                  id="box1"
                  className={
                    isClicked === "box1"
                      ? classes.leftBox1Active
                      : classes.leftBox1
                  }
                  onClick={(e) => setIsClicked(e.target.id)}
                >
                  1
                </div>
                <div
                  id="box2"
                  className={
                    isClicked === "box2"
                      ? classes.leftBox2Active
                      : classes.leftBox2
                  }
                  onClick={(e) => setIsClicked(e.target.id)}
                >
                  2
                </div>
                <div
                  id="box3"
                  className={
                    isClicked === "box3"
                      ? classes.leftBox3Active
                      : classes.leftBox3
                  }
                  onClick={(e) => setIsClicked(e.target.id)}
                >
                  3
                </div>
                <div
                  id="box4"
                  className={
                    isClicked === "box4"
                      ? classes.leftBox4Active
                      : classes.leftBox4
                  }
                  onClick={(e) => setIsClicked(e.target.id)}
                >
                  4
                </div>
                <Stack
                  display="flex"
                  direction="column"
                  className='ticket'
                  spacing={6}
                  sx={{ position: 'relative',marginBottom:'60px!important' }}
                >
                  <img style={{ position: 'absolute', width: '100%' }} src={pdfBack} />
                  <Stack sx={{ position: 'relative', paddingLeft: '20px', marginTop: '40px!Important;' }} display="flex" direction="row" spacing={1}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: '50px' }}
                    >
                      <img src={logo} alt="logo" className='logo' />
                    </Box>
                    <Box flex={3} className='ticketDetails'>
                      <Stack display="flex" direction="column" spacing={1}>
                        <Typography
                          variant="h5"
                          fontSize={20}
                          fontWeight="bold"
                        >
                          {thisEvent.title}
                        </Typography>
                        <Typography fontSize={15} fontWeight="bold">
                          {thisEvent.location?.name}
                        </Typography>
                        <Stack display="flex" direction="column">
                          <RepeatedRow left={t('ticket_date')} right={date?.date.split('T')[0]} />
                          <RepeatedRow left={t('ticket_day')} right={date?.week} />
                          <RepeatedRow left={t('ticket_time')} right={date?.date.split('T')[1]} />
                          {thisEvent?.promoter ? (
                            <RepeatedRow left="Promoter" right={thisEvent?.promoter?.firstName + " " + thisEvent?.promoter?.lastName} />
                          ) : null}

                        </Stack>
                        {thisEvent.plan.map?.rows ? (
                          <Stack display="flex" direction="column">
                            <RepeatedRow left={t('ticket_block')} right={'Ana Salon'} />
                            <RepeatedRow left={t('ticket_row')} right={'7'} />
                            <RepeatedRow left={t('ticket_seat')} right={'10'} />
                          </Stack>
                        ) : (
                          <Stack display="flex" direction="column">
                            <RepeatedRow left={t('ticket_block')} right={"Ana Salon"} />
                          </Stack>
                        )}

                        {thisEvent.promoter ? (
                          <Stack display="flex" direction="column">
                            <RepeatedRow left={t('ticket_promoter')} right={thisEvent.promoter.businessName} />
                          </Stack>
                        ):null}

                        <Stack
                          display="flex"
                          direction="row"
                          justifyContent="space-evenly"
                          sx={{
                            borderRadius: "50px",
                            border: "2px solid #df0c62",
                            padding: "2.5%",
                          }}
                        >
                          <Typography fontWeight="bold" fontSize={10}>
                            {`${t('ticket_price')} $15 ${thisEvent.currency.symbol}`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Box>

                    <Box
                      flex={0.5}
                      className='qrticket'
                      display="flex"
                      justifyContent="center"
                    >
                      <QRCode value={BASE_URL + "/scanner?tid=63fcdbe4b979aca3c6bbbe6b"} />
                      {/*  <Barcode
                            value={ticket?._id}
                            marginBottom={0}
                            background="transparent"
                        />*/}
                    </Box>
                  </Stack>
                </Stack>
                <div>
                  <img src={scissors} />
                </div>

                {isClicked === "box1" && <GridOne sponsors={thisEvent.sponsors} commitImage={commitImage} />}
                {isClicked === "box2" && <GridTwo sponsors={thisEvent.sponsors} commitImage={commitImage} />}
                {isClicked === "box3" && <GridThree sponsors={thisEvent.sponsors} commitImage={commitImage} />}
                {isClicked === "box4" && <GridFour sponsors={thisEvent.sponsors} commitImage={commitImage} />}

                {/* end of InnerContainer */}
              </Stack>
            </Container>
          </Stack>

          {/* end of Parent Stack */}
        </Stack>
        <Button
          variant="contained"
          fullWidth
          onClick={saveSponsor}
          sx={{
            backgroundColor: "#df0c62",
            "&:hover": { backgroundColor: "#df0c62" },
            borderRadius: "0",
            marginTop: "2%",
          }}
        >
          SPONSOR OLUŞTUR
        </Button>
      </Container>
    </Box>
  );
};

export default AddSponsor;
