import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Container, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import Footer from "../../../Footer/Footer";
import NavBar from "../../../NavBar/NavBar";
import SearchBar from "../../../SearchBar/SearchBar";
import classes from "./styles.module.css";
const Desktop = () => {
  const rows = [1, 2, 3, 4, 5, 6, 7];
  return (
    <Box>
      <NavBar color="#ffd283" />
      <Container maxWidth="lg">
        {/* Parent Stack */}
        <Stack
          display="flex"
          direction="column"
          sx={{ paddingTop: "5%" }}
          spacing={4}
        >
          <Typography variant="h5" color="#df0c62" fontWeight="bold">
            ORGANİZATÖR PANELİ
          </Typography>
          {/* Search Bar */}
          <SearchBar />
          <Stack
            display="flex"
            direction="column"
            padding={4}
            className={classes.scroll}
          >
            <Typography variant="h6" color="#df0c62" fontWeight="bold">
              KATILIMCI BİLGİLERİ
            </Typography>

            {/* Rows */}
            {rows.map((r, i) => {
              return (
                <Stack display="flex" direction="row" mt={2}>
                  <Box alignItems="center" display="flex">
                    <Typography color="#df0c62">Lorem Ipsum :</Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    sx={{ borderBottom: "2px solid #feb659" }}
                  >
                    <Typography color="#df0c62">PDF indir</Typography>
                    <IconButton>
                      <FileDownloadOutlined sx={{ color: "#feb659" }} />
                    </IconButton>
                  </Box>
                </Stack>
              );
            })}
          </Stack>
          {/* End Of Parent Stack */}
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
};

export default Desktop;
