import { Add } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import classes from "./styles.module.css";
const GridFour = () => {
  // Image Top Left 1
  const [uploadedImageTopLeft, setUploadedImageTopLeft] = useState(null);
  // Handle Image Upload
  const handleImageTopLeft = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageTopLeft(URL.createObjectURL(event.target.files[0]));
      console.log(event.target.files[0]);
    }
  };
  const ref = useRef();
  // handle Image Delete
  const handleImageDeleteTopLeft = () => {
    ref.current.value = ``;
  };

  ////////////////////////////////////////////////////////
  // Image Top Right 2
  const [uploadedImageTopRight, setUploadedImageTopRight] = useState(null);
  // Handle Image Upload
  const handleImageTopRight = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageTopRight(URL.createObjectURL(event.target.files[0]));
    }
  };
  const ref2 = useRef();
  // handle Image Delete
  const handleImageDeleteTopRight = () => {
    ref2.current.value = ``;
  };

  // Image Bottom Left 1
  const [uploadedImageBottomLeft, setUploadedImageBottomLeft] = useState(null);
  // Handle Image Upload
  const handleImageBottomLeft = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageBottomLeft(URL.createObjectURL(event.target.files[0]));
      console.log(event.target.files[0]);
    }
  };
  const ref4 = useRef();
  // handle Image Delete
  const handleImageDeleteBottomLeft = () => {
    ref.current.value = ``;
  };

  // Image Bottom Left 1
  const [uploadedImageBottomRight, setUploadedImageBottomRight] =
    useState(null);
  // Handle Image Upload
  const handleImageBottomRight = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImageBottomRight(URL.createObjectURL(event.target.files[0]));
      console.log(event.target.files[0]);
    }
  };
  const ref3 = useRef();
  // handle Image Delete
  const handleImageDeleteBottomRight = () => {
    ref.current.value = ``;
  };
  return (
    <Box
      sx={{
        height: "650px",
        width: "650px",
        // display: "flex",
        // justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffb658",
        overflow: "hidden",
      }}
    >
      <Stack display="flex" direction="column" height="100%" width="100%">
        {/* Top Row */}
        <Stack display="flex" direction="row" height="50%" width="100%">
          {/* Left Side */}
          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
            className={classes.templateOne}
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {uploadedImageTopLeft === null ? (
                <></>
              ) : (
                <img
                  src={uploadedImageTopLeft}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

          </Box>

          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
            className={classes.templateTwo}
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {uploadedImageTopRight === null ? (
                <></>
              ) : (
                <img
                  src={uploadedImageTopRight}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

          </Box>
        </Stack>
        {/* Bottom Row */}
        <Stack display="flex" direction="row" height="50%" width="100%">
          {/* Left Side */}
          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
            className={classes.templateThree}
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {uploadedImageBottomLeft === null ? (
                <></>
              ) : (
                <img
                  src={uploadedImageBottomLeft}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

          </Box>

          <Box
            sx={{ width: "50%", height: "100%" }}
            position="relative"
            overflow="hidden"
            className={classes.templateFour}
          >
            {/* Image Div */}
            <Box sx={{ objectFit: "cover" }}>
              {uploadedImageBottomRight === null ? (
                <></>
              ) : (
                <img
                  src={uploadedImageBottomRight}
                  alt="something"
                  width="100%"
                  height="100%"
                />
              )}
            </Box>

          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GridFour;
