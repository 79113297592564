import { Component } from "react";
import ReactToPrint from "react-to-print";
import logo from "../../../../../Assets/Images/logo.png";
import { Box, Stack, Typography } from "@mui/material";

class Infos extends Component {

    render() {
        const { data, t } = this.props
        const today = new Date().toISOString().substring(0, 10).split("T")[0].replaceAll("-", ".")
        return (
            <div>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <img src={logo} alt="logo" width="20%" />
                    <div style={{ fontSize: '10px' }}>
                        {today}
                    </div>
                </div>
                <div className="d-flex justify-content-between border-bottom" style={{ fontSize: '12px' }}>
                    <div>Etkinlik</div>
                    <div>Bilet</div>
                    <div>Komisyon</div>
                    <div>Kazanç</div>
                    <div>Ödenilecek</div>
                    <div>Gelir</div>
                </div>
                <div className="d-flex justify-content-between mb-2" style={{ fontSize: '10px' }}>
                    <div>   {data.title}</div>
                    <div> {data.soldTickets}</div>
                    <div> {data.commission}%</div>
                    <div>  {data.total?.total} {data.currency}</div>
                    <div> {data.total?.dept} {data.currency}</div>
                    <div> {data.total?.proearn} {data.currency}</div>
                </div>
                <div className="d-flex justify-content-between mb-2" style={{ fontSize: '10px' }}>
                    <div className="w-100">
                        <div>
                            <div>
                                Bilet Türü
                            </div>
                            <div>
                                {data.types.map((ttype, tind) => (
                                    <div
                                        key={tind}
                                        style={{
                                            width: "90%",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <label style={{ fontSize: '9px' }}>
                                            {ttype.title} - {ttype.price}{" "}
                                            {data.currency} -{" "}
                                            {ttype.sold} (
                                            {ttype.sold * ttype.price}{" "}
                                            {data.currency})
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div>
                                Ödeme Türü
                            </div>
                            <div
                                style={{
                                    width: "90%",
                                    marginLeft: "auto",
                                }}
                            >
                                <label>
                                    Kart - {data.total.cardVal} -{" "}
                                    {data.total.card} {data.currency}
                                </label>
                            </div>
                            <div
                                style={{
                                    width: "90%",
                                    marginLeft: "auto",
                                }}
                            >
                                <label>
                                    Nakit - {data.total.cashVal} -{" "}
                                    {data.total.cash} {data.currency}
                                </label>
                            </div>

                        </div>
                    </div>
                    <div className="w-100">
                        <div
                            style={{
                                borderBottom: "1px solid",
                                width: "100%",
                            }}
                        >
                            Satış / Katılım:{" "}
                            <label style={{ float: "right" }}>
                                {data.soldToUsed.sold} /{" "}
                                {data.soldToUsed.used}
                            </label>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid",
                                width: "100%",
                            }}
                        >
                            Toplam Kazanç:{" "}
                            <label style={{ float: "right" }}>
                                {data.total.total} {data.currency}
                            </label>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid",
                                width: "100%",
                            }}
                        >
                            Platform Kazancı ({data.commission}%):{" "}
                            <label style={{ float: "right" }}>
                                {data.total.earning} {data.currency}
                            </label>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid",
                                width: "100%",
                            }}
                        >
                            Benim Kazançım:{" "}
                            <label style={{ float: "right" }}>
                                {data.total.proearn} {data.currency}
                            </label>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid",
                                width: "100%",
                            }}
                        >
                            Ödenilen Toplam:{" "}
                            <label style={{ float: "right" }}>
                                {data.paymentsTotal || 0}{" "}
                                {data.currency}
                            </label>
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid",
                                width: "100%",
                            }}
                        >
                            Ödenilecek Miktar:{" "}
                            <label style={{ float: "right" }}>
                                {data.total.dept} {data.currency}
                            </label>
                        </div>
                    </div>

                </div>
                <div style={{ fontSize: '10px' }}>
                    <div style={{ fontSize: '12px' }}>
                        Ödeme Geçmişi
                    </div>
                    <div className="d-flex flex-column w-100 pb-3">
                        {data.payments?.length ? (
                            <>
                                {data.payments.map((payment, pindex) => (
                                    <Box
                                        style={{ width: "100%" }}
                                        key={pindex}
                                    >
                                        <Stack
                                            display="flex"
                                            direction="row"
                                            justifyContent="space-between"
                                            sx={{
                                                borderBottom:
                                                    "1px solid",
                                                padding: "0 16px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "space-between",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Typography style={{ fontSize: '10px' }}
                                                    >
                                                        {payment.amount}{" "}
                                                        {data.currency}
                                                    </Typography>

                                                    <Typography

                                                        style={{ fontSize: '10px' }}
                                                    >
                                                        {payment.created
                                                            .split("T")[0]
                                                            .replaceAll("-", ".")}
                                                    </Typography>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        textAlign: "center",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "space-between",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            width: "100%",
                                                            textAlign: "center",
                                                            fontSize: '10px'
                                                        }}

                                                    >
                                                        {payment.comment}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Stack>
                                    </Box>
                                ))}
                            </>
                        ) : (
                            <Typography
                                color="#df0c62"
                                fontWeight="bold"
                            >
                                Boş
                            </Typography>
                        )}
                    </div>
                </div>

            </div>
        )
    }



}

class InfosPdf extends Component {
    render() {
        const { data, t } = this.props;
        return (
            <div>
                <ReactToPrint
                    trigger={() => <p className='border border-danger rounded-5 p-2 cursor-pointer' style={{ color: '#df0c62' }}>Bilgileri İndir</p>}
                    content={() => this.componentRef}
                    pageStyle={{ padding: '10px' }}
                    documentTitle={'infos'}
                />
                <div className='d-none'>
                    <Infos data={data} t={t} ref={el => this.componentRef = el} />
                </div>
            </div>
        )
    }
}

export default InfosPdf