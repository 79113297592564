module.exports = {
  app: {
    name: 'E-Ticket Al WebApp',
    apiURL:  process.env.REACT_APP_BASE_API_URL,
    serverURL: process.env.REACT_APP_BACK_URL,
    clientURL: process.env.REACT_APP_BASE_URL,
    backUrl: process.env.REACT_APP_BACK_URL+"/"+process.env.REACT_APP_BASE_API_URL
  },
  port: process.env.PORT || 8000,
  cookieMaxAge: parseInt(process.env.REACT_APP_COOKIE_MAX_AGE), // you will die in 7 days
  cookiesConfig: {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE,
    path: process.env.REACT_APP_BASE_URL
  },
  database: {
    url: process.env.MONGO_URI,
    host: process.env.MONGO_URI?.split('@')[1].split(':')[0]
  },
  jwt: {
    secret: process.env.JWT_SECRET,
    tokenLife: '7d'
  },
  google: {
    // clientID: process.env.GOOGLE_CLIENT_ID,
    // clientSecret: process.env.GOOGLE_CLIENT_SECRET,
    // callbackURL: process.env.GOOGLE_CALLBACK_URL
  },
  facebook: {
    // clientID: process.env.FACEBOOK_CLIENT_ID,
    // clientSecret: process.env.FACEBOOK_CLIENT_SECRET,
    // callbackURL: process.env.FACEBOOK_CALLBACK_URL
  }
};
