import {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import guy from "../../../../../Assets/Images/guy.jpg";
import Charts from "../../../Stats/Charts";
import NavBar from "../../../NavBar/NavBar";
import {toast} from "react-toastify";
import { getById,approveEvent } from "../../../../../Data/event";
const RequestsDesktop = () => {
  const navigate = useNavigate();
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const { id } = useParams();
  const [tevent, setevent] = useState(null);
  const [declining, setDeclining] = useState(false);
  const [commission, setComission] = useState(10);
  const fetch = async () => { setevent(await getById(id)); };
  const committing = async (status) => {
      await approveEvent({
        _id: id,
        commission: (commission && parseInt(commission) > 0) ? parseInt(commission) : false,
        status
      }).then((r) => {
        if(r){
          if(status)toast("Etkinlik başarıyla onaylayndı.");
          else toast("Etkinlik başarıyla reddedildi.");
          navigate("/admin");
        }else toast("Hata. Tekrar deneyiniz.");
      })
  }
  useEffect(() => { fetch() },[]);
  return (
    <Box>
      <NavBar color="#ffd289" />
      {/* Parent Stack */}
      {tevent ? (
        <Container>
          <Stack
            display="flex"
            direction="column"
            sx={{ backgroundColor: "#fff0d7", padding: "32px" }}
            spacing={2}
            mt={"5%"}
          >
            <Box paddingLeft={4} marginBottom={2}>
              <Typography variant="h5" fontWeight="bold" color="#df0c62">
                ETKİNLİK TALEBİ
              </Typography>
            </Box>
            {/* Row 1 */}
            <Stack display="flex" direction="row" spacing={2}>
              {/* Left Side */}
              <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                <Typography fontWeight="bold" color="#df0c62" variant="h6">
                  Etkinlik Adı
                </Typography>

                <Box sx={{ padding: "8px 0" }}>
                  <Typography fontWeight="bold" color="#df0c62">
                    {tevent.title}
                  </Typography>
                </Box>
              </Box>
              {/* Right Side */}
              <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                <Typography fontWeight="bold" color="#df0c62" variant="h6">
                  Etkinlik Tarihi
                </Typography>

                <Box sx={{ padding: "8px 0" }}>
                  <Typography fontWeight="bold" color="#df0c62">
                    {tevent.startDate}
                  </Typography>
                </Box>
              </Box>
            </Stack>

            {/* Row 2 */}
            <Stack display="flex" direction="row" spacing={2}>
              {/* Left Side */}
              <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                <Typography fontWeight="bold" color="#df0c62" variant="h6">
                  Etkinlik Şehri
                </Typography>

                <Box sx={{ padding: "8px 0" }}>
                  <Typography fontWeight="bold" color="#df0c62">
                    {tevent.location?.city} / {tevent.location?.country}
                  </Typography>
                </Box>
              </Box>
              {/* Right Side */}
              <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                <Typography fontWeight="bold" color="#df0c62" variant="h6">
                  Etkinlik Sahnesi
                </Typography>

                <Box sx={{ padding: "8px 0" }}>
                  <Typography fontWeight="bold" color="#df0c62">
                    {tevent.location?.name}
                  </Typography>
                </Box>
              </Box>
            </Stack>

            {/* Row 3 */}
            <Stack display="flex" direction="row" spacing={2}>
              {/* Left Side */}
              <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                <Typography fontWeight="bold" color="#df0c62" variant="h6">
                  Etkinlik Türü
                </Typography>

                <Box sx={{ padding: "8px 0" }}>
                  <Typography fontWeight="bold" color="#df0c62">
                    {tevent.type}
                  </Typography>
                </Box>
              </Box>
              {/* Right Side */}

            </Stack>


            {/* Event Poster */}
            <Stack
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ borderBottom: "2px solid #feb659", paddingBottom: "16px" }}
            >
              <Box width="100%">
                <Typography
                  variant="h6"
                  color="#df0c62"
                  fontWeight="bold"
                  textAlign="start"
                >
                  Etkinlik Afiş/Banner Görseller
                </Typography>
              </Box>
              <div className="d-flex flex-row">
                <img src={CDN_URL+tevent.smimage} alt="guy" width="400px" height="400px" />
                <img src={CDN_URL+tevent.image} alt="guy" width="400px" height="auto" />
              </div>
            </Stack>

            {/* Commission Part */}
            <Stack display="flex" direction="column" spacing={4}>
              <Box>
                <Typography variant="h6" fontWeight="bold" color="#df0c62">
                  Komisyon Belirle
                </Typography>
              </Box>

              <input
                style={{
                  width: "100%",
                  border: "2px solid #df0c62",
                  padding: "8px",
                  outline: "none",
                }}
                value={commission}
                onChange={(e) => {setComission(e.target.value)}}
              />

              <Stack display="flex" direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={() => {committing(true);}}
                  sx={{
                    backgroundColor: "#df0c62",
                    borderRadius: "0",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                  fullWidth
                  disableElevation
                >
                  Talebi Onayla
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {setDeclining(!declining);}}
                  sx={{
                    backgroundColor: "#df0c62",
                    borderRadius: "0",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                  fullWidth
                  disableElevation
                >
                  Reddet
                </Button>
              </Stack>

              <Stack style={declining ? {height:'220px',overflow:'hidden',transition:'all 0.5s ease'} : {height:0,overflow:'hidden',transition:'all 0.5s ease'}} display="flex" direction="column" spacing={2}>
                <Typography color="#df0c62">Red Sebebi</Typography>
                <TextField
                  error
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  defaultValue="Rejected"
                  sx={{
                    backgroundColor: "white",
                    color: "red",
                  }}
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  onClick={() => {committing(false);}}
                  sx={{
                    backgroundColor: "#df0c62",
                    borderRadius: "0",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                  disableElevation
                >
                  Reddi Onayla
                </Button>
              </Stack>

              {/* End Of Commission Part */}
            </Stack>

            {/* End Of Parent Stack */}
          </Stack>
        </Container>
      ):null}
    </Box>
  );
};

export default RequestsDesktop;
