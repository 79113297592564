import React, { useState, useEffect } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import MobileAppBar from "../../AppBar/Mobile/TopBar/MobileAppBar";
import NavBar from "../../AppBar/Mobile/NavBar/MobileNavBar";
import AutoInput from "../../AutoComplete/AutoInput";
import aidKit from "../../../Assets/Images/aidKit.png";
import smallTicket from "../../../Assets/Images/smallTicket.png";
import logo from "../../../Assets/Images/logo.png";
import City from "../../../Assets/svg/city.svg";
import { ReactComponent as Stage } from "../../../Assets/svg/stage.svg";
import { ReactComponent as Cloud } from "../../../Assets/svg/cloudGroupBlack.svg";
import { SocialIcon } from "react-social-icons";
import rockStar from "../../../Assets/Images/rockStar.png";
import warmCore from "../../../Assets/Images/warmCore.png";
import CarouselDesktop from "../../Carousel/Desktop/CarouselDesktop";
import classes from "./styles.module.css";
import { listEvents, listActives, listFronted } from "../../../Data/event";
import { useNavigate } from "react-router-dom";
import FooterMobile from "../../Panel/Footer/Mobile/FooterMobile";
import { useTranslation } from "react-i18next";

const MobileHome = () => {
  const { t } = useTranslation();
  const dtesArray = [0, 1, 2, 3, 4];
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [allevents, setAllEvents] = useState([]);
  const [detail, setDetail] = useState(null);

  const CDN_URL = process.env.REACT_APP_CDN_URL;

  const fetchEvents = async () => {
    let list = await listEvents();
    setDetail(list[0]);
    setEvents(list);

    setAllEvents(await listFronted());
  };

  useEffect(() => {
    fetchEvents();
  }, []);
  return (
    <Box
      className={classes.container}
      sx={{ display: { xs: "block", sm: "none" } }}
    >
      <MobileAppBar color="#ffd283" shadow={[253, 201, 129, 0.702]} />
      {/* Border */}

      <Box className={classes.featured}>
        <div id={classes.background}></div>
        <Stack
          display="flex"
          direction="column"
          justifyContent="center"
          className={classes.featuredBox}
          spacing={-2}
        >
          <Typography variant="h4" color="white" fontWeight="bold" mb={1}>
            {t("for_fun")}
          </Typography>
          <Typography
            variant="h2"
            className={`jt --debug ${classes.titleStroke}`}
          >
            <span
              className={
                "jt__row " +
                (true || localStorage.getItem("e-ticket-al language") == "RU"
                  ? "letterSFive"
                  : null)
              }
            >
              <span className="jt__text">{t("CONCERT")}</span>
            </span>
            <span
              className={
                "jt__row jt__row--sibling " +
                (true || localStorage.getItem("e-ticket-al language") == "RU"
                  ? "letterSFive"
                  : null)
              }
              aria-hidden="true"
            >
              <span className="jt__text">{t("CONCERT")}</span>
            </span>
            <span
              className={
                "jt__row jt__row--sibling " +
                (true || localStorage.getItem("e-ticket-al language") == "RU"
                  ? "letterSFive"
                  : null)
              }
              aria-hidden="true"
            >
              <span className="jt__text">{t("CONCERT")}</span>
            </span>
            <span
              className={
                "jt__row jt__row--sibling " +
                (true || localStorage.getItem("e-ticket-al language") == "RU"
                  ? "letterSFive"
                  : null)
              }
              aria-hidden="true"
            >
              <span className="jt__text">{t("CONCERT")}</span>
            </span>
          </Typography>
        </Stack>
        <Box className={classes.featuredPosters}>
          {/* <img src={rockStar} alt="rockStar" />
          <img src={warmCore} alt="warmCore" /> */}
          <CarouselDesktop events={allevents} slidesToShow={2} />
        </Box>
      </Box>

      {/* Parent Stack */}
      <Stack display="flex" direction="column" spacing={4}>
        <Container maxWidth="xs">
          {/* Featured Events */}

          {/* Search Country */}
          <Stack display="flex" direction="column">
            <Typography variant="h6" fontWeight="bold" color="#df0c62">
              {t("find_in_your_country")}
            </Typography>
            <div className={classes.searchCountry}>
              <AutoInput />
              <img className={classes.cityImage} src={City} />
            </div>
          </Stack>
        </Container>

        {/* Events */}
        <Box className={classes.events + " mt-4"}>
          <Container maxWidth="xs">
            <Stack display="flex" direction="column" spacing={4}>
              {/* Event  */}
              {events.map((item, index) => (
                <div key={index} style={{ marginBottom: "30px" }}>
                  <Stack
                    onClick={() => {
                      if (item.saleUrl) {
                        window.location.href = item.saleUrl;
                      } else {
                        navigate("event/" + item?.slug);
                      }
                    }}
                    display="flex"
                    direction="column"
                    spacing={2}
                  >
                    <img src={CDN_URL + item.image} alt={item.title} />
                    <Typography
                      variant="h4"
                      color="#df0c62"
                      className={classes.titleStroke}
                    >
                      {item.title}
                    </Typography>

                    <Typography
                      sx={{ whiteSpace: "break-spaces" }}
                      paragraph
                      color="#df0c62"
                    >
                      {item.description.substring(0, 400)}
                    </Typography>
                    <Typography
                      variant="h6"
                      textAlign="end"
                      sx={{
                        textDecoration: "underline",
                      }}
                      color="#df0c62"
                    >
                      {item.location?.name}
                    </Typography>
                    <Box className={classes.redDash}></Box>
                    <Stack
                      display="flex"
                      direction="column"
                      className={classes.locationAndDate}
                    >
                      <Box className={classes.yellowLine}>
                        <Stack
                          display="flex"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          p={"12px 0"}
                        >
                          <Box flex={1}>
                            <Typography color="#df0c62">
                              {item.startDate.split("T")[0] +
                                " " +
                                item.startDate.split("T")[1]}
                            </Typography>
                          </Box>
                          <Box flex={1} className={classes.ticketContainer}>
                            <Typography color="#df0c62" className={classes.buy}>
                              {t("Buy")}
                            </Typography>
                            <img
                              src={smallTicket}
                              alt="smallTicket"
                              className={classes.smallTicket}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Stack>
                </div>
              ))}
            </Stack>
          </Container>
        </Box>
        {/* End Of Events */}
      </Stack>
      <FooterMobile />
      {/* Border */}
      <NavBar />
    </Box>
  );
};

export default MobileHome;
