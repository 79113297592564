import React, { useEffect, useState } from "react";
import { unsubscribe } from "../Data/customer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar from "../components/AppBar/TopBar";

const Unsubscribe = () => {
  const { email } = useParams();
  const { t } = useTranslation();
  const [unsubscribed, setUnsubscribed] = useState(false);

  const onUnsubscribe = async () => {
    await unsubscribe(email);
  };

  useEffect(() => {
    onUnsubscribe();
  }, [email]);

  const onClickUns = async () => {
    await unsubscribe(email);
    setUnsubscribed(true);
  };

  return (
    <div>
      <TopBar color="#f2c981" />
      <div className="container" style={{ marginTop: "70px" }}>
        {!unsubscribed && (
          <>
            <label> Are you sure to unsubscribe from newslatters?</label>
            <div>
              <button
                className="quantityBut btn btn-outline-secondary"
                type="button"
                onClick={onClickUns}
              >
                {t("Yes")}
              </button>
              <button
                className="quantityBut btn btn-outline-secondary"
                type="button"
              >
                {t("No")}
              </button>
            </div>
          </>
        )}
        {unsubscribed && (
          <>
            <label>
              {" "}
              You have unsubscribed from newslatters. You can close the tab.
            </label>
          </>
        )}
      </div>
    </div>
  );
};

export default Unsubscribe;
