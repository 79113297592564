import { dataGet, dataPost } from "../Services/data.js";


export const makeReservation = async (res,resId=0) => {
  let response = false;
  await dataPost("reservation/addReservation",{...res, resId: resId}).then((res) => {
    response = res?.content;
  });
  return response;
};

export const clearReservation = async (resId) => {
  let response = false;
  await dataPost("reservation/deleteReservation",{id: resId}).then((res) => {
    response = res.isSuccess;
  });
  return response;
};

export const getReservation = async (resId) => {
  let response = null;
  await dataPost("reservation/getById",{_id: resId}).then((res) => {
    response = res.content;
  });
  return response;
}

export const getReservationByPlan = async (planId) => {
  let response = [];
  await dataGet("reservation/listByPlan",{plan: planId}).then((res) => {
    response = res?.content || [];
  });
  return response;
}
