import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { Component, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import classes from "../components/Panel/AdminPanel/Sponsor/Add Sponsor/styles.module.css";
import "../Assets/Styles/pdf.css";
import "../Assets/Styles/image.css";
import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import logo from "../Assets/Images/logo.png";
import pdfBack from "../Assets/Images/endTicket.jpg";
import scissors from "../Assets/Images/scissors.svg";
import { getTicket } from "../Data/ticket";
import Image from "../components/pdf/Image";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../Services/systemApi";

const TicketDoc = () => {
  const { t, i18n } = useTranslation();
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [searchParams, setSearchParams] = useSearchParams();
  const [ticket, setTicket] = useState(null);
  const [date, setDate] = useState(null);
  const [price, setPrice] = useState(0);
  const [sponsors, setSponsors] = useState([]);

  const weekdays = [
    t("Sunday"),
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
  ];

  const getLocalDate = (dt) => {
    let date = new Date(dt);
    let weekday = weekdays[date.getDay()];
    return { date: dt?.split("T")[0], week: weekday, time: dt?.split("T")[1] };
  };

  const fetchTicket = async (id) => {
    let tic = await getTicket(id);

    let thisLanguage = tic.language;

    await localStorage.setItem("e-ticket-al language", thisLanguage);
    i18n.changeLanguage(thisLanguage?.toLocaleLowerCase());
    changeLanguage(thisLanguage?.toLocaleLowerCase());

    setDate(getLocalDate(tic.event.startDate));

    let _price = 0;
    if (tic.event.plan.isActive) {
      if (!tic.event.plan.isSimple) {
        _price = tic.event.plan.map.seatTypes.find(
          (it) => it.types == tic.types
        )?.price;
      } else
        _price = tic.event.plan.standartSeatTypes.find(
          (it) => it.types == tic.types
        )?.price;
    }

    setPrice(_price);

    const resp = await getTicket(id);
    const _sponsors = resp.event.sponsors;
    setTicket(resp);
    setSponsors(_sponsors?.split(","));
  };

  useEffect(() => {
    let tid = searchParams.get("tid");
    fetchTicket(tid);
  }, []);

  const RepeatedRow = ({ left, right }) => (
    <Stack display="flex" direction="row">
      <Box sx={{ height: "15px" }} flex={1}>
        <Typography fontWeight="bold" fontSize={10}>
          {left}
        </Typography>
      </Box>
      <Box flex={2}>
        <Typography fontWeight="bold" fontSize={10}>
          {right}
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <div className="ticketPage">
      <Box paddingBottom={5}>
        {ticket ? (
          <Container maxWidth="md">
            <Stack
              display="flex"
              direction="column"
              spacing={2}
              className="innerContainer"
            >
              <Stack
                display="flex"
                direction="column"
                className="ticket"
                spacing={6}
                sx={{ position: "relative" }}
              >
                <img
                  style={{ position: "absolute", width: "100%" }}
                  src={pdfBack}
                />
                <Stack
                  sx={{
                    position: "relative",
                    paddingLeft: "20px",
                    marginTop: "20px!Important;",
                  }}
                  display="flex"
                  direction="row"
                  spacing={1}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "50px" }}
                  >
                    <img src={logo} alt="logo" className="logo" />
                  </Box>
                  <Box flex={3} className="ticketDetails">
                    <Stack display="flex" direction="column" spacing={1}>
                      <Typography variant="h5" fontSize={20} fontWeight="bold">
                        {ticket?.event.title}
                      </Typography>
                      <Typography fontSize={15} fontWeight="bold">
                        {ticket?.event.location?.name}
                      </Typography>
                      <Stack display="flex" direction="column">
                        <RepeatedRow
                          left={t("ticket_date")}
                          right={date?.date}
                        />
                        <RepeatedRow
                          left={t("ticket_day")}
                          right={date?.week}
                        />
                        <RepeatedRow
                          left={t("ticket_time")}
                          right={date?.time}
                        />
                      </Stack>
                      {ticket?.event.plan.isSimple ? (
                        <Stack display="flex" direction="column">
                          <RepeatedRow
                            left={t("ticket_block")}
                            right={ticket?.label}
                          />
                          <RepeatedRow left={""} right={""} />
                          <RepeatedRow left={""} right={""} />
                        </Stack>
                      ) : (
                        <Stack display="flex" direction="column">
                          <RepeatedRow
                            left={t("ticket_block")}
                            right={
                              ticket
                                ? ticket.event.plan.startsFromOne
                                  ? ticket.trueLabel
                                  : ticket.label
                                : ""
                            }
                          />
                          <RepeatedRow
                            left={t("ticket_row")}
                            right={ticket.row}
                          />
                          <RepeatedRow
                            left={t("ticket_seat")}
                            right={
                              ticket
                                ? ticket.event.plan.startsFromOne
                                  ? ticket.trueSeat
                                  : ticket.seat
                                : ""
                            }
                          />
                        </Stack>
                      )}
                      {ticket ? (
                        <RepeatedRow
                          left={t("ticket_promoter")}
                          right={
                            ticket.promoter
                              ? ticket.promoter.businessName
                              : "Güven Organizasyon (Güven Keser)"
                          }
                        />
                      ) : null}

                      <Stack
                        display="flex"
                        direction="row"
                        justifyContent="space-evenly"
                        sx={{
                          borderRadius: "50px",
                          border: "2px solid #df0c62",
                          padding: "2.5%",
                        }}
                      >
                        <Typography fontWeight="bold" fontSize={10}>
                          {/* {`${t("ticket_price")} ${price} ${ */}
                          {`${t("ticket_price")} ${ticket.price} ${
                            ticket?.event.currency.symbol
                          }`}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>

                  <Box
                    flex={0.5}
                    className="qrticket"
                    display="flex"
                    justifyContent="center"
                  >
                    <QRCode value={BASE_URL + "/scanner?tid=" + ticket?._id} />
                    {/*  <Barcode
                              value={ticket?._id}
                              marginBottom={0}
                              background="transparent"
                          />*/}
                  </Box>
                </Stack>
              </Stack>
              <div>
                <img src={scissors} />
              </div>
              <div style={{ height: "100%" }}>
                {sponsors?.length === 1 ? (
                  <div className="row m-auto">
                    <div className=" col-12 mb-3 squaredSponsors">
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={CDN_URL + sponsors[0]}
                      />
                    </div>
                  </div>
                ) : sponsors?.length === 2 ? (
                  <div className="row m-auto">
                    {sponsors.map((sponsor, i) => (
                      <div className="col-12 mb-3 squaredSponsors" key={i}>
                        <img
                          style={{ width: "100%", height: "auto" }}
                          src={CDN_URL + sponsor}
                        />
                      </div>
                    ))}
                  </div>
                ) : sponsors?.length === 3 ? (
                  <div className="row m-auto">
                    <div className="col-6 mb-3 squaredSponsors">
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={CDN_URL + sponsors[0]}
                      />
                    </div>
                    <div className="col-6 mb-3 squaredSponsors">
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={CDN_URL + sponsors[1]}
                      />
                    </div>
                    <div className="col-12 mb-3 squaredSponsors">
                      <img
                        style={{ width: "100%", height: "auto" }}
                        src={CDN_URL + sponsors[2]}
                      />
                    </div>
                  </div>
                ) : sponsors?.length === 4 ? (
                  <div className="row m-auto">
                    {sponsors.map((sponsor, i) => (
                      <div className="col-6 mb-3 squaredSponsors" key={i}>
                        <img
                          style={{ width: "100%", height: "auto" }}
                          src={CDN_URL + sponsor}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </Stack>
          </Container>
        ) : null}
      </Box>
    </div>
  );
};

export default TicketDoc;
