import { dataGet, dataPost } from "../Services/data.js";

export const loginCustomer = async (params) => {
    let customer = null;
    await dataPost('customer/login', { ...params }).then((response) => {
        console.log(response);
        customer = response?.content;
    });
    return customer;
}

export const uniLogin = async (params) => {
    let user = null;
    await dataPost('customer/uniLogin', { ...params }).then((response) => {
        user = response?.content;
    });
    return user;
}

export const registerCustomer = async (params) => {
    let customer = null;
    await dataPost('customer/register', { ...params }).then((response) => {
        customer = response?.content;
    });
    return customer;
}

export const forgotCustomer = async (email) => {
    let status = false;
    await dataPost('customer/forgot', { email: email }).then(
        response => status = !!(response && response.isSuccess)
    );
    return status;
}

export const resetCustomer = async (load) => {
    let status = false;
    await dataPost('customer/reset', { ...load }).then(
        (response) => status = !!(response && response.isSuccess)
    );
    return status;
}

export const listAll = async () => {
    let customers = [];
    await dataGet('customer/listAll').then((response) => {
        if (response && response.isSuccess) {
            customers = response.content;
        }
    });
    return customers;
}


export const getCustomerByEmail = async (email) => {
    let customer = {};
    await dataPost('customer/getByEmail', { email: email }).then((response) => {
        if (response && response.isSuccess) customer = response.content;
    });
    return customer;
}

export const getProfile = async () => {
    let customer = {};
    await dataGet('customer/getProfile').then((_response) => {
        if (_response && _response.isSuccess)
            customer = _response.content;
    });
    return customer;
}

export const tryCustomer = async () => {
    let customer = false;
    await dataGet('customer/tryCustomer').then((_response) => {
        if (_response && _response.isSuccess)
            customer = true;
    });
    return customer;
}

export const updateProfile = async (profile) => {
    let customer = false;
    await dataPost('customer/updateProfile', { ...profile }).then((_response) => {
        if (_response && _response.isSuccess)
            customer = _response.isSuccess;
    });
    return customer;
}

export const changePassword = async (password) => {
    let customer = false;
    await dataPost('customer/changePassword', { password: password }).then((_response) => {
        if (_response && _response.isSuccess)
            customer = _response.isSuccess;
    });
    return customer;
}

export const getCustomerById = async (id) => {
    let customer = {};
    await dataPost('customer/getById', { id: id }).then((response) => {
        if (response && response.isSuccess)
            customer = response.content;
    });
    return customer;
}


export const listNotifications = async () => {
    let notifications = [];
    await dataGet('customer/listNotifiations').then((response) => {
        if (response && response.isSuccess)
            notifications = response.content;
    });
    return notifications;
}

export const checkNotifications = async () => {
    let notifications = [];
    await dataGet('customer/checkNotifications').then((response) => {
        if (response && response.success)
            notifications = response.content;

    });
    return notifications;
}

export const listTickets = async () => {
    let tickets = [];
    await dataGet('customer/listTickets').then((response) => {
        if (response && response.isSuccess) {
            tickets = response.content;
            console.log(tickets);
        }
    });
    return tickets;
}

export const listFavorites = async () => {
    let favorites = [];
    await dataGet('customer/listFavorites').then((response) => {
        if (response && response.isSuccess) {
            favorites = response.content;
        }
    });
    return favorites;
}

export const fastFavorites = async () => {
    let favorites = "";
    await dataGet('customer/fastFavorites').then((response) => {
        if (response && response.isSuccess) favorites = response.content;
    });
    return favorites;
}

export const countFavorites = async () => {
    let favorites = [];
    let lis = [];
    let st = "";
    await dataGet('customer/countFavorites').then((response) => {
        if (response && response.isSuccess) {
            if (response.content) {
                if (response.content.length) {
                    if (response.content.length > 1) {
                        response.content.forEach((item, i) => {
                            lis.push(item);
                        });
                        st = lis.join(",");
                    } else st = response.content[0];
                } else st = null;
            } else st = null;

            if (st) localStorage.setItem("favs", st);
            else localStorage.removeItem("favs");

            favorites = response.content.length;
        }
    });
    return favorites;
}

export const checkFavorite = async (id) => {
    let success = false;
    let favorites = [];
    let lis = [];
    let st = "";
    await dataPost('customer/checkFavorite', { id: id }).then(async (response) => {
        if (response && response.isSuccess) {

            await dataGet('customer/countFavorites').then(async (_response) => {

                if (_response && _response.isSuccess) {
                    if (_response.content) {
                        if (_response.content.length) {
                            if (_response.content.length > 1) {
                                _response.content.forEach((item, i) => {
                                    lis.push(item);
                                });
                                st = lis.join(",");
                            } else st = _response.content[0];
                        } else st = null;
                    } else st = null;

                    if (st) await localStorage.setItem("favs", st);
                    else await localStorage.removeItem("favs");

                    favorites = _response.content.length;
                }
            });

            success = true;
        } else success = false;
    });
    return success;

}

export const editProfile = async (body) => {

}

export const deleteCustomer = async (id) => {
    let customer = false;
    await dataPost('customer/deleteCustomer', { id: id }).then((response) => {
        if (response && response.isSuccess)
            customer = response.isSuccess;
    });
    return customer;
}

export const unsubscribe = async (email) => {
    let customer = false;
    await dataPost('customer/unpermit', { email: email }).then((response) => {
        if (response && response.isSuccess)
            customer = response.isSuccess;
    });
    return customer;
}
