import { Search } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import { slideInRight } from "animate.css";
const SearchBar = ({ query, changeQuery, onClickSearch }) => {
  const [searchInput, setSearchInput] = useState("none");
  const inputRef = useRef(null);
  return (
    <React.Fragment>
      {/* Search Bar */}
      <Stack
        display="flex"
        direction="row"
        sx={{ backgroundColor: "#ffd283" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box paddingLeft="16px">
          <Typography fontWeight="bold" color="#df0c62" textAlign="center">
            ARA
          </Typography>
        </Box>
        <Box
          flex={2}
          display="flex"
          justifyContent="end"
          sx={{ overflow: "hidden" }}
        >
          <Box width="50%" overflow="hidden" display={`${searchInput}`}>
            <input
              value={query}
              ref={inputRef}
              id="searchInput"
              onChange={(e) => {
                changeQuery(e.target.value);
              }}
              className="animate__animated animate__slideInRight"
              style={{
                border: "none",
                backgroundColor: "#df0c62",
                color: "white",
                fontWeight: "bold",
                fontSize: "1em",
                outline: "none",
                width: "100%",
                height: "100%",
                padding: "0 16px",
              }}
            />
          </Box>
          <Button
            id="SearchButton"
            variant="contained"
            sx={{
              backgroundColor: "#feb659",
              boxShadow: "-5px 0px 5px 1px #df0c62",
              "&:hover": {
                backgroundColor: "#feb659",
                boxShadow: "-10px 0px 5px 1px #df0c62",
              },
            }}
            onClick={() => {
              if (searchInput == "none") {
                setSearchInput("block");
                setTimeout(() => {
                  inputRef.current.focus();
                }, 1000);
              } else {
                onClickSearch();
              }
            }}
          >
            <Search sx={{ color: "white" }} />
          </Button>
        </Box>
        {/* End Of Search Bar Stack */}
      </Stack>
    </React.Fragment>
  );
};

export default SearchBar;
