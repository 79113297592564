import { Box, Button, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import classes from "./styles.module.css";
const Register = () => {
  return (
    <Box sx={{ height: "100vh", width: "100vw", backgroundColor: "#df0c62" }}>
      <Stack
        display="flex"
        direction="column"
        justifyContent="center"
        sx={{ height: "100%", width: "100%", padding: "16px" }}
        spacing={2}
      >
        <Typography variant="h6" color="white">
          Hesap Oluştur
        </Typography>
        <Stack
          display="flex"
          direction="row"
          sx={{ width: "100%" }}
          spacing={2}
        >
          <Box sx={{ width: "50%" }}>
            <input
              className={classes.isim}
              placeholder="İsim"
              style={{ width: "100%" }}
            />
          </Box>
          <Box sx={{ width: "50%" }}>
            <input
              className={classes.soyIsim}
              placeholder="Soy İşim"
              style={{ width: "100%" }}
            />
          </Box>
        </Stack>
        <input
          className={classes.ePosta}
          placeholder="E-Posta"
          style={{ width: "100%" }}
        />
        <input
          className={classes.şifre}
          placeholder="Şire"
          style={{ width: "100%" }}
          type="password"
        />

        <Stack display="flex" direction="row">
          <Checkbox style={{ color: "#fff0d7", backgroundColor: "#df0c62" }} />
          <Box>
            <p style={{ color: "white" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor.
            </p>
          </Box>
        </Stack>
        <Button
          variant="contained"
          style={{
            borderRadius: "0",
            backgroundColor: "#feb659",
            border: "none",
            padding: "16px",
            color: "white",
            fontSize: "16px",
            width: "100%",
          }}
        >
          Hesap Oluştur
        </Button>
      </Stack>
    </Box>
  );
};

export default Register;
