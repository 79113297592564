import { dataGet, dataPost } from "../Services/data.js";

export const make = async (order) => {
  let response = null;
  await dataPost('order/make', { order: order }).then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const calculate = async (cart) => {
  let response = null;
  await dataPost('order/calculate', { cart: cart }).then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const onResend = async (id, email) => {
  let response = false;
  await dataPost('order/resendMail', { id, email }).then((_response) => {
    response = _response?.isSuccess;
  });
  return response;
}


export const confirm = async (id) => {
  let response = null;
  await dataPost('order/confirm', { id }).then((_response) => {
    response = _response?.content;
  });
  return response;
}



export const completeTransfer = async (id) => {
  let response = null;
  await dataPost('order/completeTransfer', { id: id }).then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const fetchTickets = async (id, secret) => {
  let response = null;
  await dataPost('order/fetchTickets', { id: id, secret }).then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const listCustomerOrders = async (id) => {
  let response = null;
  await dataGet('order/listCustomerOrders').then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const ticketCancel = async (id) => {
  let response = null;
  await dataPost('order/ticketCancel', { _id: id }).then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const orderCancel = async (body) => {
  let response = null;
  await dataPost('order/orderCancel', body).then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const allOrderCancel = async (body) => {
  let response = null;
  await dataPost('order/allOrderCancel', body).then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const listAll = async () => {
  let response = null;
  await dataGet('order/listAll').then((_response) => {
    response = _response?.content;
  });
  return response;
}

export const listLatest = async (query = "") => {
  let response = null;
  let params = {};
  if (query !== "" && query !== " ") params = { query };
  await dataPost('order/listLatest', params).then((_response) => {
    response = _response?.content;
  });
  return response;
}
