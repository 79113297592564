import { Close, Menu } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import { SocialIcon } from "react-social-icons";
import logo from "../../Assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import style from "./styles.module.css";
import { useTranslation } from "react-i18next";
import cookies from "react-cookies";
const Header = () => {
  const [open, setOpen] = useState(false);
  const cookiesConfig = {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE, path: process.env.REACT_APP_BASE_URL,
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  const customStyle = {
    height: "100vh",
    backgroundColor: "rgba(255,210,131,0.9)",
  };

  const clearCustomerData = async (customer) => {
    await cookies.remove("customerFirstName", customer.firstName, cookiesConfig);
    await cookies.remove("customerLastName", customer.lastName, cookiesConfig);
    await cookies.remove("customerEmail", customer.email, cookiesConfig);
    await cookies.remove("customerPhoneNumber", customer.phoneNumber, cookiesConfig);
    await cookies.remove("customerAddress", customer.address, cookiesConfig);
    await localStorage.removeItem("local", customer.token);
  };

  const logout = async () => {
    await clearCustomerData().then(() => {
      navigate('/');
    });
  }

  return (
    <div>
      <Menu
        sx={{ color: "white" }}
        fontSize="large"
        onClick={() => setOpen(!open)}
      />
      <SwipeableDrawer
        anchor="top"
        open={open}
        PaperProps={{ sx: { ...customStyle } }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Box>
          <IconButton size="large" onClick={() => setOpen(!open)}>
            <Close fontSize="large" sx={{ color: "black" }} />
          </IconButton>
        </Box>
        <Stack
          display="flex"
          direction="column"
          height="100vh"
          width="100vw"
          alignItems="center"
          justifyContent="center"
          spacing={12}
        >
          {/* Logo */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} alt="logo" width={"75%"} />
          </Box>
          {/* Pages */}
          <Stack
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Typography
              variant="h4"
              color="white"
              fontWeight={900}
              className={style.textStyle}
              onClick={() => { navigate("/"); setOpen(!open) }}
            >
            {t("HOME")}
            </Typography>

            <Typography
              variant="h4"
              color="white"
              fontWeight={900}
              className={style.textStyle}
              onClick={() => { navigate("/events"); setOpen(!open) }}
            >
              {t("EVENTS")}
            </Typography>

            <Typography
              variant="h4"
              color="white"
              fontWeight={900}
              className={style.textStyle}
              onClick={() => { setOpen(!open) }}
            >
                {t("CLOSE")}
            </Typography>
          </Stack>
          {/* Social icons */}
          <Zoom in={open} timeout={1000}>
            <Stack display="flex" direction="row">
              <IconButton>
                <SocialIcon url="https://facebook.com/eticketal" network="facebook" bgColor="black" />
              </IconButton>
              <IconButton>
                <SocialIcon url="https://twitter.com/eticketal" network="twitter" bgColor="black" />
              </IconButton>
              <IconButton>
                <SocialIcon url="https://instagram.com/eticketal" network="instagram" bgColor="black" />
              </IconButton>
              <IconButton>
                <SocialIcon url="https://youtube.com/eticketal" network="youtube" bgColor="black" />
              </IconButton>
            </Stack>
          </Zoom>
        </Stack>
      </SwipeableDrawer>
    </div>
  );
};

export default Header;
