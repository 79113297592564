import {
  ConfirmationNumber,
  Home,
  Person,
  Public,
  Search,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {toast} from 'react-toastify';
import { changeLanguage } from "../../../../Services/systemApi";
import DrawButton from "../../../DrawerButton/DrawerButton";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { getReservation,clearReservation } from "../../../../Data/reservation";


const MobileNavBar = () => {

  const [language, setLanguage] = useState(localStorage.getItem('e-ticket-al language'));
  const [currency, setCurrency] = useState(localStorage.getItem('e-ticket-al currency'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl2);

  const [isLogged, setIsLogged] = useState(localStorage.getItem("local"));

  const { t, i18n } = useTranslation();

  const [cartLength, setCartLength] = useState(Number(0))

  const onChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    changeLanguage(language);
  };

  const loginControl = () => {
    if (localStorage.getItem("local")) setIsLogged(true);
  };

  const settingLocale = async (type, value) => {
    if (type == 'lang') {
      await localStorage.setItem("e-ticket-al language", value);
      await onChangeLanguage(language?.toLocaleLowerCase());
    } else {
      await localStorage.setItem("e-ticket-al currency", value);
    }
  }

  // const clearCart = async () => {
  //   await clearReservation(await localStorage.getItem('resId'));
  //   await localStorage.removeItem('resExTime');
  //   await localStorage.removeItem('resId');
  //   await localStorage.removeItem('cart');
  //   window.dispatchEvent(new Event("storage"));
  //   toast.warning(t('reservation_expired'));
  //   if(window.location.pathname == '/buyTicket') navigate('/');
  // }
  //
  // const reservationControl = async() => {
  //   let expire = await localStorage.getItem('resExTime');
  //
  //   console.log('mobile res control');
  //   if(expire){
  //     setTimeout(() => {
  //       reservationControl();
  //     },3000);
  //
  //     let current = new Date().getTime();
  //     if(current > expire) clearCart();
  //   }
  // }

  const cartControl = async() => {
     // let _res = await localStorage.getItem('resId');
     // if(_res) await getReservation(_res).then(async(r) => {
     //  if(r){
     //    let thisdate = new Date();
     //    let reservationdate = new Date(r.created);
     //
     //    let thistime = thisdate.getTime();
     //    let reservationtime = reservationdate.getTime();
     //    let expiretime = reservationtime + (1000 * 60 * 5);
     //    let expireDate = new Date(expiretime);
     //    if(expiretime > thistime){ // VALID RES
     //
     //
     //
     //      await localStorage.setItem('resExTime',expiretime);
     //      reservationControl();

          let _cart = await localStorage.getItem('cart');

          if (_cart) {
            let cart = JSON.parse(_cart);
            if (cart?.length) {
              setCartLength(cart.length);
            }else setCartLength(0);
          }else{
            setCartLength(0);
          }
    //     }else{ //Expired Res
    //       clearReservation(_res);
    //       await localStorage.removeItem('resExTime');
    //       await localStorage.removeItem('resId');
    //       await localStorage.removeItem('cart');
    //       window.dispatchEvent(new Event("storage"));
    //       if(window.location.pathname == '/buyTicket') navigate('/');
    //     }
    //
    //   }else{ //No Res
    //     await localStorage.removeItem('resExTime');
    //     await localStorage.removeItem('resId');
    //     await localStorage.removeItem('cart');
    //     window.dispatchEvent(new Event("storage"));
    //     if(window.location.pathname == '/buyTicket') navigate('/');
    //   }
    //
    // });
    //

  };

  useEffect(() => {
    loginControl();
    cartControl();
    window.addEventListener('cart', cartControl())
    return () => window.removeEventListener('cart',cartControl());
  }, [])

  const changeLocale = async (type, value) => {
    await settingLocale(type, value).then(() => {
      setTimeout(() => { window.location.reload(); }, 0);
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };



  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "0%",
        left: "0%",
        width: "100%",
        height: "60px",
        backgroundColor: "#df0c62",
        display: { xs: "flex", md: "none" },
        zIndex: "2",
      }}
    >
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        spacing={4}
        alignItems="center"
        sx={{ padding: "0 24px 0 24px", width: "100vw" }}
      >
        <IconButton size="small">
          <DrawButton />
        </IconButton>

        {/* Select Language And Currency */}
        <div>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls={openMenu ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClick}
          >
            <Badge badgeContent={language} color="warning">
              <Public sx={{ color: "white" }} fontSize="large" />
            </Badge>
          </IconButton>



          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              style: { backgroundColor: "#fff0d7" },
            }}
          >
            <MenuItem
              onClick={handleClose}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#ffd283" },
                borderBottom: "2px solid #df0c62",
              }}
            >
              <Stack
                display="flex"
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Public sx={{ color: "#df0c62" }} />

                <Typography variant="h6" fontWeight="bold" color="#df0c62">
                  {language}
                </Typography>
              </Stack>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose();
                changeLocale("lang", "TR");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                TR
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                changeLocale("lang", "EN");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                EN
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                changeLocale("lang", "DE");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                DE
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                changeLocale("lang", "RU");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                RU
              </Typography>
            </MenuItem>
          </Menu>
        </div>


        <div style={{ display: 'none' }}>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            aria-controls={openMenu2 ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu2 ? "true" : undefined}
            onClick={handleClick2}
          >
            <Badge badgeContent={currency} color="warning">
              <CurrencyExchangeIcon sx={{ color: "white" }} fontSize="large" />
            </Badge>
          </IconButton>



          {/*<Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl2}
            open={openMenu2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              style: { backgroundColor: "#fff0d7" },
            }}
          >
            <MenuItem
              onClick={handleClose2}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#ffd283" },
                borderBottom: "2px solid #df0c62",
              }}
            >
              <Stack
                display="flex"
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Public sx={{ color: "#df0c62" }} />

                <Typography variant="h6" fontWeight="bold" color="#df0c62">
                  {currency}
                </Typography>
              </Stack>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleClose2();
                changeLocale("curr","₺");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                ₺
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose2();
                changeLocale("curr","$");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                $
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose2();
                changeLocale("curr","C$");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                C$
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose2();
                changeLocale("curr","€");
              }}
              sx={{
                backgroundColor: "#fff0d7",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              <Typography textAlign="center" color="#feb659">
                €
              </Typography>
            </MenuItem>
          </Menu>*/}
        </div>



        {/* Cart */}
        <IconButton size="small" onClick={() => navigate("/buyTicket")}>
        {cartLength ? (
          <Badge badgeContent={Number(cartLength)} color="warning">
            <ConfirmationNumber fontSize="large" sx={{ color: "white" }} />
          </Badge>
        ):(
          <ConfirmationNumber fontSize="large" sx={{ color: "white" }} />
        )}
        </IconButton>
        {/* Profile */}
        <IconButton size="small" onClick={() => isLogged ? navigate("/profile") : navigate('/login')}>
          <Badge badgeContent={0} color="warning">
            <Person fontSize="large" sx={{ color: "white" }} />
          </Badge>
        </IconButton>
      </Stack>
    </Box>
  );
};

export default MobileNavBar;
