import { Close, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import NavBar from "../../../NavBar/NavBar";
import seats from "../../../../../Assets/Images/seats.png";
import classes from "./styles.module.css";
const Desktop = () => {
  const csAcc = () => {
    return (
      <React.Fragment>
        <Accordion
          sx={{
            backgroundColor: "#fff0d7",
            borderBottom: "2px solid #df0c62",
          }}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack display="flex" direction="row" spacing={2}>
              <input
                style={{
                  outline: "none",
                  border: "1px solid #df0c62",
                  width: "15%",
                }}
              />
              <Typography color="#df0c62">TL</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  };

  return (
    <Box sx={{ backgroundColor: "#fff0d7" }}>
      <NavBar color="#ffd283" />
      <Container maxWidht="lg" sx={{ paddingTop: "5%", paddingBottom: "2.5%" }}>
        {/* Parent Stack */}
        <Stack display="flex" direction="column" spacing={4}>
          <Box display="flex" justifyContent="end">
            <IconButton>
              <Close sx={{ color: "#df0c62" }} fontSize="large" />
            </IconButton>
          </Box>
          <Typography variant="h5" fontWeight="bold" color="#df0c62">
            OTURMA DÜZENİ
          </Typography>
          <Stack
            display="flex"
            direction="row"
            sx={{ marginTop: "5%" }}
            spacing={1}
          >
            {/* Left Seat Details Side */}
            <Box flex={1}>
              <Stack display="flex" direction="column">
                <Accordion
                  sx={{
                    backgroundColor: "#fff0d7",
                    borderBottom: "2px solid #df0c62",
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography color="#df0c62">Var</Typography>
                  </AccordionSummary>
                  <AccordionDetails>{csAcc()}</AccordionDetails>
                </Accordion>
              </Stack>
            </Box>
            {/* Right Image Seat Side */}
            <Box flex={1}>
              <img src={seats} alt="seats" className={classes.seats} />
            </Box>
          </Stack>
          {/* End of Parent Stack */}
          <Box sx={{ width: "100%", borderBottom: "2px solid #df0c62" }}></Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              sx={{
                width: "50%",
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
              }}
            >
              Düzeni Onayla
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Desktop;
