export const eventCategories = [
    {
        name: "Konser",
        subCategories: [
            "Pop",
            "Rock",
            "Caz",
            "Klasik Müzik",
            "Alternatif",
            "Metal",
            "Halk Müziği",
            "Elektronik",
            "Hip-Hop/Rap",
            "Dünya Müziği"
        ]
    },
    {
        name: "Tiyatro",
        subCategories: [
            "Drama",
            "Komedi",
            "Müzikal",
            "Çocuk Tiyatrosu",
            "Deneysel",
            "Tek Kişilik Oyun",
            "Kukla Tiyatrosu",
            "Opera",
            "Pandomim",
            "Dans Tiyatrosu"
        ]
    }
];

