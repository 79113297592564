import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import {useNavigate} from 'react-router-dom';
import { SocialIcon } from "react-social-icons";
import logo from "../../../Assets/Images/logo.png";
import DrawButton from "../../DrawerButton/DrawerButton";
import stage from "../../../Assets/svg/stage.svg";
import cloud from "../../../Assets/svg/cloudGroupBlack.svg";
import { ReactComponent as EndTicket } from "../../../Assets/svg/endTicket.svg";
import classes from "./styles.module.css";
import { ConfirmationNumber, Person, Search } from "@mui/icons-material";
import MobileAppBar from "../../AppBar/Mobile/TopBar/MobileAppBar";
import NavBar from "../../AppBar/Mobile/NavBar/MobileNavBar";
import { useTranslation } from "react-i18next";
const MobileCongratulations = ({orderId,logged}) => {
   const { t } = useTranslation()
  const navigate = useNavigate();
  return (
    <Box
      sx={{ display: { xs: "block", sm: "none" } }}
      className={classes.container}
    >
      <MobileAppBar color="#ffd283" shadow={[253, 201, 129, 0.702]} />

      <Container maxWidth="sm">
        {/* Empty Box */}
        <Box sx={{ width: "100%", height: "8rem" }}></Box>
        {/* Body */}
        <Stack display="flex" direction="column" spacing={1}>
          {/* Congrats Ticket */}

          <Box className={classes.celebTicket}>
            <Stack
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              sx={{ padding: "1% 23%" }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                textAlign="center"
                color="#df0c62"
                sx={{ textDecoration: "underline" }}
              >
                {t('congrats')}
              </Typography>

              <Typography color="#df0c62" fontSize={10} textAlign="center">
            {orderId?.substring(orderId.length - 8, orderId.length)?.toUpperCase()} {t('order_complete_not_verified')}
              </Typography>

              <img src={logo} alt="logo" width="50%" />
            </Stack>
          </Box>

          <Stack display="flex" direction="row" spacing={1}>
          {logged ? (
            <Box flex={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => { navigate('/profile?tickets=show')}}
                sx={{
                  backgroundColor: "#df0c62",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
                 {t('go_to_tickets')}
              </Button>
            </Box>
          ):null}

            <Box flex={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => { navigate('/evets')}}
                sx={{
                  backgroundColor: "#df0c62",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
                {t('see_other_events')}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Container>
      {/* Empty Box */}
      <Box sx={{ width: "100%", height: "4rem" }}></Box>
      {/* Last Part */}
      <Stack
        className={`${classes.fourthPart}`}
        display="flex"
        direction="column"
        alignItems="center"
      >
        {/* Yellow background */}
        <Stack
          className={`${classes.yellowBackground}`}
          display="flex"
          direction="column"
          spacing={2}
        >
          <Box sx={{ borderBottom: "2px solid #fecd8b" }}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#df0c62", paddingTop: "16px" }}
              textAlign="center"
            >
              BİLGİİ
            </Typography>
          </Box>

          <Box sx={{ width: "100%", color: "#df0c62", padding: "0 100px" }}>
            <Typography variant="h6" textAlign="center">
              BAŞVURU FORMU HAKKIMIZDA BİZE ULAŞIN İŞLEM REHBERİ
            </Typography>
          </Box>

          <Box sx={{ borderBottom: "2px solid #fecd8b" }}>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#df0c62" }}
              textAlign="center"
            >
              HUKUKİ METİNLER
            </Typography>
          </Box>
          <Box className={`${classes.bottomLogo}`}>
            <img src={logo} alt="logo" style={{ width: "55%" }} />
          </Box>

          {/* Social Media Icons */}
          <Stack
            display="flex"
            direction="row"
            justifyContent="center"
            spacing={1}
          >
            <SocialIcon network="facebook" bgColor="black" />
            <SocialIcon network="twitter" bgColor="black" />
            <SocialIcon network="instagram" bgColor="black" />
            <SocialIcon network="youtube" bgColor="black" />
          </Stack>
          <Box className={`${classes.emptyBox}`}>
            <img className={`${classes.stage}`} src={stage} alt="stage" />
            <img className={`${classes.cloud}`} src={cloud} alt="cloud" />
          </Box>
        </Stack>
      </Stack>
      {/* Bottom NavBar */}
      <Box height="17vh"></Box>
      <NavBar />
    </Box>
  );
};

export default MobileCongratulations;
