import { GETALLCOUNTRIES } from "../Actions/types";

const countries = (state = null, action) => {
  if (action.type === GETALLCOUNTRIES) {
    return (state = action.payload);
  } else {
    return state;
  }
};

export default countries;
