import { Navigate } from "react-router-dom";
const RequireAdmin = ({ isAdmin, children }) => {
    if (!isAdmin) {
        // User is not an admin, redirect to home page
        return <Navigate to="/" replace />;
    }
    // User is an admin, render the requested component
    return children;
};

export default RequireAdmin;