import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Footer from "../../../Footer/Footer";
import NavBar from "../../../NavBar/NavBar";
import SearchBar from "../../../SearchBar/SearchBar";
import guy from "../../../../../Assets/Images/guy.jpg";
import classes from "./styles.module.css";
const Desktop = () => {
  const RepeatedRows = ({ title, detail }) => {
    return (
      <Stack
        display="flex"
        direction="column"
        sx={{ borderBottom: "2px solid #feb659" }}
      >
        <Typography variant="h6" fontWeight="bold" color="#df0c62">
          {title}
        </Typography>
        <Box sx={{ padding: "8px 16px" }}>
          <Typography color="#df0c62" fontWeight="bold">
            {detail}
          </Typography>
        </Box>
      </Stack>
    );
  };
  return (
    <Box>
      <NavBar color="#ffd283" />
      <Container maxWidth="lg">
        {/* Parent Stack */}
        <Stack
          display="flex"
          direction="column"
          sx={{ paddingTop: "5%" }}
          spacing={4}
        >
          <Typography variant="h4" className={classes.titleStroke}>
            ORGANİZATÖR PANELİ
          </Typography>
          {/* Search Bar */}
          <SearchBar />
          {/* Events Title & Poster */}
          <Stack
            display="flex"
            direction="row"
            sx={{ backgroundColor: "#fffed7" }}
          >
            <Box
              flex={1}
              // className={classes.posterContainer}
              sx={{
                height: { xs: "auto", sm: "707px" },
                width: { xs: "auto", sm: "466px" },
              }}
            >
              <img src={guy} alt="guy" className={classes.eventImage} />
            </Box>
            <Box
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h3"
                fontWeight="bold"
                color="#df0c62"
                textAlign="center"
                sx={{ fontSize: { xs: "1.5rem", sm: "4rem" } }}
              >
                MAESTRO
              </Typography>
            </Box>
          </Stack>
          {/* Parent Event detail Stack */}
          <Stack display="flex" direction="column">
            <Stack display="flex" direction="row" spacing={2}>
              {/* Left Side */}
              <Box flex={1}>
                <Stack display="flex" direction="column" spacing={1}>
                  <RepeatedRows title=" Etkinlik Adı" detail=" Maestro" />
                  <RepeatedRows
                    title="Etkinlik Şehri"
                    detail="İstanbul / Türkiye"
                  />
                  <RepeatedRows title="Etkinlik Türü" detail=" Konser" />
                </Stack>
              </Box>
              {/* Right Side */}
              <Box flex={1}>
                <Stack display="flex" direction="column" spacing={1}>
                  <RepeatedRows
                    title="Etkinlik Tarihi"
                    detail="23.11.2022 / 22:30"
                  />
                  <RepeatedRows
                    title="Etkinlik Sahnesi"
                    detail="Küçünçiftlik Park"
                  />
                  <RepeatedRows title="Katılımcı Sayısı" detail="1200" />
                </Stack>
              </Box>
            </Stack>
            <RepeatedRows title="Oturma Düzeni" detail="Yok" />
            {/* End of Event Detail Stack */}
          </Stack>
          {/* End Of Parent Stack */}
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
};

export default Desktop;
