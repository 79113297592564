import React,{useState,useEffect} from "react";
import {useSearchParams,useNavigate} from "react-router-dom";
import {confirm} from "../Data/order";
import {clearReservation} from "../Data/reservation";
import DesktopCongratulations from "../components/Congratulations/Desktop/DesktopCongratulations";
import MobileCongratulations from "../components/Congratulations/Mobile/MobileCongratulations";
import TabletCongratulations from "../components/Congratulations/Tablet/TabletCongratulations";

const Congratulations = () => {
  const [logged,isLogged] = useState(localStorage.getItem('local'));
  const [counter,isCounter] = useState(localStorage.getItem('counter'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [oid, setOid] = useState();
  const navigate = useNavigate();
  const clearCart = async () => {

    await localStorage.removeItem('resExTime');
    await localStorage.removeItem('resId');
    await localStorage.removeItem('cart');

  }
  let confirmOrder = async () => {
    let orderId = searchParams.get('oid');
    if(!orderId) navigate('/');
    else{
      setOid(orderId);
      await confirm(orderId).then(async(r) => {
        if(r?._id){

          clearCart().then(() => {
            window.dispatchEvent(new Event("storage"));
            if(counter) navigate('/seller?payment_intent_client_secret='+r.securityKey+'&oid='+r._id);
          });
        }else navigate('/buyTicket');
      });

    }
  }
  useEffect(() => {
    confirmOrder();
  },[]);

  return (
    <React.Fragment>
      <MobileCongratulations logged={logged} orderId={oid} />
      <DesktopCongratulations logged={logged} orderId={oid} />
    </React.Fragment>
  );
};

export default Congratulations;
