import axios from "axios";
import cookies from "react-cookies";
import conf from './config';

export default axios.create({
  baseURL: conf.app.backUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
