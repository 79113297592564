import React from 'react'
import './barcode.css'

// import QrReader from 'react-weblineindia-qrcode-scanner'


const BarcodeScanner = () => {

  const [result, setResult] = React.useState("Not Found");

  const handleError = (r) => {
    setResult(r);
    console.log(r);
  }
  const handleScan = (r) => {
    // setResult(r);
    // console.log(r);
  }

  const previewStyle = {
      height: 240,
      width: 320,
    }


  return (
    <div className='barcode-scanner'>
      <div className='scanner'>
    
      </div>
    </div>
  )
}

export default BarcodeScanner
