import React from "react";
import {
  Box,
  Skeleton,
  Typography,
  Button,
  AccordionDetails,
} from "@mui/material";

const AccordionDetailsSkeleton = () => {
  return (
    <AccordionDetails>
      <Box className="container-fluid my-4" sx={{ color: "#df0c62" }}>
        <hr />
        <Box className="row">
          <Box className="col-6 overall">
            <Skeleton variant="rectangular" width="100%" height={200} />
          </Box>
          <Box className="col-6 stat">
            {Array.from({ length: 6 }).map((_, index) => (
              <Box
                key={index}
                sx={{
                  borderBottom: "1px solid #df0c62",
                  width: "100%",
                  height: "30px",
                  marginTop: "5px",
                }}
              >
                <Skeleton variant="text" width="80%" />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="container-fluid my-4" sx={{ color: "#df0c62" }}>
        <hr />
        <Box className="row">
          <Box className="col-6 overall">
            <Typography variant="h5" sx={{ width: "100%" }}>
              Bilet türü:
            </Typography>
            {Array.from({ length: 3 }).map((_, index) => (
              <Box key={index} sx={{ width: "90%", marginLeft: "auto", mb: 1 }}>
                <Skeleton variant="text" width="90%" />
              </Box>
            ))}
            <hr />
            <Typography variant="h5" sx={{ width: "100%" }}>
              Ödeme türü:
            </Typography>
            {Array.from({ length: 3 }).map((_, index) => (
              <Box key={index} sx={{ width: "90%", marginLeft: "auto", mb: 1 }}>
                <Skeleton variant="text" width="80%" />
              </Box>
            ))}
          </Box>
          <Box className="col-6 stat">
            {Array.from({ length: 4 }).map((_, index) => (
              <Box
                key={index}
                sx={{
                  borderBottom: "1px solid #df0c62",
                  width: "100%",
                  height: "30px",
                  marginTop: "5px",
                }}
              >
                <Skeleton variant="text" width="70%" />
              </Box>
            ))}
          </Box>
        </Box>
        <hr />
      </Box>
      <Box display="flex" justifyContent="center" gap={2}>
        <Skeleton variant="rectangular" width={180} height={40} />
        <Skeleton variant="rectangular" width={180} height={40} />
      </Box>
    </AccordionDetails>
  );
};

export default AccordionDetailsSkeleton;
