import { Add } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useRef, useState } from "react";

const GridOne = ({commitImage, sponsors}) => {

  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [uploadedImage, setUploadedImage] = useState(sponsors?.split(',')[0]);
  // Handle Image Upload
  const handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUploadedImage(URL.createObjectURL(event.target.files[0]));
      commitImage(event.target.files[0], 0);
      console.log(event.target.files[0]);
    }
  };
  const ref = useRef();
  // handle Image Delete
  const handleImageDelete = () => {
    ref.current.value = ``;
  };
  return (
    <Box
      sx={{
        height: "650px",
        width: "650px",
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: "#ffb658",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        position="relative"
        zIndex={1}
        sx={{ height: "100%", width: "100%" }}
      >
        {!uploadedImage ? (
          <></>
        ) : (
          <img src={!uploadedImage.includes('blob') ? (CDN_URL+uploadedImage) : uploadedImage} alt="" width="100%" height="100%" />
        )}
      </Box>
      <Box
        position="absolute"
        sx={{
          top: "50%",
          left: "50%",
          translate: "-50% -50%",
        }}
        zIndex={1}
      >
        <input
          multiple
          ref={ref}
          type="file"
          id="upload"
          style={{ display: "none" }}
          accept="image/*"
          onChange={(event) => handleImage(event)}
        />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            translate: "-50% -50%",
          }}
        >
          <IconButton size="large">
            <label htmlFor="upload">
              <Add sx={{ color: "#df0c62" }} fontSize="large" />
            </label>
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0%",
          left: "0%",
        }}
      >
        <Typography variant="h4" color="white" fontWeight="bold">
          650x650
        </Typography>
      </Box>
    </Box>
  );
};

export default GridOne;
