import React from 'react'
import logo from '../../Assets/Images/logo.png'

const MailTemplate = () => {
  return (
    <div style={{ backgroundColor: '#fff0d7' }}>
      <div className='container'>
        <img src={logo} style={{ padding: '108px 0 50px 0' }} />
        <div className='col-10' style={{ color: '#df0c62', fontWeight: '500', fontFamily: 'Roboto', letterSpacing: '1px', fontSize: '18px' }}>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.

          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.

          </p>
          <span style={{ height: '24px', width: '24px', borderRadius: '50%', display: 'inline-block', backgroundColor: '#df0c62', margin: '0 4px 15px 0' }}></span>
          <a href="https://eticketal.com" style={{ fontSize: '36px', fontWeight: '600', paddingBottom:'185px' }}>
            eticketal.com
          </a>



        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 228.64 134.64"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g class="cls-1" style={{ opacity: '0.5' }}><path class="cls-2" style={{ fill: '#df0c62' }} d="M66.24,13.57H41.33V81.33H24.58V13.57H0V0H66.24Z" /></g><g class="cls-1" style={{ opacity: '0.5' }}><path class="cls-2" style={{ fill: '#df0c62' }} d="M63,99.33H46.21V18H63Z" /></g><g class="cls-1" style={{ opacity: '0.5' }}><path class="cls-2" style={{ fill: '#df0c62' }} d="M112.36,106.43q-1,13.13-9.69,20.67t-23,7.54Q64,134.64,55,124.11t-9-28.91v-5a48.63,48.63,0,0,1,4.13-20.66A31,31,0,0,1,62,55.85a33.13,33.13,0,0,1,17.85-4.77q14.07,0,22.68,7.54t9.94,21.17H95.71q-.61-7.87-4.38-11.42T79.85,64.82q-8.38,0-12.54,6T63,89.45v6.14q0,13.18,4,19.28T79.63,121q7.75,0,11.59-3.54t4.38-11Z" /></g><g class="cls-1" style={{ opacity: '0.5' }}><path class="cls-2" style={{ fill: '#df0c62' }} d="M121.23,65.71l-8.72,9.38V98.33H95.76V17h16.75V53.86l7.37-10.11L140.61,17h20.61L132.34,53.14l29.72,45.19H142.12Z" /></g><g class="cls-1" style={{ opacity: '0.5' }}><path class="cls-2" style={{ fill: '#df0c62' }} d="M194.58,79H162.41v21.78h37.76v13.46H145.65V32.9h54.4V46.47H162.41V65.85h32.17Z" /></g><g class="cls-1" style={{ opacity: '0.5' }}><path class="cls-2" style={{ fill: '#df0c62' }} d="M228.64,14.57H203.73V82.33H187V14.57H162.4V1h66.24Z" /></g></g></g></svg>

    </div>
  )
}

export default MailTemplate
