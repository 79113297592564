import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import MobileAppBar from "../../AppBar/Mobile/TopBar/MobileAppBar";
import NavBar from "../../AppBar/Mobile/NavBar/MobileNavBar";
import AutoInput from "../../AutoComplete/AutoInput";
import aidKit from "../../../Assets/Images/aidKit.png";
import smallTicket from "../../../Assets/Images/smallTicket.png";
import logo from "../../../Assets/Images/logo.png";
import City from "../../../Assets/svg/city.svg";
import { ReactComponent as Stage } from "../../../Assets/svg/stage.svg";
import { ReactComponent as Cloud } from "../../../Assets/svg/cloudGroupBlack.svg";
import { SocialIcon } from "react-social-icons";
import classes from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import FooterTablet from "../../Panel/Footer/Tablet/FooterTablet";
import FooterMobile from "../../Panel/Footer/Mobile/FooterMobile";
import Footer from "../../Panel/Footer/Footer";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive'
const EventsMobile = ({events,detail,focusedImage,setEvents,setDetail,setFocusedImage,searched}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 567px)' });
  const { t } = useTranslation()
  const dtesArray = [0, 1, 2, 3];
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const navigate = useNavigate();

  const evntsArray = [0, 1, 2, 3];
  return (
    <Box
      className={classes.container}
      sx={{ display: { xs: "block", md: "none" } }}
    >
      <MobileAppBar color="white" shadow={[255, 160, 200, 0.702]} />
      {/* Border */}
      <Stack display="flex" direction="column" spacing={4}>
        <Container maxWidth="xs">
          {/* Featured Events */}

          {/* Search Country */}
          <Stack display="flex" direction="column">
            <Typography variant="h6" fontWeight="bold" color="#df0c62">
              {t('find_in_your_country')}
            </Typography>
            <div className={classes.searchCountry}>
              <AutoInput />
              <img className={classes.cityImage} src={City} />
            </div>
          </Stack>
        </Container>


        {/* Events */}
        <Box className={classes.events}>
          <Container maxWidth="xs">
            <Stack display="flex" direction="column" spacing={4}>
              {/* Event  */}
              {events.length > 0 ? (
                <>{events.map((item,index) => (
                  <div>
                    <Stack onClick={() => navigate('event/'+(detail?.slug || events[0]?.slug))}  display="flex" direction="column" spacing={1}>
                      <img src={CDN_URL+item.image} alt="aidKit" />
                      <Typography
                        variant="h4"
                        color="#df0c62"
                        className={classes.titleStroke}
                      >
                        {item.title}
                      </Typography>

                      <Typography paragraph color="#df0c62">
                        {item.description}
                      </Typography>
                      <Typography
                        variant="h6"
                        textAlign="end"
                        sx={{
                          textDecoration: "underline",
                        }}
                        color="#df0c62"
                      >
                        {item.location?.name}
                      </Typography>
                      <Box className={classes.redDash}></Box>
                      <Stack
                        display="flex"
                        direction="column"
                        className={classes.locationAndDate}
                      >
                        <Box className={classes.yellowLine}>
                          <Stack
                            display="flex"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            p={"12px 0"}
                          >
                            <Box flex={1} className={classes.ticketContainer}>
                              <Typography color="#df0c62">
                                {item.startDate.split('T')[0] + " " + item.startDate.split('T')[1]}
                              </Typography>
                            </Box>
                            <Box
                              flex={1}
                              className={classes.ticketContainer}
                              onClick={() => navigate('event/'+(detail?.slug || events[0]?.slug))}
                            >
                              <Typography
                                color="#df0c62"
                                className={classes.buy}
                              >
                                {t('Buy')}
                              </Typography>
                              <img
                                src={smallTicket}
                                alt="smallTicket"
                                className={classes.smallTicket}
                              />
                            </Box>
                          </Stack>
                        </Box>
                      </Stack>
                    </Stack>
                  </div>
                ))}</>
              ) : searched ? (
                <Typography
                  variant="h4"
                  color="#df0c62"
                  className={classes.focusedTitle}
                >
                 {t('not_found')}
                </Typography>
              ):null}

            </Stack>
          </Container>
        </Box>
        {!isTablet ? ( <FooterMobile /> ):( <FooterTablet />)}
      </Stack>
      {/* Border */}
      <NavBar />
    </Box>
  );
};

export default EventsMobile;
