import React, { useState, useEffect } from "react";
import style from "./organizer.module.css";
import TextField from "@mui/material/TextField";
import NavBar from "../NavBar/NavBar";
import { getAllCountries } from "../../../Data/general";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import { Box, Button, Modal } from "@mui/material";
import SeatingSubmit from "./SeatingSubmit";
import { standartSeats,defaultType,standartMap} from "../../../Services/helpers";

const LocationSubmit = ({ form, setForm, submit, onDismiss }) => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [countries, setCountries] = useState([]);
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [fieldmodal, setFieldModal] = useState(false);

  const fetchCountries = async () => {
    let countries = await getAllCountries();
    console.log(countries);
    countries.sort((a,b) => {
      return a.title > b.title ? 1 : -1;
    });
    console.log(countries);
    setCountries(countries);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const changeImage = (e) => {
    if(e.target.files[0].size > 5000000){
      toast('Yüklenen resim 5MB fazla olamaz');
      e.target.value = "";
      e.target.type = "file";
    }else{
      let list = Array.from(e.target.files);
      setForm({...form, image: list[0]})
    }
  }

  const onFieldClose = () => {
    setFieldModal(false);
  };

  useEffect(() => {
     fetchCountries();

  }, []);


  return (
    <div style={{ height: "100vh", backgroundColor: "#fcf2e2" }}>
      <NavBar color="#ffd283" />
      <Modal open={fieldmodal} onClose={onFieldClose}>
        <SeatingSubmit plan={form.plan} changeplan={(r) => {setForm({...form, plan: r})}} onFieldClose={onFieldClose} />
      </Modal>
      <div
        className="container d-flex justify-content-center align-items-center mt-3"
        style={{ height: "75vh" }}
      >
        <div className={style.submit}>
          <div
            onClick={onDismiss}
            style={{ cursor: "pointer" }}
            className="text-end"
          >
            Kapat
          </div>
          <div style={{ height: "calc(100% - 45px)" }}>
            <h2 className="mb-5" style={{ color: "#df0c62" }}>
              SAHNE BİLGİLERİ
            </h2>
            <form
              className="container-fluid"
              style={{ height: "calc(100% - 40px)", overflowY: "auto" }}
            >
              <div className="row">
                <div className="col-12">
                  <input
                    className="mb-4"
                    type="text"
                    value={form.name}
                    name="name"
                    placeholder="Sahne Adı"
                    onChange={onChange}
                  />
                  <div className="mb-4 modalFormInput">
                    <Autocomplete
                      options={countries || []}
                      open={countrySelectorOpen}
                      onOpen={() => {
                        setCountrySelectorOpen(true);
                      }}
                      onClose={() => {
                        setCountrySelectorOpen(false);
                      }}
                      value={form.country ? {title:form.country} : {title:''}}
                      onChange={(e) => {
                        setForm({ ...form, country: e.target.innerText });
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.title === value
                      }
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Sahne Ülkesi" />
                      )}
                    />
                  </div>
                  <input
                    className="mb-4"
                    type="text"
                    value={form.city}
                    name="city"
                    placeholder="Sahne Şehri"
                    onChange={onChange}
                  />
                  <input
                    className="mb-4"
                    type="text"
                    value={form.description}
                    name="description"
                    placeholder="Sahne Açıklaması"
                    onChange={onChange}
                  />
                  <input
                    className="mb-4"
                    type="text"
                    value={form.address}
                    name="address"
                    placeholder="Açık Adress"
                    onChange={onChange}
                  />
                  <input
                    className="mb-4"
                    type="text"
                    value={form.url}
                    name="url"
                    placeholder="Harita Bağlantısı"
                    onChange={onChange}
                  />
                  <input
                    className="mb-4"
                    type="text"
                    value={form.lat}
                    name="lat"
                    placeholder="Enlem Koordinatı"
                    onChange={onChange}
                  />
                  <input
                    className="mb-4"
                    type="text"
                    value={form.lng}
                    name="lng"
                    placeholder="Boylam Koordinatı"
                    onChange={onChange}
                  />
                  {/* <input className='mb-4' type='text' value={form.plan} name="plan" placeholder='Oturma Düzeni' onChange={onChange} /> */}


                  <div className="d-flex flex-row">
                  <Box
                    className="mb-4 modalFormInput d-flex align-items-center justify-content-between"
                    onClick={(e) => {document.querySelector('#locationImage').click()}}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    Resim (800px X 800px)(1x1)
                    {form.image ? (
                      <>
                        {typeof form.image != 'string' ? (
                          <img src={URL.createObjectURL(form.image)} height="50px" width="auto" />
                        ):(
                          <img src={CDN_URL+form.image} height="50px" width="auto" />
                        )}
                      </>
                    ):null}
                  </Box>
                  <input
                    className="mb-4"
                    type="file"
                    id="locationImage"
                    value={form.image ? form.image.filename : null}
                    name="image"
                    placeholder="Sahne Görseli"
                    onChange={(e) => {changeImage(e)}}
                  />
                  </div>
                  <Box
                    className="mb-4  modalFormInput d-none"
                    onClick={() => setFieldModal(true)}
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    Oturma Düzeni
                    <span style={{float:'right'}}>  {form.plan ? ( form.plan.simple ? "Basit" : "Planlı") : "Yok"}</span>
                  </Box>
                </div>
              </div>
              <div className={`${style.note} p-5`}>
                *Yükleyecek olduğunuz görsel 800x800 px ölçüsünden fazla,
                minimum 150 dpi olmalıdır. Aksi taktirden sistem onayından
                geçemez.
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="d-flex fixed-bottom">
        <div style={{ backgroundColor: "#feb659", width: "60px" }}></div>
        <div style={{ backgroundColor: "#ffd283", width: "20px" }}></div>
        <button className={`${style.button}`} onClick={submit}>
          Mekan Kaydet
        </button>
      </div>
    </div>
  );
};

export default LocationSubmit;
