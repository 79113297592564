import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  listAll,
  deleteCustomer,
  registerCustomer,
} from "../../../../../Data/customer";
import { ExpandMore, FileDownloadOutlined } from "@mui/icons-material";
import classes from "./styles.module.css";
import { BsTrash } from "react-icons/bs";
import SearchBar from "../../../SearchBar/SearchBar";
import AModal from "../../../Modal/AModal";
import { useTranslation } from "react-i18next";
import Spinner from "../../../../Common/Spinner";
const CustomerDesktop = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [allcustomers, setallCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async () => {
    setIsLoading(true);
    setCustomers(await listAll());
    setIsLoading(false);
  };

  const onDelete = async (id) => {
    await deleteCustomer(id).then((r) => {
      if (!r) toast.error(t("error_try_again"));
      else {
        toast.success(t("customer_deleted"));
        fetch();
      }
    });
  };

  const onAdd = async (customer) => {
    await registerCustomer(customer).then((r) => {
      if (!r) toast.error(t("error_try_again"));
      else {
        toast.success(t("customer_added"));
        fetch();
      }
    });
  };

  const search = (query) => {
    setSearchQuery(query);
    if (query == "") setCustomers(allcustomers);
    else {
      let serial = query.toLowerCase();
      let list = [];
      if (allcustomers.length == 0) {
        setallCustomers([...customers]);
        list = [...customers];
      } else list = allcustomers;
      list = list.filter(
        (it) =>
          it.firstName?.toString().toLowerCase().includes(serial) ||
          it.lastName?.toString().toLowerCase().includes(serial) ||
          it.email?.toString().toLowerCase().includes(serial) ||
          it.phoneNumber?.toString().toLowerCase().includes(serial)
      );
      setCustomers(list);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  let ctmrArray = [0, 1];
  let customer = {};

  return (
    <Box>
      <Stack display="flex" direction="column" spacing={4}>
        {/* first Part */}

        {/* Add Customer Modal*/}
        <AModal title=" müşteri ekle" submit={onAdd} />
        {/* Search Bar */}
        <SearchBar query={searchQuery} changeQuery={search} />
        {/* ÜYE BİLGİLERİ */}
        <Stack
          display="flex"
          direction="column"
          padding="2%"
          spacing={2}
          sx={{ backgroundColor: "#ffd283", height: "fit-content" }}
          className={classes.member}
        >
          {/* Title */}
          <Typography variant="h5" fontWeight="bold" color="#df0c62">
            ÜYE BİLGİLERİ
          </Typography>
          {isLoading && <Spinner />}
          {customers.map((c, inx) => {
            return (
              <Box key={inx}>
                <Accordion
                  sx={{
                    backgroundColor: "#fff0d7",
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    sx={{ paddingRight: "0" }}
                    expandIcon={<ExpandMore sx={{ color: "#df0c62" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Stack display="flex" direction="row" width="100%">
                      <Typography color="#df0c62" fontWeight="bold">
                        {c.firstName + " " + c.lastName}
                      </Typography>
                      <Box flex={1} sx={{ borderBottom: "2px solid #feb659" }}>
                        <Typography
                          color="#df0c62"
                          fontWeight="bold"
                          textAlign="end"
                        >
                          Biglileri Gör
                        </Typography>
                      </Box>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Detail parent Stack */}
                    <Stack display="flex" direction="column" spacing={4}>
                      {/* First Part Stack */}
                      <Stack
                        display="flex"
                        direction="row"
                        spacing={2}
                        sx={{ backgroundColor: "#fff0d7" }}
                      >
                        {/* Left Side */}
                        <Box flex={1}>
                          <Stack display="flex" direction="column" spacing={2}>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.firstName}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.phoneNumber}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.created.split("T")[0] +
                                  " " +
                                  c.created.split("T")[1].split(".")[0]}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                        {/* Right Side */}
                        <Box flex={1}>
                          <Stack display="flex" direction="column" spacing={2}>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.lastName}
                              </Typography>
                            </Box>
                            <Box
                              padding="8px 0"
                              sx={{ borderBottom: "2px solid #feb659" }}
                            >
                              <Typography color="#df0c62" fontWeight="bold">
                                {c.email}
                              </Typography>
                            </Box>
                          </Stack>
                        </Box>
                        {/* End Of First Part Stack */}
                      </Stack>
                      {/* second Part Stack */}
                      <Stack display="flex" direction="column" spacing={2}>
                        <Stack display="flex" direction="row" width="100%">
                          <div style={{ display: "none" }}>
                            <Typography color="#df0c62" fontWeight="bold">
                              PDF indir
                            </Typography>
                            <IconButton size="medium">
                              <FileDownloadOutlined sx={{ color: "#df0c62" }} />
                            </IconButton>
                          </div>

                          <Box
                            flex={1}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Kullanıcı sılıyorsunuz. Emin misiniz?"
                                )
                              )
                                onDelete(c._id);
                            }}
                            sx={{
                              borderBottom: "2px solid #feb659",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <Typography color="#df0c62" fontWeight="bold">
                              Sil
                            </Typography>
                            <IconButton size="medium">
                              <BsTrash style={{ color: "#df0c62" }} />
                            </IconButton>
                          </Box>
                        </Stack>
                        {/* End of Second PArt Stack */}
                      </Stack>
                      {/* End Of Accordion Detail Stack */}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                {/* Accordion End */}
              </Box>
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CustomerDesktop;
