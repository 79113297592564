import { dataGet, dataPost } from "../Services/data.js";

export const addEvent = async (params) => {
  let event = {};
  await dataPost("event/addEvent", { ...params }).then((response) => {
    event = response.content;
  });
  return event;
};

export const listAllEvents = async () => {
  let events = [];
  await dataGet("event/listAll").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const listAllDetailed = async () => {
  let events = [];
  await dataGet("event/listAllDetailed").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const fetchOneDetailed = async (id) => {
  let events = [];
  await dataGet("event/getEventDetails/" + id).then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const listPromoted = async () => {
  let events = [];
  await dataGet("event/listPromoted").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const listMyPromoted = async () => {
  let events = [];
  await dataGet("event/listMyPromoted").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const listOnlyPromoted = async () => {
  let events = [];
  await dataGet("event/listOnlyPromoted").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};


export const approveEvent = async (params) => {
  let event = {};
  await dataPost("event/approve", { ...params }).then((response) => {
    event = response.content;
  });
  return event;
};

export const setSponsors = async (id, sponsors) => {
  let event = {};
  await dataPost("event/setSponsors", { _id: id, sponsors }).then((response) => {
    event = response.content;
  });
  return event;
};

export const setComission = async (id, commission) => {
  let event = {};
  await dataPost("event/setComission", { _id: id, commission }).then((response) => {
    event = response.content;
  });
  return event;
};


export const listCountries = async () => {
  let events = [];
  await dataGet("event/listCountries").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const listEvents = async () => {
  let events = [];
  await dataPost("event/list").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const listActives = async () => {
  const response = await dataPost("event/listActives");
  if (!response || !response.isSuccess) return [];
  return response.content;
};

export const listFronted = async () => {
  const response = await dataPost("event/listFronted");
  if (!response || !response.isSuccess) return [];
  return response.content;
};

export const searchEvents = async (query) => {
  let events = [];
  await dataPost("event/search", { query: query }).then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const listNotApproved = async () => {
  let events = [];
  await dataGet("event/listNotApproved").then((response) => {
    if (response && response.isSuccess) events = response.content;
  });
  return events;
};

export const getBySlug = async (slug) => {
  let event = {};
  await dataPost("event/getBySlug", { slug: slug }).then((response) => {
    if (response && response.isSuccess) {
      event = response.content;
    }
  });
  return event;
};

export const getByCounter = async () => {
  let event = {};
  await dataGet("event/getByCounter").then((response) => {
    if (response && response.isSuccess) {
      event = response.content;
    }
  });
  return event;
};

export const getById = async (id) => {
  let event = {};
  await dataPost("event/getById", { _id: id }).then((response) => {
    if (response && response.isSuccess) {
      event = response.content;
    }
  });
  return event;
};

export const updateEvent = async (params) => {
  let event = {};
  await dataPost("event/update", { ...params }).then((response) => {
    event = response.content;
  });
  return event;
};


export const eventPostponePrepare = async (id) => {
  let info = [];
  await dataPost("event/eventPostponePrepare", { _id: id }).then((response) => {
    if (response && response.isSuccess) {
      info = response.content;
    }
  });
  return info;
};


export const eventPostponed = async (id) => {
  let info = {};
  await dataPost("event/eventPostponed", { _id: id }).then((response) => {
    if (response && response.isSuccess) {
      info = response.content;
    }
  });
  return info;
};





export const deleteEvent = async (id) => {
  let success = false;
  await dataPost("event/deleteEvent", { _id: id }).then((response) => {
    success = response.isSuccess;
  });
  return success;
};

export const disableEvent = async (id) => {
  let success = false;
  await dataPost("event/disableEvent", { _id: id }).then((response) => {
    success = response.isSuccess;
  });
  return success;
};

export const remindCountryBased = async (data) => {
  let o = {}
  await dataPost("event/remind-country-based", { ...data }).then((response) => {
    o = response?.content;
  });
  return o;
};
