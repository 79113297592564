import { dataGet, dataPost } from "../Services/data.js";

export const addPlan = async (params) => {
  let plan = {};
  await dataPost("plan/addPlan", { ...params }).then((response) => {
    plan = response.content;
  });
  return plan;
};

export const testPlan = async () => {
  let plan = {
    standartSeatTypes: [
      { types: 'default', label: 'Default', amount: 400, sold: 0, price: 20 },
      { types: 'backstage', label: 'Backstage', amount: 100, sold: 0, price: 150 }
    ]
    //  map: {
    //   rows: 8,
    //   columns: 20,
    //   seatTypes: {
    //     default: { types:'default', label: "Economy", cssClass: "second-class", price: 15 },
    //     first: {types:"first",label: "First Class",cssClass: "first-class",price: 25,seatRows: [0, 1, 2]},
    //     reduced: {types:"reduced",label: "Reduced",cssClass: "third-class",price: 10,seatRows: [5,6,7]},
    //   },
    //   rowSpacers: [3, 7],
    //   columnSpacers: [5,10,15],
    // }
  };

  await dataPost("plan/addPlan", { ...plan }).then((response) => {
    plan = response.content;
  });
  return plan;
};

export const getByLocation = async (params) => {
  let plan = [];
  await dataPost("plan/getByLocation", { location: params }).then((r) => {
    if (r?.isSuccess) plan = r.content;
  });
  return plan;
}



export const closePlaces = async (params) => {
  let plan = null;
  await dataPost("plan/closePlaces", { planid: params.plan, places: params.places }).then((r) => {
    if (r.isSuccess) plan = r.content;
    else plan = false;
  });
  return plan;
}

export const openPlaces = async (params) => {
  let plan = null;
  await dataPost("plan/openPlaces", { planid: params.plan, places: params.places }).then((r) => {
    if (r.isSuccess) plan = r.content;
    else plan = false;
  });
  return plan;
}


export const updatePlan = async (params) => {
  let plan = {};
  await dataPost("plan/updatePlan", { ...params }).then((response) => {
    plan = response.content;
  });
  return plan;
};

export const setDefault = async (id) => {
  let plan = {};
  await dataPost("plan/setDefault", { id: id }).then((response) => {
    plan = response.content;
  });
  return plan;
};

export const deletePlan = async (id) => {
  let plan = {};
  await dataPost("plan/deletePlan", { _id: id }).then((response) => {
    plan = response.content;
  });
  return plan;
};
