import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import NavBar from "../../../NavBar/NavBar";
import classes from "./styles.module.css";
import aidKit from "../../../../../Assets/Images/aidKit.png";
import seats from "../../../../../Assets/Images/seats.png";
import { Close, ExpandMore } from "@mui/icons-material";
import logo from "../../../../../Assets/Images/logo.png";
const HotSaleDesktop = () => {
  const arreee = [0, 1, 2, 3, 4, 5, 6];

  return (
    <div>
      <NavBar color="#ffb658" />
      <Box
        sx={{
          backgroundColor: "#fff0d7",
          boxShadow: "inset 0px 1px 10px 0px #fec982",
          height: { xs: "250px", sm: "300px" },
          paddingTop: { xs: "7%", sm: "4%" },
        }}
      >
        <Container maxWidth="md" sx={{ height: "100%" }}>
          <Stack display="flex" direction="row" sx={{ height: "100%" }}>
            {/* Event Poster */}
            <Box flex={1} sx={{ display: { xs: "none", sm: "block" } }}>
              <img
                src={aidKit}
                alt="Event Poster"
                className={classes.eventPoster}
              />
            </Box>
            <Box
              flex={1}
              sx={{
                display: { xs: "block", sm: "none" },
                position: "relative",
                top: 50,
              }}
            >
              <img
                src={aidKit}
                alt="Event Poster"
                className={classes.eventPoster}
              />
            </Box>
            {/* Event Details */}
            <Box flex={2} className={classes.eventDetails}>
              <Stack display="flex" direction="column">
                <Typography
                  variant="h4"
                  color="#df0c62"
                  className={classes.titleStroke}
                >
                  KADINLAR MATİNESİ
                </Typography>
                <Stack display="flex" direction="row">
                  <Box flex={1}>
                    <Typography variant="h6" fontWeight="bold" color="#df0c62">
                      Hanssonzentrum
                    </Typography>
                    <Typography paragraph color="#df0c62">
                      09.10.2022 / 22:30
                    </Typography>
                  </Box>
                </Stack>
                <Box className={classes.redDash}></Box>
                {/* Event Type */}
                <Typography paragraph color="#df0c62">
                  KONSER
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Container>
        {/* End of Top Container */}
      </Box>
      <Container maxWidth="md" sx={{ paddingBottom: 5 }}>
        {/* Parent Stack */}
        <Stack display="flex" direction="column" marginTop={20} spacing={4}>
          <Stack display="flex" direction="row">
            <Typography variant="h6" color="#df0c62" fontWeight="bold">
              Koltuk Seç
            </Typography>
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}></Box>
          </Stack>

          {/* Seats Stack */}
          <Stack
            display="flex"
            sx={{
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: 2, sm: 1 },
            }}
            spacing={1}
            pt={2.5}
          >
            <Box flex={3}>
              <img src={seats} alt="seats" width="100%" />
            </Box>
            <Box flex={1.5}>
              <Stack
                display="flex"
                direction="column"
                height="100%"
                spacing={1}
              >
                {/* Seats */}
                <Box className={classes.pickedSeats}>
                  <Stack display="flex" direction="column" spacing={4} p={2}>
                    {arreee.map((ar, i) => {
                      return (
                        <Box
                          key={i}
                          display="flex"
                          justifyContent="center"
                          className={classes.seat}
                        >
                          <Box className={classes.deleteButton}>
                            <IconButton
                              size="small"
                              sx={{
                                backgroundColor: "#df0c62",
                                "&:hover": { backgroundColor: "#df0c62" },
                              }}
                            >
                              <Close sx={{ color: "white" }} />
                            </IconButton>
                          </Box>
                          <Box mr={1}>
                            <Typography color="#df0c62" fontWeight="bold">
                              sira:3
                            </Typography>
                          </Box>
                          <Box mr={1}>
                            <Typography color="#df0c62" fontWeight="bold">
                              Koltuk:23
                            </Typography>
                          </Box>
                          <Box mr={1}>
                            <Typography color="#df0c62" fontWeight="bold">
                              75,000$
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                </Box>
              </Stack>
            </Box>
            {/* End Of Seats Stack */}
          </Stack>

          {/* Amount Of Tickets */}
          <Stack display="flex" direction="row" alignItems="center">
            <Typography variant="h6" color="#df0c62" paragraph>
              Biletler
            </Typography>
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd283" }}></Box>
            <Typography
              variant="h6"
              color="#df0c62"
              paragraph
              sx={{ fontSize: { xs: "0.8rem", sm: "2rem" } }}
            >
              Sepetinizde{" "}
              <strong
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                2
              </strong>{" "}
              adet bilet bulunmaktadır.
            </Typography>
          </Stack>

          <Stack
            display="flex"
            direction="column"
            sx={{ height: { xs: "200px", sm: "300px" }, overflowY: "scroll" }}
            className={classes.ticketStack}
          >
            {/* details */}
            <Stack display="flex" direction="row">
              <Box
                flex={1}
                sx={{
                  borderBottom: "2px solid #ffd289",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  Blok
                </Typography>
              </Box>
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  Sıra
                </Typography>
              </Box>
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  Koltuk
                </Typography>
              </Box>
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  Bilet Bedeli
                </Typography>
              </Box>
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  Servis Bedeli
                </Typography>
              </Box>
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  Tolam Tutar
                </Typography>
              </Box>
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", sm: "1.5rem" } }}
                  fontWeight="bold"
                  color="#df0c62"
                  textAlign="center"
                >
                  Sil
                </Typography>
              </Box>
            </Stack>

            {/* Tickets In Cart */}
            {arreee.map((tckt, i) => {
              return (
                <Stack key={i} display="flex" direction="row">
                  <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.6rem", sm: "1.5rem" } }}
                      fontWeight="bold"
                      color="#df0c62"
                      textAlign="center"
                    >
                      Salon
                    </Typography>
                  </Box>
                  <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.6rem", sm: "1.5rem" } }}
                      fontWeight="bold"
                      color="#df0c62"
                      textAlign="center"
                    >
                      3
                    </Typography>
                  </Box>
                  <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.6rem", sm: "1.5rem" } }}
                      fontWeight="bold"
                      color="#df0c62"
                      textAlign="center"
                    >
                      23
                    </Typography>
                  </Box>
                  <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.6rem", sm: "1.5rem" } }}
                      fontWeight="bold"
                      color="#df0c62"
                      textAlign="center"
                    >
                      65,000 €
                    </Typography>
                  </Box>
                  <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.6rem", sm: "1.5rem" } }}
                      fontWeight="bold"
                      color="#df0c62"
                      textAlign="center"
                    >
                      10,000 €
                    </Typography>
                  </Box>
                  <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.6rem", sm: "1.5rem" } }}
                      fontWeight="bold"
                      color="#df0c62"
                      textAlign="center"
                    >
                      75,000 €
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    sx={{ borderBottom: "2px solid #ffd289" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box sx={{ display: { xs: "none", sm: "block" } }}>
                      <IconButton
                        size="medium"
                        onClick={() => alert("deleted")}
                      >
                        <Close sx={{ color: "#df0c62" }} />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "block",
                          sm: "none",
                          position: "relative",
                          bottom: 5,
                        },
                      }}
                    >
                      <IconButton size="small" onClick={() => alert("deleted")}>
                        <Close sx={{ color: "#df0c62", fontSize: "1rem" }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Stack>
              );
            })}
          </Stack>

          <Stack
            display="flex"
            direction="row"
            justifyContent="space-between"
            sx={{ border: "2px solid #df0c62" }}
            p={1}
          >
            <Typography variant="h6" color="#df0c62">
              Genel Toplam:
            </Typography>
            <Typography variant="h6" color="#df0c62" fontWeight="bold">
              150,000 €
            </Typography>
          </Stack>

          <Typography color="#df0c62">*Fiyatlarda KDV dahildir.</Typography>

          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
                mr: 2,
                borderRadius: 0,
              }}
            >
              Tümünü Sil
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#df0c62",
                "&:hover": { backgroundColor: "#df0c62" },
                borderRadius: 0,
              }}
            >
              Devam
            </Button>
          </Box>

          {/* Payment Section */}
          <Stack display="flex" direction="row" alignItems="center">
            <Typography
              variant="h6"
              color="#df0c62"
              fontWeight="bold"
              paragraph
            >
              Ödeme
            </Typography>
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd283" }}></Box>
          </Stack>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={
                <ExpandMore fontSize="large" sx={{ color: "#df0c62" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" fontWeight="bold" color="#df0c62">
                Kredi Kartı:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack display="flex" direction="column" spacing={2}>
                <Box>
                  <Typography fontWeight="bold" color="#df0c62">
                    Kart Üzerindeki Ad Soyad
                  </Typography>
                  <input
                    style={{
                      width: "100%",
                      border: "2px solid #df0c63",
                      outline: "none",
                    }}
                  />
                </Box>
                <Box>
                  <Typography fontWeight="bold" color="#df0c62">
                    Kart Numarası
                  </Typography>
                  <input
                    style={{
                      width: "100%",
                      border: "2px solid #df0c63",
                      outline: "none",
                    }}
                  />
                </Box>

                <Stack display="flex" direction="row" spacing={1}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" color="#df0c62">
                      Ay/Yıl
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" color="#df0c62">
                      CVC
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                    />
                  </Box>
                </Stack>
                <Typography color="#df0c62">
                  *Ödeme işlemine devam ederek KVKK Aydonlatma Metnini’okuduğımu
                  ve anladığımı kabul ediyorum.
                </Typography>

                <Box display="flex" justifyContent="center">
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      backgroundColor: "#df0c62",
                      "&:hover": { backgroundColor: "#df0c62" },
                      width: "25%",
                      borderRadius: 0,
                    }}
                  >
                    Onayla
                  </Button>
                </Box>
                {/* End Of Kart Payment Detail */}
              </Stack>
            </AccordionDetails>
          </Accordion>

          {/* Cash */}

          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={
                <ExpandMore fontSize="large" sx={{ color: "#df0c62" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5" color="#df0c62" fontWeight="bold">
                Nakit
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack display="flex" direction="column" spacing={2}>
                <Stack display="flex" direction="row" spacing={2}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" color="#df0c62">
                      Ad*
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" color="#df0c62">
                      Soyad*
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                    />
                  </Box>
                </Stack>

                <Stack display="flex" direction="row" spacing={2}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" color="#df0c62">
                      Telefon Numarası*
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" color="#df0c62">
                      E-Posta*
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                    />
                  </Box>
                </Stack>

                <Box display="flex" justifyContent="center">
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      backgroundColor: "#df0c62",
                      "&:hover": { backgroundColor: "#df0c62" },
                      width: "25%",
                      borderRadius: 0,
                    }}
                  >
                    Onayla
                  </Button>
                </Box>
                {/* End Of Kart Payment Detail */}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation="0">
            <AccordionSummary
              expandIcon={<></>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack display="flex" direction="row" alignItems="center">
                <Typography color="#df0c62" variant="h6" fontWeight="bold">
                  Makbuz istiyorum
                </Typography>
                <Checkbox color="error" />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      ulke *
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="country"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      şehir *
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="city"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      Adres 1*
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="adres1"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      Adres 2*
                    </Typography>
                    <input
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="adres2"
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Page End Logo */}
          <Stack display="flex" direction="row">
            <img src={logo} alt="logo" width="22%" />
            <Box flex={1} sx={{ borderBottom: "2px solid #df0c62" }}></Box>
          </Stack>
          {/* End Of Parent Stack */}
        </Stack>
      </Container>
    </div>
  );
};

export default HotSaleDesktop;
