import { GETALLCODES } from "../Actions/types";

const getCodes = (state = null, action) => {
  if (action.type === GETALLCODES) {
    return (state = action.payload);
  } else {
    return state;
  }
};

export default getCodes;
