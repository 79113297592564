import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Modal,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import NavBar from "../components/Panel/NavBar/NavBar";
import cookies from "react-cookies";
import QRCode from "react-qr-code";
import classes from "../components/Panel/OrganizerPanel/HotSale/Desktop/styles.module.css";
import aidKit from "../Assets/Images/aidKit.png";
import seats from "../Assets/Images/seats.png";
import pdfBack from "../Assets/Images/endTicket.jpg";
import scissors from "../Assets/Images/scissors.svg";
import { createPayment } from "../Data/payments";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/Payment/CheckoutForm";

import { getBySlug, getByCounter } from "../Data/event";
import { make, confirm, fetchTickets, calculate } from "../Data/order";
import { testPlan } from "../Data/plan";

import SeatchartJS, { Options } from "seatchart";
import Seatchart from "../components/Seatchart/Seatchart.js";

import "seatchart/dist/seatchart.min.css";
import smallTicket from "../Assets/Images/smallTicket.png";
import AutoInput from "../components/AutoComplete/AutoInput";

import { useTranslation } from "react-i18next";

import { Close, ExpandMore } from "@mui/icons-material";
import logo from "../Assets/Images/logo.png";
const HotSale = ({ stripePromise }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();

  const appearance = {
    theme: "none",
    variables: { colorPrimary: "#df0b62", colorPrimaryText: "#df0b62" },
    rules: {
      ".Tab": { border: "2px solid #ffd689" },
      ".Tab:hover": {
        color: "#df0b62",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
      },
      ".Tab--selected": { borderColor: "#df0b62", color: "#df0b62" },
      ".TabIcon--selected": { fill: "#df0b62" },
      ".Input": {
        color: "#df0b62",
        border: "2px solid #df0b62",
        borderRadius: 0,
      },
      ".Input::placeholder": { color: "#df0b62" },
      ".Input--invalid": {
        boxShadow:
          "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
      },
      ".Label": { color: "#df0b62" },
      ".p-LinkAutofillPromptButton": { color: "#df0b62" },
      ".RedirectText": { color: "#df0b62" },
    },
  };
  const [clientSecret, setClientSecret] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [checked, setChecked] = useState({
    first: false,
    second: false,
    third: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const [counter, setCounter] = useState(localStorage.getItem("counter"));

  const [order, setOrder] = useState({
    invoice: false,
    country: "",
    city: "",
    zip: "",
    address1: "",
    address2: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    cardName: "",
    cardNumber: "",
    cardDate: "",
    cardCVC: "",
  });

  const getParam = (name) => {
    const urlparams = new URLSearchParams(window.location.search);
    return urlparams.get(name);
  };

  const [standart, setStandart] = useState(null);
  const navigate = useNavigate();

  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const [thisEvent, setEvent] = useState(null);
  const [eventMap, setEventMap] = useState(null);
  const [soldTickets, setSoldTickets] = useState([]);
  const [name, setName] = useState("");

  const [oid, setOid] = useState(getParam("oid"));
  const [osecret, setOsecret] = useState(
    getParam("payment_intent_client_secret")
  );
  const [osuccess, setOrderSuccess] = useState(getParam("orderSucc"));

  const [viewTickets, setViewTickets] = useState([]);

  const seatchartRef = useRef(null);
  const seatbox = useRef(null);

  const arreee = [0, 1, 2, 3, 4, 5, 6];

  const fetchStripeClient = async (total, curr) => {
    setClientSecret(await createPayment(total, curr));
  };

  const fetchOrderTickets = async (oid) => {
    let tickets = await fetchTickets(oid, osecret);
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    tickets.forEach((item, i) => {
      let dstr = item.event.startDate.split("T")[0].split("-");
      let etime = item.event.startDate.split("T")[1];
      let ndstr = dstr[2] + "-" + dstr[1] + "-" + dstr[0];
      let odate = new Date(ndstr);
      let dateWeekDay = weekday[odate.getDay()];
      tickets[i].date = { date: ndstr, day: dateWeekDay, etime };
    });

    setViewTickets(tickets);
  };

  let confirmOrder = async () => {
    let orderId = searchParams.get("oid");
    let status = searchParams.get("orderSucc");
    let secret = searchParams.get("payment_intent_client_secret");
    if (orderId && status && secret) {
      setOid(orderId);
      await confirm(orderId, secret).then(async (r) => {
        fetchEvent();
        if (r?._id) {
          setOrder({
            invoice: false,
            country: "",
            city: "",
            zip: "",
            address1: "",
            address2: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            cardName: "",
            cardNumber: "",
            cardDate: "",
            cardCVC: "",
          });
          // window.location.href = '/seller?payment_intent_client_secret='+r.securityKey+'&oid='+r._id;
        }
      });
    }
  };

  const cookiesConfig = {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE,
    path: process.env.REACT_APP_BASE_URL,
  };

  const clearCounterData = async (counter) => {
    await cookies.remove("counterFirstName", cookiesConfig);
    await cookies.remove("counterRole", cookiesConfig);
    await cookies.remove("counterEmail", cookiesConfig);
    await localStorage.removeItem("counter");
  };

  const logout = async () => {
    await clearCounterData().then(() => {
      navigate("/login");
    });
  };

  const fetchEvent = async () => {
    let ev = await getByCounter();

    let standartQuantities = {};
    if (ev?.plan?.standartSeatTypes?.length > 0) {
      ev.plan.standartSeatTypes.map((it, ind) => {
        standartQuantities[it.types] = 0;
      });
      setStandart(standartQuantities);
    }
    setEvent(ev);

    let title = await cookies.load("counterFirstName", cookiesConfig);
    title += " (";
    title += await cookies.load("counterEmail", cookiesConfig);
    title += ")";
    setName(title);
  };

  const submitOrder = async (paymentSecret) => {
    setIsLoading(true);
    let tickets = [];
    let _cart = null;
    let allow = false;
    if (!!seatchartRef.current) {
      let cart = seatchartRef.current?.store.cart;
      cart.forEach((item, i) => {
        tickets.push({
          event: thisEvent._id,
          type: item.type,
          row: item.index.row + 1,
          seat: item.index.col + 1,
          trueSeat:
            getTruePlace(thisEvent.plan, item.index.row, item.index.col) + 1,
          trueLabel:
            item.label.substring(0, 1) +
            (getTruePlace(thisEvent.plan, item.index.row, item.index.col) + 1),
          label: item.label,
        });
      });
      _cart = tickets;
    } else {
      Object.keys(standart).map((it, ind) => {
        let thistype = thisEvent.plan.standartSeatTypes.find(
          (fit) => fit.types === it
        );
        let am = standart[it];
        while (am > 0) {
          tickets.push({
            event: thisEvent._id,
            type: thistype.types,
            label: thistype.label,
          });
          am--;
        }
      });
      _cart = tickets;
    }

    if (_cart.length == 0) {
      toast("Önce koltuk seçmeniz lazım.");
      setIsLoading(false);
    } else {
      let newOrder = {
        cart: _cart,
        customer: {
          invoice: order.invoice,
          country: order.country,
          city: order.city,
          zip: order.zip,
          address1: order.address1,
          address2: order.address2,
          firstName: order.firstName,
          lastName: order.lastName,
          email: order.email,
          phoneNumber: order.phoneNumber,
          secret: paymentSecret,
          payment: order.payment,
        },
      };
      await make(newOrder).then((r) => {
        if (r) {
          allow = r;
          setSoldTickets(r.tickets);
          console.log(r);
        }
      });
      return allow;
    }
  };

  const generatePassword = (len = 6) => {
    var charset = "abcdefghijklmnopqrstuvwxyz0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < len; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const cashOrder = async () => {
    let secret = generatePassword(10);
    await submitOrder(secret).then(async (r) => {
      if (r) {
        window.location.href =
          "/seller?rnd=" +
          generatePassword(3) +
          "&orderSucc=1&oid=" +
          r._id +
          "&payment_intent_client_secret=" +
          secret;
        // navigate('?rnd='+generatePassword(3)+'&orderSucc=1&oid='+r._id+"&payment_intent_client_secret="+secret);
      }
      // await confirm(r,secret).then((e) =>   {
      //   if(e){
      //     navigate('?payment_intent_client_secret='+e.securityKey+'&oid='+e._id);
      //   }else toast.error(t('error_try_again'));
      //
      //   console.log(e);
      // });
      setIsLoading(false);
    });
  };

  const getTruePlace = (plan, row, col) => {
    let { rows, colums, disabledSeats } = plan.map;
    // row = 1;
    //col = 14
    let disabledHere = disabledSeats.filter((it) => it.row == row);
    let disabledBefore = disabledHere.filter((it) => it.col < col);
    let disabledAmount = disabledBefore.length;
    let trueDisabled = col - disabledAmount;
    return trueDisabled;
  };

  const cartControl = async () => {
    let cart = [];
    if (thisEvent?._id) {
      if (thisEvent.plan.isSimple) {
        Object.keys(standart).map((it, ind) => {
          let thistype = thisEvent.plan.standartSeatTypes.find(
            (fit) => fit.types === it
          );
          let am = standart[it];
          while (am > 0) {
            cart.push({
              event: thisEvent._id,
              type: thistype.types,
              label: thistype.label,
            });
            am--;
          }
        });
      } else {
        setClientSecret(null);
        seatchartRef.current.store.cart.forEach((item, i) => {
          cart.push({
            event: thisEvent._id,
            type: item.type,
            row: item.index.row + 1,
            seat: item.index.col + 1,
            trueSeat:
              getTruePlace(thisEvent.plan, item.index.row, item.index.col) + 1,
            trueLabel:
              item.label.substring(0, 1) +
              (getTruePlace(thisEvent.plan, item.index.row, item.index.col) +
                1),
            label: item.label,
          });
        });
      }
      if (cart.length)
        await calculate(cart).then((oTotal) => {
          if (oTotal) {
            // setTimeout(() => {
            fetchStripeClient(oTotal, thisEvent.currency.code.toLowerCase());
            // },100);
          }
        });
    }
  };

  useEffect(() => {
    fetchOrderTickets(oid);
  }, [oid]);

  useEffect(() => {
    cartControl();
  }, [standart]);

  useEffect(() => {
    confirmOrder();
  }, [window.location.search]);

  useEffect(() => {
    fetchEvent();
  }, [window.location]);

  useEffect(() => {
    if (!counter) navigate("/login");
  }, [counter]);

  useEffect(() => {
    if (seatchartRef.current?.store)
      seatbox.current.addEventListener("click", () => {
        console.log("chaaged");
        cartControl();
      });
  }, [thisEvent?._id]);

  const RepeatedRow = ({ left, right }) => (
    <Stack display="flex" direction="row">
      <Box flex={1}>
        <Typography fontWeight="bold" fontSize={10}>
          {left}
        </Typography>
      </Box>
      <Box flex={2}>
        <Typography fontWeight="bold" fontSize={10}>
          {right}
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <div className="hotSalePage">
      <NavBar color="#ffb658" />
      <Modal
        open={viewTickets.length}
        onClose={() => {
          setViewTickets([]);
        }}
      >
        <div className="ticketsModal">
          <h5
            onClick={() => {
              window.location.href = "/seller";
            }}
            style={{ cursor: "pointer", color: "#df0c62" }}
            className="text-end me-2 "
          >
            Kapat
          </h5>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            color="#df0c62"
            sx={{ textDecoration: "underline", margin: "auto" }}
          >
            Success
          </Typography>
          <div className="container">
            {viewTickets.map((item, index) => (
              <Grid item xs={12} sx={{ marginTop: "30px" }}>
                <a
                  style={{
                    color: "#df0c62",
                    display: "block",
                    fontSize: "20px",
                    fontWeight: 800,
                  }}
                  target="_blank"
                  href={CDN_URL + "pdfs/" + item._id + ".pdf"}
                >
                  Indir
                </a>
                <Box className={"celebTicket"}>
                  <QRCode value={BASE_URL + "/scanner?tid=" + item?._id} />
                  <Stack
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="start"
                    height="100%"
                    padding="0 25%"
                  >
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      textAlign="center"
                      color="#df0c62"
                      sx={{ textDecoration: "underline" }}
                    >
                      {item.event.title}
                    </Typography>

                    <Typography color="#df0c62">
                      {item.event.location?.name}
                    </Typography>
                    <br />
                    <Typography color="#df0c62">
                      {item.customer.firstName + " " + item.customer.lastName}
                    </Typography>
                    <Typography variant="h6" color="#df0c62" fontWeight="bold">
                      {item.event.plan.startsFromOne
                        ? item.trueLabel
                        : item.label}
                    </Typography>
                    <br />

                    <Typography color="#df0c62">Door opens at:</Typography>
                    <Typography color="#df0c62">
                      {item.event.doorOpenDate.split("T")[1]}
                    </Typography>
                    <br />
                    <img src={logo} alt="logo" width="30%" />
                  </Stack>
                </Box>
              </Grid>
            ))}
          </div>
        </div>
      </Modal>
      <Box
        sx={{
          backgroundColor: "#fff0d7",
          boxShadow: "inset 0px 1px 10px 0px #fec982",
          height: { xs: "250px", sm: "300px" },
          paddingTop: { xs: "7%", sm: "4%" },
        }}
      >
        <Typography
          onClick={() => {
            logout();
          }}
          style={{
            position: "absolute",
            marginLeft: "auto",
            left: "15px",
            top: "15px",
          }}
          color="#df0c62"
          variant="h8"
        >
          {name}
        </Typography>

        <Typography
          onClick={() => {
            logout();
          }}
          style={{
            cursor: "pointer",
            position: "absolute",
            marginLeft: "auto",
            right: "15px",
            top: "15px",
          }}
          color="#df0c62"
          variant="h6"
          fontWeight="bold"
        >
          Çıkış
        </Typography>
        {thisEvent ? (
          <Container maxWidth="md" sx={{ height: "100%" }}>
            <Stack display="flex" direction="row" sx={{ height: "100%" }}>
              {/* Event Poster */}
              <Box flex={1} sx={{ display: { xs: "none", sm: "block" } }}>
                <img
                  src={CDN_URL + thisEvent.image}
                  alt="Event Poster"
                  className={classes.eventPoster}
                />
              </Box>
              <Box
                flex={1}
                sx={{
                  display: { xs: "block", sm: "none" },
                  position: "relative",
                  top: 50,
                }}
              >
                <img
                  src={CDN_URL + thisEvent.image}
                  alt="Event Poster"
                  className={classes.eventPoster}
                />
              </Box>
              {/* Event Details */}

              <Box flex={2} className={classes.eventDetails}>
                <Stack display="flex" direction="column">
                  <Typography
                    variant="h4"
                    color="#df0c62"
                    className={classes.titleStroke}
                  >
                    {thisEvent.title}
                  </Typography>
                  <Stack display="flex" direction="row">
                    <Box flex={1}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="#df0c62"
                      >
                        {thisEvent.location?.name}
                      </Typography>
                      <Typography paragraph color="#df0c62">
                        {thisEvent?.startDate}
                      </Typography>
                    </Box>
                  </Stack>
                  <Box className={classes.redDash}></Box>
                  {/* Event Type */}
                  <Typography paragraph color="#df0c62">
                    {thisEvent?.type}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Container>
        ) : null}
        {/* End of Top Container */}
      </Box>
      <Container maxWidth="xl" sx={{ paddingBottom: 5 }}>
        {/* Parent Stack */}
        <Stack display="flex" direction="column" marginTop={20} spacing={4}>
          <Stack display="flex" direction="row">
            <Typography variant="h6" color="#df0c62" fontWeight="bold">
              Koltuk Seç
            </Typography>
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd289" }}></Box>
          </Stack>

          {thisEvent && thisEvent.plan ? (
            <>
              {thisEvent.plan.isSimple ? (
                <div style={{ margin: "50px 0", color: "#df0b62" }}>
                  {thisEvent.plan.standartSeatTypes.map((variant, index) => (
                    <div
                      style={{
                        margin: "10px 0",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <p style={{ width: "100px", marginBottom: 0 }}>
                        {variant.label}
                      </p>
                      <p style={{ width: "100px", marginBottom: 0 }}>
                        {thisEvent.currency.symbol + "" + variant.price}
                      </p>

                      <div class="input-group" style={{ width: "200px" }}>
                        <div class="input-group-prepend">
                          <button
                            onClick={() => {
                              let st = { ...standart };
                              if (st[variant.types] > 0)
                                st[variant.types] = st[variant.types] - 1;
                              else st[variant.types] = 0;
                              setStandart(st);
                            }}
                            class="quantityBut btn btn-outline-secondary"
                            type="button"
                          >
                            -
                          </button>
                        </div>
                        <input
                          value={standart[variant.types]}
                          onChange={(e) => {
                            let st = { ...standart };
                            st[variant.types] = e.target.value;
                            setStandart(st);
                          }}
                          style={{
                            width: "100px",
                            margin: "0 10px",
                            color: "#df0b62",
                            border: "1px solid #ffd283",
                          }}
                        />
                        <div class="input-group-append">
                          <button
                            onClick={() => {
                              let st = { ...standart };
                              st[variant.types] = st[variant.types] + 1;
                              setStandart(st);
                            }}
                            class="quantityBut btn btn-outline-secondary"
                            type="button"
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <p style={{ marginBottom: 0 }}>
                        Kalan Miktar:{" "}
                        {variant.amount -
                          variant.sold -
                          standart[variant.types]}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div ref={seatbox}>
                  <Seatchart ref={seatchartRef} options={thisEvent.plan} />
                </div>
              )}
            </>
          ) : null}

          <div>
            <Stack display="flex" direction="row" alignItems="center">
              <Typography
                variant="h6"
                color="#df0c62"
                fontWeight="bold"
                paragraph
              >
                Alıcı Bilgileri
              </Typography>
              <Box flex={1} sx={{ borderBottom: "2px solid #ffd283" }}></Box>
            </Stack>
            <Stack
              sx={{ marginTop: "15px" }}
              display="flex"
              direction="column"
              spacing={2}
            >
              <Stack display="flex" direction="row" spacing={1}>
                <Box flex={1}>
                  <Typography color="#df0c62" fontWeight="bold">
                    İsim *
                  </Typography>
                  <input
                    value={order.firstName}
                    onChange={(e) =>
                      setOrder({ ...order, firstName: e.target.value })
                    }
                    style={{
                      width: "100%",
                      border: "2px solid #df0c63",
                      outline: "none",
                    }}
                    name="name"
                  />
                </Box>
                <Box flex={1}>
                  <Typography color="#df0c62" fontWeight="bold">
                    Soyisim *
                  </Typography>
                  <input
                    value={order.lastName}
                    onChange={(e) =>
                      setOrder({ ...order, lastName: e.target.value })
                    }
                    style={{
                      width: "100%",
                      border: "2px solid #df0c63",
                      outline: "none",
                    }}
                    name="lastName"
                  />
                </Box>
              </Stack>
              <Box flex={1}>
                <Typography color="#df0c62" fontWeight="bold">
                  E-mail *
                </Typography>
                <input
                  value={order.email}
                  onChange={(e) =>
                    setOrder({ ...order, email: e.target.value })
                  }
                  style={{
                    width: "100%",
                    border: "2px solid #df0c63",
                    outline: "none",
                  }}
                />
              </Box>
              <Box flex={1}>
                <Typography color="#df0c62" fontWeight="bold">
                  Telefon *
                </Typography>
                <input
                  value={order.phoneNumber}
                  onChange={(e) =>
                    setOrder({ ...order, phoneNumber: e.target.value })
                  }
                  style={{
                    width: "100%",
                    border: "2px solid #df0c63",
                    outline: "none",
                  }}
                  name="id"
                />
              </Box>

              {/* End of Accordion Stack */}
            </Stack>
          </div>

          <Accordion
            expanded={order.invoice}
            elevation="0"
            sx={{ "&:before": { display: "none" } }}
          >
            <AccordionSummary
              expandIcon={<></>}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack display="flex" direction="row" alignItems="center">
                <Typography color="#df0c62" variant="h6" fontWeight="bold">
                  Fatura İsteniliyor
                </Typography>
                <Checkbox
                  checked={order.invoice}
                  onChange={(e) =>
                    setOrder({ ...order, invoice: e.target.checked })
                  }
                  color="error"
                />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      Ülke *
                    </Typography>
                    <input
                      value={order.country}
                      onChange={(e) =>
                        setOrder({ ...order, country: e.target.value })
                      }
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="country"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      Şehir *
                    </Typography>
                    <input
                      value={order.city}
                      onChange={(e) =>
                        setOrder({ ...order, city: e.target.value })
                      }
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="city"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      Posta Kodu *
                    </Typography>
                    <input
                      value={order.zip}
                      onChange={(e) =>
                        setOrder({ ...order, zip: e.target.value })
                      }
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="country"
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      Adres
                    </Typography>
                    <input
                      value={order.address1}
                      onChange={(e) =>
                        setOrder({ ...order, address1: e.target.value })
                      }
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="adres1"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Typography color="#df0c62" fontWeight="bold">
                      Adres devamı
                    </Typography>
                    <input
                      value={order.address2}
                      onChange={(e) =>
                        setOrder({ ...order, address2: e.target.value })
                      }
                      style={{
                        width: "100%",
                        border: "2px solid #df0c63",
                        outline: "none",
                      }}
                      name="adres2"
                    />
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Payment Section */}
          <Stack display="flex" direction="row" alignItems="center">
            <Typography
              variant="h6"
              color="#df0c62"
              fontWeight="bold"
              paragraph
            >
              Ödeme
            </Typography>
            <Box flex={1} sx={{ borderBottom: "2px solid #ffd283" }}></Box>
          </Stack>
          <Accordion
            sx={{ border: "none", "&:before": { display: "none" } }}
            elevation={0}
            onChange={(e) => {
              setOrder({
                ...order,
                payment: e.target.classList.contains("Mui-expanded")
                  ? "card"
                  : "cash",
              });
              console.log(e.target.getAttribute("aria-expanded"));
            }}
            expanded={order.payment == "card"}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore fontSize="large" sx={{ color: "#df0c62" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack display="flex" direction="row" alignItems="center">
                <Typography
                  sx={{ padding: 0 }}
                  color="#df0c62"
                  variant="h6"
                  fontWeight="bold"
                >
                  Kredi Kartı:
                </Typography>
                <Checkbox
                  checked={order.payment == "card"}
                  onChange={(e) =>
                    setOrder({
                      ...order,
                      payment: e.target.value ? "card" : "cash",
                    })
                  }
                  color="error"
                />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="#df0c62">*Fiyatlarda KDV dahildir.</Typography>
              <Box flex={1}>
                {clientSecret && stripePromise && (
                  <Elements
                    stripe={stripePromise}
                    options={{ clientSecret, appearance }}
                  >
                    <CheckoutForm
                      hotSale={true}
                      secret={clientSecret}
                      submitOrder={submitOrder}
                    />
                  </Elements>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={(e) => {
              setOrder({
                ...order,
                payment: e.target.classList.contains("Mui-expanded")
                  ? "cash"
                  : "card",
              });
              console.log(e);
            }}
            expanded={order.payment == "cash"}
            sx={{ border: "none", "&:before": { display: "none" } }}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore fontSize="large" sx={{ color: "#df0c62" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack display="flex" direction="row" alignItems="center">
                <Typography color="#df0c62" variant="h6" fontWeight="bold">
                  Nakit:
                </Typography>
                <Checkbox
                  checked={order.payment == "cash"}
                  onChange={(e) =>
                    setOrder({
                      ...order,
                      payment: e.target.value ? "cash" : "card",
                    })
                  }
                  color="error"
                />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ margin: "20px 0" }}>
                <Typography variant="h6" fontWeight="bold" color="#df0c62">
                  {t("Legal Notices")}:
                </Typography>

                {/* First Box */}
                <Box sx={{ borderBottom: "2px solid #ffd589" }}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Checkbox
                      value={checked.first}
                      onChange={(e) => {
                        setChecked({ ...checked, first: e.target.checked });
                      }}
                      style={{ color: "#df0c62" }}
                    />
                    <Link
                      target="_blank"
                      to="/page/gizlilik-politikasi"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h7"
                        color="#df0c62"
                        sx={{ "&:hover": { fontWeight: "bold" } }}
                      >
                        {t("Privacy Policy")}
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
                {/* Second Box */}
                <Box sx={{ borderBottom: "2px solid #ffd589" }}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Checkbox
                      value={checked.second}
                      onChange={(e) => {
                        setChecked({ ...checked, second: e.target.checked });
                      }}
                      style={{ color: "#df0c62" }}
                    />
                    <Link
                      target="_blank"
                      to="/page/mesafeli-satis-sözlesmesi"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h7"
                        color="#df0c62"
                        sx={{ "&:hover": { fontWeight: "bold" } }}
                      >
                        {t("DISTANCE SELLING CONTRACT")}
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
                {/* Third Box */}
                <Box sx={{ borderBottom: "2px solid #ffd589" }}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Checkbox
                      value={checked.third}
                      onChange={(e) => {
                        setChecked({ ...checked, third: e.target.checked });
                      }}
                      style={{ color: "#df0c62" }}
                    />
                    <Link
                      target="_blank"
                      to="/page/iptal-ve-iade-politikasi"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h7"
                        color="#df0c62"
                        sx={{ "&:hover": { fontWeight: "bold" } }}
                      >
                        {t("Return Policy")}
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
              </div>
              {/* End Of Child Accordion Stack */}
              <Box display="flex" justifyContent="center">
                <Button
                  id="submit"
                  type="button"
                  onClick={cashOrder}
                  variant="contained"
                  sx={{
                    backgroundColor: "#df0c62",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                  disabled={isLoading || !checked.first || !checked.second}
                >
                  {isLoading ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "Satışı Tamamla"
                  )}
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            onChange={(e) => {
              setOrder({
                ...order,
                payment: e.target.classList.contains("Mui-expanded")
                  ? "guest"
                  : "card",
              });
              console.log(e);
            }}
            expanded={order.payment == "guest"}
            sx={{ border: "none", "&:before": { display: "none" } }}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore fontSize="large" sx={{ color: "#df0c62" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack display="flex" direction="row" alignItems="center">
                <Typography color="#df0c62" variant="h6" fontWeight="bold">
                  Misafir:
                </Typography>
                <Checkbox
                  checked={order.payment == "guest"}
                  onChange={(e) =>
                    setOrder({
                      ...order,
                      payment: e.target.value ? "guest" : "card",
                    })
                  }
                  color="error"
                />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ margin: "20px 0" }}>
                <Typography variant="h6" fontWeight="bold" color="#df0c62">
                  {t("Legal Notices")}:
                </Typography>

                {/* First Box */}
                <Box sx={{ borderBottom: "2px solid #ffd589" }}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Checkbox
                      value={checked.first}
                      onChange={(e) => {
                        setChecked({ ...checked, first: e.target.checked });
                      }}
                      style={{ color: "#df0c62" }}
                    />
                    <Link
                      target="_blank"
                      to="/page/gizlilik-politikasi"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h7"
                        color="#df0c62"
                        sx={{ "&:hover": { fontWeight: "bold" } }}
                      >
                        {t("Privacy Policy")}
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
                {/* Second Box */}
                <Box sx={{ borderBottom: "2px solid #ffd589" }}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Checkbox
                      value={checked.second}
                      onChange={(e) => {
                        setChecked({ ...checked, second: e.target.checked });
                      }}
                      style={{ color: "#df0c62" }}
                    />
                    <Link
                      target="_blank"
                      to="/page/mesafeli-satis-sözlesmesi"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h7"
                        color="#df0c62"
                        sx={{ "&:hover": { fontWeight: "bold" } }}
                      >
                        {t("DISTANCE SELLING CONTRACT")}
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
                {/* Third Box */}
                <Box sx={{ borderBottom: "2px solid #ffd589" }}>
                  <Stack display="flex" direction="row" alignItems="center">
                    <Checkbox
                      value={checked.third}
                      onChange={(e) => {
                        setChecked({ ...checked, third: e.target.checked });
                      }}
                      style={{ color: "#df0c62" }}
                    />
                    <Link
                      target="_blank"
                      to="/page/iptal-ve-iade-politikasi"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="h7"
                        color="#df0c62"
                        sx={{ "&:hover": { fontWeight: "bold" } }}
                      >
                        {t("Return Policy")}
                      </Typography>
                    </Link>
                  </Stack>
                </Box>
              </div>
              {/* End Of Child Accordion Stack */}
              <Box display="flex" justifyContent="center">
                <Button
                  id="submit"
                  type="button"
                  onClick={cashOrder}
                  variant="contained"
                  sx={{
                    backgroundColor: "#df0c62",
                    "&:hover": { backgroundColor: "#df0c62" },
                  }}
                  disabled={isLoading || !checked.first || !checked.second}
                >
                  {isLoading ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "Satışı Tamamla"
                  )}
                </Button>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Page End Logo */}
          <Stack display="flex" direction="row">
            <img src={logo} alt="logo" width="22%" />
            <Box flex={1} sx={{ borderBottom: "2px solid #df0c62" }}></Box>
          </Stack>
          {/* End Of Parent Stack */}
        </Stack>
      </Container>
    </div>
  );
};

export default HotSale;
