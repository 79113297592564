import React, { useState, useEffect } from "react";
import logo from "../../Assets/Images/logo.png";


const Loading = () => {

  const back = {
    position: 'fixed',
    top: 0,
    outline: 0,
    left: 0,
    right: 0,
    maxHeight: '100%',
    height: '100vh',
    backgroundColor: 'rgba(255,210,131,1)',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    zIndex: 10000
  };

  return(
    <div style={back}>
      <img height="50px" width="auto" src={logo} />
    </div>
  );
}

export default Loading;
