import {
  AppBar,
  Badge,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
  Zoom,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import logo from "../../Assets/Images/logo.png";
import cookies from "react-cookies";
import {
  IoTicketSharp,
  IoPersonSharp,
  IoSearch,
  IoMenu,
  IoClose,
} from "react-icons/io5";
import {toast} from 'react-toastify';
import { Close, Public, LanguageIcon } from "@mui/icons-material";
import { SocialIcon } from "react-social-icons";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { useNavigate } from "react-router-dom";
import style from "./appbar.module.css";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../Services/systemApi";

import { getReservation,clearReservation } from "../../Data/reservation";
import { tryCustomer } from "../../Data/customer";

const TopBar = ({ color }) => {
  // let languageStored = localStorage.getItem("e-ticket-al language");
  // let currencyStored = localStorage.getItem("e-ticket-al currency");

  const [cartCounter, setCartCounter] = useState(0);
  const [isLogged, setIsLogged] = useState(false);

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem('e-ticket-al language'));
  const [currency, setCurrency] = useState(localStorage.getItem('e-ticket-al currency'));
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    changeLanguage(lang);
  };

  const customStyle = {
    height: "100vh",
    backgroundColor: "rgba(255,210,131,0.9)",
  };

  const cookiesConfig = {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE,
    path: process.env.REACT_APP_BASE_URL,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openMenu2 = Boolean(anchorEl2);

  const [searchTimer, setSearchTimer] = useState(null);
  const [resControlTimeout,setResControlTimeout] = useState(null);
  const [_accessTimer,setAccessTimer] = useState(null);

  const settingLocale = async (type, value) => {
    if (type == 'lang') {
      await localStorage.setItem("e-ticket-al language", value);
      await onChangeLanguage(language?.toLocaleLowerCase());
    } else {
      await localStorage.setItem("e-ticket-al currency", value);
    }
  }

  const changeLocale = async (type, value) => {
    await settingLocale(type, value).then(() => {
      setTimeout(() => { window.location.reload(); }, 0);
    });
  }

  useEffect(() => {
    i18n.changeLanguage(language?.toLocaleLowerCase());
    changeLanguage(language?.toLocaleLowerCase())
  }, [language]);

  useEffect(() => {
    if (query.length > 0) searching()
  }, [query])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const loginControl = async() => {
    let _logged = await localStorage.getItem('local');
    if(_logged) setIsLogged(true);
    else setIsLogged(false);

    // const profile = await tryCustomer();
    // if (profile) setIsLogged(true);
    // else{
    //   setIsLogged(false);
    //   clearCustomerData();
    // }
  };

  const clearCart = async () => {
    await clearReservation(await localStorage.getItem('resId'));
    await localStorage.removeItem('resExTime');
    await localStorage.removeItem('resId');
    await localStorage.removeItem('cart');
    toast.warning(t('reservation_expired'));
    window.dispatchEvent(new Event("storage"));
    if(window.location.pathname == '/buyTicket') navigate('/');
  }

  const reservationControl = async() => {
    // console.log('desk control');

    if(await localStorage.getItem('resExTime')){

      let resTimeout = setTimeout(() => {
        reservationControl();
      },3000);

      setResControlTimeout(resTimeout);
      let expire = await localStorage.getItem('resExTime');
      let current = new Date().getTime();
      if(current > expire) clearCart();

    }
  }

  const cartControl = async() => {

    // let access = await localStorage.getItem('access');
    // if(access && access == 'denied'){
    //   if(_accessTimer) clearTimeout(_accessTimer)
    //   let accessTimer = setTimeout(async() => {
    //     await localStorage.removeItem('access');
    //     window.location.href = '/login';
    //   },3000);
    //   setAccessTimer(accessTimer);
    // }

     let _res = await localStorage.getItem('resId');
     if(_res) await getReservation(_res).then(async(r) => {
      if(r){
        let thisdate = new Date();
        let reservationdate = new Date(r.created);

        let thistime = thisdate.getTime();
        let reservationtime = reservationdate.getTime();
        let expiretime = reservationtime + (1000 * 60 * 5);
        let expireDate = new Date(expiretime);
        if(expiretime > thistime){ // VALID RES


          await localStorage.setItem('resExTime',expiretime);
          if(resControlTimeout) clearTimeout(resControlTimeout)
          reservationControl();

          let _cart = await localStorage.getItem('cart');

          if (_cart) {
            let cart = JSON.parse(_cart);
            if (cart?.length) {
              setCartCounter(cart.length);
            }
          }else{
            setCartCounter(0);
          }
        }else{ //Expired Res
          clearReservation(_res);
          await localStorage.removeItem('resExTime');
          await localStorage.removeItem('resId');
          await localStorage.removeItem('cart');
          setTimeout(() => {window.dispatchEvent(new Event("storage"));},200);
          if(window.location.pathname == '/buyTicket') navigate('/');
        }

      }else{ //No Res
        await localStorage.removeItem('resExTime');
        await localStorage.removeItem('resId');
        await localStorage.removeItem('cart');
        setTimeout(() => {window.dispatchEvent(new Event("storage"));},200);
        if(window.location.pathname == '/buyTicket') navigate('/');
      }

    });


  };

  const clearCustomerData = async () => {
    await cookies.remove("customerFirstName", cookiesConfig);
    await cookies.remove("customerLastName", cookiesConfig);
    await cookies.remove("customerEmail", cookiesConfig);
    await cookies.remove("customerPhoneNumber", cookiesConfig);
    await cookies.remove("customerAddress", cookiesConfig);
    await localStorage.removeItem("local");
  };

  const logout = async () => {
    await clearCustomerData().then(() => {
      navigate("/");
    });
  };

  useEffect(() => {
    loginControl();
    cartControl();
    window.addEventListener("storage", async () => {
      console.log('storage updated');
      loginControl();
      cartControl();
    });
  }, []);

  const searching = async () => {
    if (searchTimer) clearTimeout(searchTimer);
    let timer = setTimeout(() => {
      navigate(`/events?search=${query}`)
    }, 1500);
    setSearchTimer(timer);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: color }}>
        <Box
          sx={{ display: show ? "block" : "none", backgroundColor: "#fcf2e2" }}
        >
          <Zoom in={show} timeout={250}>
            <Container maxWidth="md" sx={{ backgroundColor: "#fff0d7" }}>
              <Stack
                display="flex"
                direction="row"
                alignItems="center"
                sx={{ padding: "16px  64px" }}
              >
                <input
                  className={style.search}
                  type="text"
                  placeholder={t("Search Event")}
                  style={{ backgroundColor: "#fcf2e2", border: "none" }}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Typography
                  color="black"
                  fontWeight="bold"
                  className="text-dark me-3"
                  onClick={() => navigate(`/events?search=${query}`)}
                >
                  {t("Search")}
                </Typography>
                <Box sx={{ borderRight: "2px dashed black" }}>
                  <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, ml: 2 }}
                  >
                    <IoSearch color="#000" />
                  </IconButton>
                </Box>

                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ ml: 2 }}
                  onClick={() => setShow(!show)}
                >
                  <IoClose color="#000" />
                </IconButton>
              </Stack>
            </Container>
          </Zoom>
        </Box>
        <Container maxWidth="md">
          <Toolbar disableGutters={true}>
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={0}
              >
                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setOpen(!open)}
                >
                  <IoMenu color="#e32366" />
                </IconButton>
                <SwipeableDrawer
                  anchor="top"
                  open={open}
                  PaperProps={{ sx: { ...customStyle } }}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                >
                  <Stack
                    display="flex"
                    direction="column"
                    height="100vh"
                    width="100vw"
                    alignItems="center"
                    justifyContent="center"
                    spacing={12}
                  >
                    <Container maxWidth="md">
                      <IconButton size="large" onClick={() => setOpen(!open)}>
                        <Close sx={{ color: "black", fontSize: "3rem" }} />
                      </IconButton>
                    </Container>
                    {/* Logo */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={logo} alt="logo" width={"60%"} />
                    </Box>

                    {/* Pages */}
                    <Stack
                      display="flex"
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing={4}
                      onClick={() => setOpen(!open)}
                    >
                      <Typography
                        className={style.textStyle}
                        variant="h4"
                        color="white"
                        fontWeight={900}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/")}
                      >
                        {t("HOME")}
                      </Typography>
                      <Typography
                        className={style.textStyle}
                        variant="h4"
                        color="white"
                        fontWeight={900}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/events")}
                      >
                        {t("EVENTS")}
                      </Typography>

                      {/* CIKIS */}
                      <Typography
                        className={style.textStyle}
                        variant="h4"
                        color="white"
                        fontWeight={900}
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpen(!open)}
                      >
                      {t("CLOSE")}
                      </Typography>
                    </Stack>
                    {/* Social icons */}
                    <Zoom in={open} timeout={1000}>
                      <Stack display="flex" direction="row">
                        <IconButton>
                          <SocialIcon network="facebook" bgColor="black" />
                        </IconButton>
                        <IconButton>
                          <SocialIcon network="twitter" bgColor="black" />
                        </IconButton>
                        <IconButton>
                          <SocialIcon network="instagram" bgColor="black" />
                        </IconButton>
                        <IconButton>
                          <SocialIcon network="youtube" bgColor="black" />
                        </IconButton>
                      </Stack>
                    </Zoom>
                  </Stack>
                </SwipeableDrawer>

                <div>
                  <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    aria-controls={
                      openMenu ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <Badge badgeContent={language} color="error">
                      <Public sx={{ color: "#df0c62" }} />
                    </Badge>
                  </IconButton>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: { backgroundColor: "#fff0d7" },
                    }}
                  >
                    <MenuItem
                      onClick={handleClose}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#ffd283" },
                        borderBottom: "2px solid #df0c62",
                      }}
                    >
                      <Stack
                        display="flex"
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Public sx={{ color: "#df0c62" }} />

                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          color="#df0c62"
                        >
                          {language}
                        </Typography>
                      </Stack>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose();
                        changeLocale('lang', 'TR');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        TR
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        changeLocale('lang', 'EN');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        EN
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        changeLocale('lang', 'DE');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        DE
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        changeLocale('lang', 'RU');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        RU
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>

                <div style={{ display: 'none' }}>
                  <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    aria-controls={
                      openMenu2 ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openMenu2 ? "true" : undefined}
                    onClick={handleClick2}
                  >
                    <Badge badgeContent={currency} color="error">
                      <CurrencyExchangeIcon sx={{ color: "#df0c62" }} />
                    </Badge>
                  </IconButton>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl2}
                    open={openMenu2}
                    onClose={handleClose2}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: { backgroundColor: "#fff0d7" },
                    }}
                  >
                    <MenuItem
                      onClick={handleClose2}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#ffd283" },
                        borderBottom: "2px solid #df0c62",
                      }}
                    >
                      <Stack
                        display="flex"
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Public sx={{ color: "#df0c62" }} />

                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          color="#df0c62"
                        >
                          {currency}
                        </Typography>
                      </Stack>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        handleClose2();
                        changeLocale('curr', '₺');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        ₺
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose2();
                        changeLocale('curr', '$');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        $
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose2();
                        changeLocale('curr', 'C$');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        C$
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose2();
                        changeLocale('curr', '€');
                      }}
                      sx={{
                        backgroundColor: "#fff0d7",
                        "&:hover": { backgroundColor: "#df0c62" },
                      }}
                    >
                      <Typography textAlign="center" color="#feb659">
                        €
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </Box>
              <Box
                flex={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => navigate("/")}
              >
                <img src={logo} alt="logo" width="50%" />
              </Box>
              <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={0}
              >
                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setShow(!show)}
                >
                  <IoSearch color="#e32366" />
                </IconButton>
                <Link to={isLogged ? "/profile" : "/login"}>
                  <IconButton
                    size="medium"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => isLogged ? navigate("/profile") : navigate('/login')}
                  >
                    <IoPersonSharp color="#e32366" />
                  </IconButton>
                </Link>
                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    if (cartCounter) navigate("/buyTicket");
                  }}
                >
                  <Badge badgeContent={cartCounter} color="error">
                    <IoTicketSharp color="#e32366" />
                  </Badge>
                </IconButton>
              </Box>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default TopBar;
