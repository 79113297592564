import { dataGet, dataPost, dataFile } from "../Services/data";

export const saveImage = async (file, type = 'square') => {
  let content = "";
  await dataFile('general/saveImg', file, type).then((response) => {
    console.log(response)
    if (response.isSuccess) content = response.content;
  });
  return content;
}

export const saveImages = async (files, params, conf) => {
  let _files = [...files];
  let content = "";
  await dataFile('general/saveImg', _files, params, conf).then((response) => {
    if (response?.isSuccess) content = response.content;
  });
  return content;
}

export const listCurrencies = async () => {
  let currencies = [];
  await dataGet("currency/listAll").then((response) => {
    if (response && response.isSuccess) currencies = response.content;
  });
  return currencies;
};

export const getAllCountries = async () => {
  const countries = await fetch("https://restcountries.com/v3.1/all")
    .then((response) => response.json())
    .then((data) =>
      data.map((c) => {
        return { title: c.name.common };
      })
    )
    .catch((err) => console.log(err));
  return countries;
};
