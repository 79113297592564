import {
  Button,
  Checkbox,
  Stack,
  Typography,
  Badge,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import ticket from "../../Assets/Images/ticketBig.svg";
import logo from "../../Assets/Images/logo.png";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import cookies from "react-cookies";
import MobileLogin from "../../components/Login/Mobile";
import { Close, Public, LanguageIcon } from "@mui/icons-material";
import TabletLogin from "../../components/Login/Tablet";
import DesktopLogin from "../../components/Login/Desktop";
import { AiFillEye } from "react-icons/ai";
import classes from "./styles.module.css";
import {
  registerCustomer,
  loginCustomer,
  forgotCustomer,
  resetCustomer,
  uniLogin,
} from "../../Data/customer";

import { registerPromoter, loginPromoter } from "../../Data/promoter";
import say from "../../Services/language";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../Services/systemApi";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const Login = () => {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(
    localStorage.getItem("e-ticket-al language")
  );
  const resetId = searchParams.get("resetId");
  const cookiesConfig = {
    maxAge: process.env.REACT_APP_COOKIE_MAX_AGE,
    path: process.env.REACT_APP_BASE_URL,
  };

  const [formState, setFormState] = useState(resetId ? "reset" : "login");
  const [loginState, setLoginState] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [resetState, setResetState] = useState({
    password: "",
    r_password: "",
  });
  const [registerState, setRegisterState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    organizator: false,
    password: "",
    consent: false,
  });
  //
  // useEffect(() => {
  //   if(searchParams.resetId) setFormState("reset");
  //   console.log(searchParams);
  // },[searchParams]);

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    changeLanguage(lang);
  };
  // const [isDisabled, setIsDisabled] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openMenu2 = Boolean(anchorEl2);
  const settingLocale = async (type, value) => {
    if (type == "lang") {
      await localStorage.setItem("e-ticket-al language", value);
      await onChangeLanguage(language?.toLocaleLowerCase());
    } else {
      await localStorage.setItem("e-ticket-al currency", value);
    }
  };
  const changeLocale = async (type, value) => {
    await settingLocale(type, value).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 0);
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogin = async () => {
    setLoading(true);
    await uniLogin(loginState)
      .then(async (user) => {
        setLoading(false);
        if (user) {
          if (user.admin) {
            await setAdminData(user).then(() => {
              window.dispatchEvent(new Event("storage"));
              navigate("/admin", { replace: true });
            });
          } else if (user.promoter) {
            await setPromoterData(user).then(() => {
              window.dispatchEvent(new Event("storage"));
              navigate("/promoter", { replace: true });
            });
          } else if (user.counter) {
            await setCounterData(user).then(() => {
              window.dispatchEvent(new Event("storage"));
              if (user.role == "seller") navigate("/seller", { replace: true });
              else navigate("/", { replace: true });
            });
          } else {
            await setCustomerData(user).then(() => {
              window.dispatchEvent(new Event("storage"));
              navigate("/", { replace: true });
            });
          }
          toast.success(t("welcome_back") + user.firstName);
        } else toast.warning(t("error_try_again"));
      })
      .catch((e) => {
        window.alert("error" + e);
        setLoading(false);
      });
  };

  const onRegister = async () => {
    setLoading(true);
    await registerCustomer(registerState).then(async (_customer) => {
      setLoading(false);
      let customer = {};
      if (_customer) {
        customer = _customer;
        await setCustomerData(customer).then(() => {
          toast.success(t("welcome_back") + customer.firstName);
          window.dispatchEvent(new Event("storage"));
          navigate("/profile", { replace: true });
        });
      } else toast.warning(t("error_try_again"));
    });
  };

  const onJoinPromoter = async () => {
    setLoading(true);
    await registerPromoter(registerState).then(async (_promoter) => {
      setLoading(false);
      if (_promoter?.id) {
        setSubmitted(true);
        toast.success(t("account_created"));
      } else if (_promoter) {
        if (_promoter == "email") {
          toast.warning(t("email_is_used"));
        } else if (_promoter == "phone") {
          toast.warning(t("phone_is_used"));
        } else toast.warning(t("error_try_again"));
      } else toast.warning(t("error_try_again"));
    });
  };

  const setCustomerData = async (customer) => {
    await cookies.save("customerFirstName", customer.firstName, cookiesConfig);
    await cookies.save("customerLastName", customer.lastName, cookiesConfig);
    await cookies.save("customerEmail", customer.email, cookiesConfig);
    await cookies.save(
      "customerPhoneNumber",
      customer.phoneNumber,
      cookiesConfig
    );
    await cookies.save("customerAddress", customer.address, cookiesConfig);
    await localStorage.setItem("local", customer.token);
  };

  const setPromoterData = async (promoter) => {
    await cookies.save("promoterFirstName", promoter.firstName, cookiesConfig);
    await cookies.save("promoterLastName", promoter.lastName, cookiesConfig);
    await cookies.save("promoterEmail", promoter.email, cookiesConfig);
    await cookies.save(
      "promoterPhoneNumber",
      promoter.phoneNumber,
      cookiesConfig
    );
    await localStorage.setItem("promoter", promoter.token);
  };

  const setAdminData = async (admin) => {
    await cookies.save("adminFirstName", admin.firstName, cookiesConfig);
    await cookies.save("adminLastName", admin.lastName, cookiesConfig);
    await cookies.save("adminEmail", admin.email, cookiesConfig);
    await localStorage.setItem("admin", admin.token);
  };

  const setCounterData = async (counter) => {
    await cookies.save("counterFirstName", counter.firstName, cookiesConfig);
    await cookies.save("counterRole", counter.role, cookiesConfig);
    await cookies.save("counterEmail", counter.email, cookiesConfig);
    await localStorage.setItem("counter", counter.token);
  };

  const onForgot = async () => {
    await forgotCustomer(loginState.email).then((r) => {
      if (r) {
        toast.success(t("reset_email_sent"));
        setFormState("login");
      } else toast.warning(t("error_try_again"));
    });
  };

  const onReset = async () => {
    let load = { id: resetId, ...resetState };
    await resetCustomer(load).then((r) => {
      if (r) {
        toast.success(t("password_reset_success"));
        setFormState("login");
      } else toast.error(t("error_try_again"));
    });
  };

  const onSignUp = () => {
    if (registerState.organizator) onJoinPromoter();
    else onRegister();
  };

  // const onVerify = (token) => {
  //   //console.log("hCaptcha token", token);
  //   setIsDisabled(false);
  // };

  useEffect(() => {
    i18n.changeLanguage(language?.toLocaleLowerCase());
    changeLanguage(language?.toLocaleLowerCase());
  }, [language]);

  return (
    <Box
      sx={{
        height: { xs: "auto", md: "100vh" },
        width: "100vw",
        overflowX: "hidden!Important",
        backgroundColor: "#fff0d7",
        overflow: { xs: "auto", lg: "hidden" },
        position: "relative",
      }}
    >
      <Stack
        display="flex"
        direction="row"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          height: "100%",
        }}
      >
        <Box
          className="d-flex flex-row align-items-center justify-contet-center"
          sx={{
            position: "absolute",
            top: { xs: "3%", lg: "unset" },
            bottom: { xs: "unset", lg: "3%" },
            left: "5%",
          }}
        >
          <img
            onClick={() => {
              navigate("/");
            }}
            src={logo}
            alt="logo"
            style={{ width: "230px", cursor: "pointer" }}
          />

          <div className="ms-4">
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              aria-controls={openMenu ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onMouseOver={handleClick}
            >
              <Public sx={{ color: "#df0c62", fontSize: "30px" }} />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: { backgroundColor: "#fff0d7" },
              }}
            >
              <MenuItem
                onClick={handleClose}
                sx={{
                  backgroundColor: "#fff0d7",
                  "&:hover": { backgroundColor: "#ffd283" },
                  borderBottom: "2px solid #df0c62",
                }}
              >
                <Stack
                  display="flex"
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Public sx={{ color: "#df0c62" }} />

                  <Typography variant="h6" fontWeight="bold" color="#df0c62">
                    {language}
                  </Typography>
                </Stack>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  changeLocale("lang", "TR");
                }}
                sx={{
                  backgroundColor: "#fff0d7",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
                <Typography textAlign="center" color="#feb659">
                  TR
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  changeLocale("lang", "EN");
                }}
                sx={{
                  backgroundColor: "#fff0d7",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
                <Typography textAlign="center" color="#feb659">
                  EN
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  changeLocale("lang", "DE");
                }}
                sx={{
                  backgroundColor: "#fff0d7",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
                <Typography textAlign="center" color="#feb659">
                  DE
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  changeLocale("lang", "RU");
                }}
                sx={{
                  backgroundColor: "#fff0d7",
                  "&:hover": { backgroundColor: "#df0c62" },
                }}
              >
                <Typography textAlign="center" color="#feb659">
                  RU
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </Box>
        <Box
          sx={{
            display: { xs: "block", lg: "none" },
            position: "absolute",
            top: "-2%",
            right: "-12%",
            transform: "rotate(145deg)",
            width: "32%",
          }}
        >
          <img src={ticket} alt="ticket" style={{ width: "100%" }} />
        </Box>
        {/* Left Side */}
        <Box
          sx={{
            height: { xs: "fit-content", md: "auto" },
            paddingTop: { xs: "150px", md: "0" },
            width: { sx: "100%", md: "50%" },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Stack
            display="flex"
            direction="column"
            spacing={3}
            sx={{
              padding: "32px",
              width: "100%",
            }}
            alignItems="center"
          >
            <Box sx={{ width: "75%" }}>
              <Typography
                className={classes.label}
                variant="h5"
                fontWeight="bold"
                sx={{ color: "#df0c62", zIndex: "1" }}
              >
                {t("Login")}
              </Typography>
            </Box>
            {formState == "login" ? (
              <>
                <input
                  className={classes.ePosta}
                  placeholder={t("E-mail")}
                  value={loginState.email}
                  onChange={(e) =>
                    setLoginState({ ...loginState, email: e.target.value })
                  }
                  style={{ width: "75%" }}
                />
                <input
                  className={classes.şifre}
                  placeholder={t("Password")}
                  value={loginState.password}
                  onChange={(e) =>
                    setLoginState({ ...loginState, password: e.target.value })
                  }
                  style={{ width: "75%" }}
                  type="password"
                />
              </>
            ) : formState == "forgot" ? (
              <>
                <input
                  className={classes.ePosta}
                  value={loginState.email}
                  onChange={(e) =>
                    setLoginState({ ...loginState, email: e.target.value })
                  }
                  placeholder={t("E-mail")}
                  style={{ width: "75%" }}
                />
              </>
            ) : formState == "reset" ? (
              <>
                <input
                  className={classes.şifre}
                  value={resetState.password}
                  onChange={(e) =>
                    setResetState({ ...resetState, password: e.target.value })
                  }
                  placeholder={t("Password")}
                  style={{ width: "75%" }}
                  type="password"
                />
                <input
                  className={classes.şifre}
                  value={resetState.r_password}
                  onChange={(e) =>
                    setResetState({ ...resetState, r_password: e.target.value })
                  }
                  placeholder={t("Confirm Password")}
                  style={{ width: "75%" }}
                  type="password"
                />
              </>
            ) : null}
            {/* <HCaptcha
              sitekey="71b6f6e1-b938-4173-a099-d2def0141b3e"
              onVerify={onVerify}
              hl={localStorage.getItem("e-ticket-al language").toLowerCase()}
            /> */}
            <Stack
              display="flex"
              direction="column"
              spacing={1}
              sx={{ width: "75%" }}
            >
              {formState == "login" ? (
                <Button
                  // disabled={isDisabled}
                  variant="contained"
                  className={loading ? "loadingBut" : ""}
                  onClick={!loading ? onLogin : null}
                  style={{
                    borderRadius: "0",
                    backgroundColor: "#feb659",
                    border: "none",
                    padding: "16px",
                    color: "#df0b62",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {t("Login")}
                </Button>
              ) : formState == "forgot" ? (
                <Button
                  variant="contained"
                  className={loading ? "loadingBut" : ""}
                  onClick={!loading ? onForgot : null}
                  style={{
                    borderRadius: "0",
                    backgroundColor: "#feb659",
                    border: "none",
                    padding: "16px",
                    color: "#df0b62",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {t("Submit")}
                </Button>
              ) : formState == "reset" ? (
                <Button
                  variant="contained"
                  className={loading ? "loadingBut" : ""}
                  onClick={!loading ? onReset : null}
                  style={{
                    borderRadius: "0",
                    backgroundColor: "#feb659",
                    border: "none",
                    padding: "16px",
                    color: "#df0b62",
                    fontSize: "16px",
                    width: "100%",
                  }}
                >
                  {t("Save Password")}
                </Button>
              ) : null}

              {formState == "login" ? (
                <Typography
                  variant="h6"
                  fontSize="14px"
                  textAlign="center"
                  color="#df0b62"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setFormState("forgot")}
                >
                  {t("Forgot your password?")}
                </Typography>
              ) : formState == "forgot" ? (
                <Typography
                  variant="h6"
                  fontSize="14px"
                  textAlign="center"
                  color="#df0b62"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setFormState("login")}
                >
                  {t("Back to login")}
                </Typography>
              ) : formState == "reset" ? (
                <Typography
                  variant="h6"
                  fontSize="14px"
                  textAlign="center"
                  color="#df0b62"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setFormState("login")}
                >
                  {t("Back to login")}
                </Typography>
              ) : null}
            </Stack>
          </Stack>
        </Box>

        {/* Right Side */}
        <Box
          sx={{
            minHeight: { xs: "50vh", md: "auto" },
            height: { xs: "fit-content", md: "auto" },
            width: { sx: "100%", md: "50%" },
            display: "flex",
            position: "relative",
            backgroundColor: "#df0c62",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              right: "-12%",
              transform: "rotate(145deg)",
              width: "32%",
            }}
          >
            <img src={ticket} alt="ticket" style={{ width: "100%" }} />
          </Box>
          {!submitted ? (
            <Stack
              display="flex"
              direction="column"
              spacing={3}
              sx={{
                padding: {
                  xs: "32px 32px 32px 30px",
                  lg: "32px 32px 32px 160px",
                },
                width: "100%",
                margin: "auto",
                height: "fit-content",
                zIndex: "1",
                overflowX: "hidden",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  className={classes.label}
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: "white", zIndex: "1" }}
                >
                  {t("Sign up")}
                </Typography>
              </Box>

              <Stack
                display="flex"
                direction="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
                spacing={2}
              >
                <input
                  className={classes.isim}
                  value={registerState.firstName}
                  onChange={(e) =>
                    setRegisterState({
                      ...registerState,
                      firstName: e.target.value,
                    })
                  }
                  placeholder={t("First Name")}
                  style={{ width: "60%" }}
                  name="name"
                />

                <Box sx={{ width: "100%" }}>
                  <input
                    className={classes.soyIsim}
                    value={registerState.lastName}
                    onChange={(e) =>
                      setRegisterState({
                        ...registerState,
                        lastName: e.target.value,
                      })
                    }
                    placeholder={t("Last Name")}
                    name="surname"
                  />
                </Box>
              </Stack>

              <input
                className={classes.ePosta}
                value={registerState.email}
                onChange={(e) =>
                  setRegisterState({ ...registerState, email: e.target.value })
                }
                placeholder={t("E-Mail")}
                style={{ width: "110%" }}
                name="email"
              />

              <input
                className={classes.ePosta}
                value={registerState.phoneNumber}
                onChange={(e) =>
                  setRegisterState({
                    ...registerState,
                    phoneNumber: e.target.value,
                  })
                }
                placeholder={t("Phone Number")}
                style={{ width: "110%" }}
                name="telefon"
              />

              {registerState.organizator ? (
                <>
                  <input
                    className={classes.ePosta}
                    value={registerState.taxNumber}
                    onChange={(e) =>
                      setRegisterState({
                        ...registerState,
                        taxNumber: e.target.value,
                      })
                    }
                    placeholder={t("Tax Number")}
                    style={{ width: "110%" }}
                    name="tax number"
                  />
                  <input
                    className={classes.ePosta}
                    value={registerState.businessName}
                    onChange={(e) =>
                      setRegisterState({
                        ...registerState,
                        businessName: e.target.value,
                      })
                    }
                    placeholder={t("Business Name")}
                    style={{ width: "110%" }}
                    name="business"
                  />
                </>
              ) : null}

              <Stack
                display="flex"
                direction="row"
                alignItems="center"
                sx={{ position: "relative", display: "block" }}
              >
                <input
                  className={classes.şifre}
                  value={registerState.password}
                  id="registerPasswordInput"
                  onChange={(e) =>
                    setRegisterState({
                      ...registerState,
                      password: e.target.value,
                    })
                  }
                  placeholder={t("Password")}
                  style={{ width: "110%" }}
                  type="password"
                  name="password"
                />
                <small
                  style={{
                    position: "absolute",
                    right: "-2%",
                    fontSize: 18,
                    color: "#df0c62",
                    top: 0,
                    bottom: 0,
                    margin: "auto 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                  type="button"
                  onClick={() => {
                    let pass = document.querySelector("#registerPasswordInput");
                    if (pass.type == "password") pass.type = "text";
                    else pass.type = "password";
                  }}
                >
                  <AiFillEye />
                </small>
              </Stack>

              <Stack display="none" direction="row" alignItems="center">
                <Box>
                  <Checkbox
                    checked={registerState.organizator}
                    onChange={(e) =>
                      setRegisterState({
                        ...registerState,
                        organizator: e.target.checked,
                      })
                    }
                    id="orgBox"
                    style={{ color: "#fff0d7", backgroundColor: "#df0c62" }}
                  />
                </Box>
                <Box sx={{ width: "55%", height: "50%" }}>
                  <Typography
                    variant="paragraph"
                    color="white"
                    onClick={() =>
                      setRegisterState({
                        ...registerState,
                        organizator: !document.getElementById("orgBox").checked,
                      })
                    }
                    sx={{ textAlign: "start", cursor: "pointer" }}
                  >
                    {t("I am an organizator")}
                  </Typography>
                </Box>
              </Stack>

              <Stack display="flex" direction="column" spacing={1}>
                <Stack display="flex" direction="row" alignItems="center">
                  <Box>
                    <Checkbox
                      checked={registerState.consent}
                      onChange={(e) =>
                        setRegisterState({
                          ...registerState,
                          consent: e.target.checked,
                        })
                      }
                      id="consentBox"
                      style={{ color: "#fff0d7", backgroundColor: "#df0c62" }}
                    />
                  </Box>
                  <Box sx={{ width: "80%", height: "50%" }}>
                    <Typography
                      variant="paragraph"
                      color="white"
                      onClick={() =>
                        setRegisterState({
                          ...registerState,
                          consent:
                            !document.getElementById("consentBox").checked,
                        })
                      }
                      sx={{ textAlign: "start", cursor: "pointer" }}
                    >
                      <span>
                        {t("by checking start")}
                        <a target="_blank" href="/page/kullanici-sozlesmesi">
                          {t("Terms")}
                        </a>
                        <a target="_blank" href="/page/gizlilik-politikasi">
                          {t("Privacy Policy")}
                        </a>
                        {t("and")}
                        <a target="_blank" href="/page/cerez-politikasi">
                          {t("Cookie Policy")}
                        </a>
                        {t("by checking end")}
                      </span>
                    </Typography>
                  </Box>
                </Stack>
                <Box sx={{ width: "100%", paddingTop: "32px" }}>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "0",
                      backgroundColor: "#feb659",
                      border: "none",
                      padding: "16px",
                      color: "#df0b62",
                      fontSize: "16px",
                      width: "60%",
                    }}
                    className={loading ? "loadingBut" : ""}
                    onClick={!loading ? onSignUp : null}
                  >
                    {t("Sign up")}
                  </Button>
                </Box>
              </Stack>
            </Stack>
          ) : (
            <>
              <Typography
                className={classes.label + " m-auto p-4 "}
                variant="h6"
                fontWeight="bold"
                sx={{ color: "#fff", zIndex: "1" }}
              >
                Üyelik talebiniz alındı. Hesabınız şu anda onaylanma aşamasında.
                Onaylanma işlemi gerçekleştiği zaman, e-posta üzerinden
                bilgiledireceksiniz.
              </Typography>
            </>
          )}
        </Box>
      </Stack>

      {/* Mobile Display */}
      {/* <MobileLogin /> */}
      {/* Tablet Display */}
      {/* <TabletLogin /> */}
      {/* Large Display */}
      {/* <DesktopLogin /> */}
    </Box>
  );
};

export default Login;
