import {useEffect,useState,useRef} from "react";
import {
  Box,
  Stack,
  Typography,
  Checkbox,
  Button
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {toast} from 'react-toastify';
import {garantiMake} from "../../Data/payments";

export default function GarantiForm({secret,submit,loading,hotsale}){
  // console.log(loading);

  const [card,setCard] = useState({number:"",month:"",year:"",cvv:""});
  const [checked, setChecked] = useState({ first: false, second: false, third: false });
  const [isLoading, setIsLoading] = useState(loading);
  const [allowed, setAllowed] = useState(false);
  const [activeFrame, setActiveFrame] = useState(false);
  const { t } = useTranslation();
  const frameRef = useRef();
  const navigate = useNavigate();

  // console.log(hotsale);

  const clean = (html) => {
    return html.replace("<body>","").replace("</body>","").replace("<html>","").replace("</html>","");
  }


  const make = async () => {
    await submit(secret).then(async(order) => {
      if(order){
        await garantiMake({...card, orderId: order._id, total:order.total, currency: order.currency.code}).then((r) => {
          // console.log(r);
          // document.querySelector('iframe[name="3dPay"]').contentWindow.location.reload(true);

          frameRef.current.src = "";
          setActiveFrame(true);
          frameRef.current.contentWindow.document.querySelector('body').innerHTML = clean(r);
          frameRef.current.contentWindow.document.querySelector('form').submit();
        });
      }else toast.error(t('error_try_again'))
    });
  }

  const frameControl = (eve) => {
    let _frame = eve.target;
    console.log(_frame.src == window.location.href);
    if(_frame.src == window.location.href){
      let resp = JSON.parse(eve.target.contentWindow.document.body.textContent);
      if(resp.isSuccess){
        console.log(resp.content);
        setCard({number:"",month:"",year:"",cvv:""});
        setChecked({ first: false, second: false, third: false });
        setAllowed(false);
        setActiveFrame(false);
        if(hotsale) navigate('/seller?orderSucc=1&payment_intent_client_secret='+secret+'&oid='+resp.content.orderid);
        else navigate('/congratulations?payment_intent_client_secret='+secret+'&oid='+resp.content.orderid);

      }else{
        setActiveFrame(false);
        toast.error(t('error_try_again'));
      }
    }
  }



  useEffect(() => {
      if (Object.values(checked).find((it) => it == false) != undefined) {
        setAllowed(false);
      } else setAllowed(true);


    }, [checked]);

    useEffect(() => {
      frameRef.current.addEventListener('load',(e) => {frameControl(e);});
    },[]);

  return (
    <>
    <Stack display="flex" direction="column" spacing={2}>
      <Stack display="flex" direction="row" spacing={2}>
        {/* Left Side Payment Detail */}
        <Box flex={1}>
          <Stack display="flex" direction="column" spacing={2}>
            <Box>
              <Typography fontWeight="bold" color="#df0c62">
                Kart Üzerindeki Ad Soyad
              </Typography>
              <input
              value={card.name}
              onChange={(e) => {setCard({...card, name: e.target.value})}}
                style={{
                  width: "100%",
                  border: "2px solid #df0c63",
                  outline: "none",
                }}
                name="fullName"
              />
            </Box>
            <Box>
              <Typography fontWeight="bold" color="#df0c62">
                Kart Numarası
              </Typography>
              <input
              value={card.number}
              onChange={(e) => {setCard({...card, number: e.target.value})}}
                style={{
                  width: "100%",
                  border: "2px solid #df0c63",
                  outline: "none",
                }}
                name="cardNumber"
              />
            </Box>

            <Stack display="flex" direction="row" spacing={1}>
              <Box flex={1}>
                <Typography fontWeight="bold" color="#df0c62">
                  Ay
                </Typography>
                <input
                value={card.month}
                onChange={(e) => {setCard({...card, month: e.target.value})}}
                  style={{
                    width: "100%",
                    border: "2px solid #df0c63",
                    outline: "none",
                  }}
                  name="cardMonth"
                />
              </Box>
              <Box flex={1}>
                <Typography fontWeight="bold" color="#df0c62">
                  Yıl
                </Typography>
                <input
                value={card.year}
                onChange={(e) => {setCard({...card, year: e.target.value})}}
                  style={{
                    width: "100%",
                    border: "2px solid #df0c63",
                    outline: "none",
                  }}
                  name="cardMonth"
                />
              </Box>
              <Box flex={1}>
                <Typography fontWeight="bold" color="#df0c62">
                  CVC
                </Typography>
                <input
                value={card.cvv}
                onChange={(e) => {setCard({...card, cvv: e.target.value})}}
                  style={{
                    width: "100%",
                    border: "2px solid #df0c63",
                    outline: "none",
                  }}
                  name="cardCode"
                />
              </Box>
            </Stack>
            <Typography color="#df0c62">
              *Ödeme işlemine devam ederek KVKK Aydonlatma
              Metnini’okuduğımu ve anladığımı kabul ediyorum.
            </Typography>
            {/* End Of left Side Payment Detail */}
          </Stack>
        </Box>
      </Stack>
      <div style={{ margin: '20px 0' }}>
        <Typography variant="h6" fontWeight="bold" color="#df0c62">
          {t('Legal Notices')}:
        </Typography>

        {/* First Box */}
        <Box sx={{ borderBottom: "2px solid #ffd589", }}>
          <Stack display="flex" direction="row" alignItems="center">
            <Checkbox
              value={checked.first}
              onChange={(e) => { setChecked({ ...checked, first: e.target.checked }) }} style={{ color: "#df0c62" }} />
            <Link to="/page/gizlilik-politikasi" style={{ textDecoration: "none" }}>
              <Typography
                variant="h7"
                color="#df0c62"
                sx={{ "&:hover": { fontWeight: "bold" } }}
              >
                {t('Privacy Policy')}
              </Typography>
            </Link>
          </Stack>
        </Box>
        {/* Second Box */}
        <Box sx={{ borderBottom: "2px solid #ffd589" }}>
          <Stack display="flex" direction="row" alignItems="center">
            <Checkbox
              value={checked.second}
              onChange={(e) => { setChecked({ ...checked, second: e.target.checked }) }} style={{ color: "#df0c62" }} />
            <Link to="/page/mesafeli-satis-sözlesmesi" style={{ textDecoration: "none" }}>
              <Typography
                variant="h7"
                color="#df0c62"
                sx={{ "&:hover": { fontWeight: "bold" } }}
              >
                {t('Distance Selling Contract')}
              </Typography>
            </Link>
          </Stack>
        </Box>
        {/* Third Box */}
        <Box sx={{ borderBottom: "2px solid #ffd589", }}>
          <Stack display="flex" direction="row" alignItems="center">
            <Checkbox
              value={checked.third}
              onChange={(e) => { setChecked({ ...checked, third: e.target.checked }) }} style={{ color: "#df0c62" }} />
            <Link to="/page/iptal-ve-iade-politikasi" style={{ textDecoration: "none" }}>
              <Typography
                variant="h7"
                color="#df0c62"
                sx={{ "&:hover": { fontWeight: "bold" } }}
              >
                {t('Return Policy')}
              </Typography>
            </Link>
          </Stack>
        </Box>
      </div>
      <iframe ref={frameRef} className={'hiddenframe ' + (activeFrame ? "openframe" :null)}></iframe>
      {/* End Of Child Accordion Stack */}
      <Box display="flex" justifyContent="center">
        <Button
          id="submit"
          type="button"
          onClick={() => {make()}}
          variant="contained"
          className={loading ? 'loadingBut' : ''}
          sx={{
            backgroundColor: "#df0c62",
            "&:hover": { backgroundColor: "#df0c62" },
          }}
          disabled={isLoading || !allowed || loading}
        >
          {isLoading ? <div className="spinner" id="spinner"></div> : "Ödemeyi Tamamla"}
        </Button>
      </Box>
    </Stack>
    </>
  );
}
