import React from "react";
import HotSaleDesktop from "./Desktop/HotSaleDesktop";
const HotSale = () => {
  return (
    <div>
      <HotSaleDesktop />
    </div>
  );
};

export default HotSale;
