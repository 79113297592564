import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { getAllCountries } from "../../Actions";
import {listCountries} from "../../Data/event";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const AutoInput = () => {
  const [defined,setDefined] = React.useState(localStorage.getItem('e-ticket-al country'));
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const changeLocation = async (val) => {
    setDefined(val);
    window.location.reload();
  }

  useEffect(() => {
    if(defined && defined != ""){
      localStorage.setItem('e-ticket-al country',defined);
    }else localStorage.removeItem('e-ticket-al country');
  },[defined]);

  const dispatch = useDispatch();
  const { t } = useTranslation()

  const fetch = async () => {
    setOptions(await listCountries());
  }

  useEffect(() => {
    fetch()

  }, []);
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {

      if (active) {
      fetch()
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: "100%" }}
      open={open}
      value={defined == 'undefined' ? "" : defined}
      onChange={(e) => {
        if(e?.target?.innerText != ''){
          changeLocation(e.target.innerText);
        }else changeLocation("");

      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option === value}
      getOptionLabel={(option) => option}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`${t('COUNTRY')}...`}
          InputProps={{
            style: {
              color: "#ffffff",
              fontWeight: "bold",
              backgroundColor: "#df0c62",
            },
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoInput;
